import React, { useEffect, useState } from "react";

const CreateParkingHours = ({ onTimeChange, parkingHours }: any) => {
  const [weekDays, setWeekDays] = useState(parkingHours || []);

  // Sync parkingHours prop to weekDays state only on initial load
  useEffect(() => {
    if (parkingHours && weekDays.length === 0) {
      setWeekDays([...parkingHours]); // Load initial data
    }
  }, [parkingHours]);

  const handleTimeChange = (
    index: number,
    timeType: "openTime" | "closeTime",
    value: string
  ) => {
    const updatedWeekDays = weekDays.map((day: any, i: any) =>
      i === index ? { ...day, [timeType]: value } : day
    );
    setWeekDays(updatedWeekDays);
  };

  useEffect(() => {
    onTimeChange(weekDays);
  }, [weekDays, onTimeChange]);

  return (
    <div className="ms-2 my-1" style={{ maxWidth: "600px" }}>
      {weekDays.length > 0 ? (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                Weekdays
              </th>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                Open Time
              </th>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                Close Time
              </th>
            </tr>
          </thead>
          <tbody>
            {weekDays.map((day: any, index: any) => (
              <tr key={day?.day}>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                  {day.day}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                  <input
                    type="time"
                    value={day?.openTime}
                    onChange={(e) =>
                      handleTimeChange(index, "openTime", e.target.value)
                    }
                  />
                </td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                  <input
                    type="time"
                    value={day?.closeTime}
                    onChange={(e) =>
                      handleTimeChange(index, "closeTime", e.target.value)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading parking hours...</p>
      )}
    </div>
  );
};

export default CreateParkingHours;
