import { NetworkManager } from "../Components/NetworkManager";
import ToastManager from "../Components/ToastManager";
import { StatusCodeUtil } from "./StatusCodeUtil";
import { Events } from "../Pages/Languages/Events";
import CreditCardDetails from "../Pages/CustProfiles/CreditCardDetails";
import BusyLoader from "../Components/BusyLoader";
import { Constants } from "../Pages/Languages/Constants";

export class CommonUtil {
  static CHECKED: string = "Y";
  static UN_CHECKED: string = "N";
  static CREATE_MODE = "create";
  static UPDATE_MODE = "update";

  static SELECTED_LANGUAGE: any = "en";
  static SEL_LANGUAGE_TEXT = "Language (EN)";

  static ENG_LANGUAGE = "en";
  static ENG_LANGUAGE_TEXT = "Language (EN)";

  static FR_LANGUAGE = "fr";
  static FR_LANGUAGE_TEXT = "Language (FR)";

  static SP_LANGUAGE = "sp";
  static SPANISH_LANGUAGE_TEXT = "Language (SP)";

  static ORG_TYPE_OWNER = "OWNER";
  static ORG_TYPE_RENTER = "RENTER";
  static ORG_TYPE_OWNER_RENTER = "OWNER_RENTER";
  static TRAILER_HUB_PROVIDER = "Trailer Hub Provider";
  static ORG_APPROVED = "Approved";
  static ORG_REVIEW_READY = "Review";

  static SELECT_ORG_TYPE = "";

  static ACTIVE = "Active";
  static IN_ACTIVE = "Inactive";
  static INVITED = "Invited";

  static INSPECTION_STATUS_ACTIVE = "ACTIVE";
  static INSPECTION_STATUS_INVITED = "INVITED";
  static INSPECTION_STATUS_RESEND = "RESEND";

  static CANADA = "Canada";
  static US = "United States";

  static CANADA_CURRENCY = "CAD";
  static US_CURRENCY = "USD";

  static FP2506_DOC_TYPE = "FP-2506";
  static GST506_DOC_TYPE = "GST506";
  static W8_DOC_TYPE = "W8";
  static W9_DOC_TYPE = "W9";
  static BANK_DOC_TYPE = "Bank";
  static BANK1_DOC_TYPE = "Bank1";
  static TAX_DOC_TYPE = "Tax";
  static INSUR_DOC_TYPE = "Insurence";
  static POD_DOC_TYPE = "POD Document";

  static ASSET_TYPE_TRAILER = "Trailer";
  static ASSET_TYPE_TRUCK = "Truck";

  static INSPECTION_ACTIVE = "Active";
  static INSPECTION_INACTIVE = "Inactive";

  static DEFAULT_INSPECTION_TYPE = "Interchange";

  static EQUIP_ASSET_TYPE = "Trailer";

  static IS_GENERIC_EQUIP = false;
  static EQUIP_AVAIL_TYPE = "";
  static EQUIP_AVAIL_RENTAL_TYPE = "Rental";
  static EQUIP_AVAIL_REPOSITION_TYPE = "Reposition";
  static EQUIP_LISTING_TYPE = "";
  static EQUIP_TRACKING_TYPE = "";
  static EQUIP_HUBODOMETER = "Hubodometer";

  static ACTIVATION_HODO_METER = 0;
  static DE_ACTIVATION_HODO_METER = 0;

  static EQUIP_GENERIC = "Generic";
  static EQUIP_SPECIFIC = "Specific";

  static ANY_AVAILABLE = "Any Available";
  static ANY_NOT_AVAILABLE = "Any Not Available";

  static LOGIN_USER_ID = "";
  static CUSTOMER_COUNTRY = "";
  static ORGANIZATION_TYPE = "";
  static DISTANCE_MESURE = "km";
  static APPROVED = "Approved";
  static PENDING = "Pending";
  static OWNER_STATUS = "";
  static RENTER_STATUS = "";
  static PUBLIC_SEARCH_DB = false;
  static PRIVATE_SEARCH_DB = false;

  static OWNER = "Owner";
  static RENTER = "Renter";
  static CONTACT_DETAILS_DOC = "CONTACT_DETAILS_DOC";

  static MILES_TO_METERS = 1609;

  static RP_KEY = "O93IGEb0ALW%2BXDsrG4b5UHAee1OjTNcYICQdZiP7BFA%3D";
  static RESERVATION_TOKEN = "";
  static PHYSICAL_DAMAGE = "Trailer Physical Damage";
  static LIABILITY = "Trailer Liability Coverage";

  static DROPDOWN_MANDATORY_CLASS = "mandatory";
  static DROPDOWN_VALID_CLASS = "select-box-valid";
  static DROPDOWN_INVALID_CLASS = "select-box-invalid";

  static DATE_FIELD_MANDATORY_CLASS =
    "form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker";
  static DATE_FIELD_VALID_CLASS =
    "form-control clsStandardText flat-picker is-valid";

  static DATE_FIELD_IN_VALID_CLASS =
    "form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker is-invalid";

  static TEXT_FIELD_MANDATORY_CLASS =
    "form-control clsStandardText clsFormInputControl";
  static TEXT_FIELD_VALID_CLASS = "form-control clsStandardText";

  static DROP_DOWN_MANDATORY_CLASS = "mandatory";

  static MIN_OWNER_COVERAGE = "1000000";

  static EQ_DETAILS_COSTESTIMATE: any = {};
  static DB_MENU = "DASHBOARD";
  static PROCESS_MENU = "PROCESS";
  static ADMINISTRATION_MENU = "ADMINISTRATION";
  static REPORTS_MENU = "REPORTS";
  static APIHUB_MENU = "APIHUB";

  static SEARCH_DB_SUB_MENU = "Search Dashboard";
  static COMPANY_DB_SUB_MENU = "Company Dashboard";
  static INVOICES_DB_SUB_MENU = "Invoices";
  static PROCESS_RESERVE_SUB_MENU = "Process Reservations";
  static ORG_SUB_MENU = "Organizations";
  static EQUIP_SUB_MENU = "Equipment";
  static DRIVERS_SUB_MENU = "Drivers";
  static PARKING_LOT_SUB_MENU = "Parking Lots";
  static NOTIFICATIONS_SUB_MENU = "Notifications";
  static EQUIPMENT_REP__SUB_MENU = "Equipment";
  static AVAILABILITIES_SUB_MENU = "Availabilities";
  static RESERVATIONS_SUB_MENU = "Reservations";
  static INVOICES_REP__SUB_MENU = "Invoices";
  static RELEASES_PICS = "Releases";

  static EQUIPMENT_LIST: boolean = true;

  static GRID_HEADER_HEIGHT = 35;
  static GRID_ROW_HEIGHT = 40;

  static ENGLISH_JSON: any = [];
  static FRENCH_JSON: any = [];
  static SPANISH_JSON: any = [];

  static GOOGLE_MAP_KEY = "";

  static US_PASSCODE = "";
  static CANADA_PASSCODE = "";
  static MAP_MAX_ZOOM = 22;

  static CLAIMID = "";
  static DOCUMENTCODE = ""
  static BROKERID = ""
  static CARRIERID = "";
  static PROFILE_STATUS = "incomplete";
  static profilecompleteCallback:Function;

  static DATE_FORMAT = {
    dateFormat: "m-d-Y",
    altFormat: "Y-m-d",
  };

  static Interchange_Date_Format = {
    dateFormat: "Y-m-d",
  };

  static AVAIL_DATE_FORMAT = {
    dateFormat: "m-d-Y",
    altFormat: "Y-m-d",
    minDate: new Date(),
  };

  static MAP_ZOOMS = [
    { minZoom: 0, maxZoom: 22, minDist: 0, maxDist: 500 },
    { minZoom: 0, maxZoom: 12, minDist: 0, maxDist: 2000 },
    { minZoom: 0, maxZoom: 11, minDist: 0, maxDist: 3000 },
    { minZoom: 0, maxZoom: 10, minDist: 0, maxDist: 4000 },
    { minZoom: 0, maxZoom: 9, minDist: 0, maxDist: 10000 },
    { minZoom: 0, maxZoom: 8, minDist: 0, maxDist: 23000 },
    { minZoom: 0, maxZoom: 3, minDist: 0, maxDist: 650000 },
    { minZoom: 0, maxZoom: 2, minDist: 0, maxDist: 1500000 },
    { minZoom: 0, maxZoom: 6, minDist: 0, maxDist: 75000 },
    { minZoom: 0, maxZoom: 1, minDist: 0, maxDist: 3000000 },
  ];

  static TYPES = [{ key: "ACH", value: "ACH" }]; //{key: 'SWIFT', value: 'SWIFT'},
  static INSUR_PLAN_TYPES = [
    { key: "Trailer Liability Coverage", value: "Trailer Liability Coverage" },
    { key: "Trailer Physical Damage", value: "Trailer Physical Damage" },
  ];

  static EQUIPMENT_TYPE_IMAGES = [
    { value: "DRY VAN", path: "../../images/dryVan.png" },
    { value: "PLATE DRY VAN", path: "../../images/plateDryVan1.png" },
    { value: "STORAGE VAN", path: "../../images/storageVan1.png" },
    { value: "REEFER VAN", path: "../../images/reeferVan1.png" },
    { value: "HEATED VAN", path: "../../images/heatedVan1.png" },
    { value: "FLATBED", path: "../../images/flatBed1.png" },
    { value: "DROPDECK", path: "../../images/dropDeck1.png" },
    { value: "LOG", path: "../../images/log1.png" },
    { value: "SCRAP METAL", path: "../../images/scrapMetal1.png" },
    { value: "RECYCLING", path: "../../images/recycling1.png" },
    { value: "FLATBED", path: "../../images/flatBed1.png" },
    { value: "CHIP", path: "../../images/chip1.png" },
    { value: "DUMP", path: "../../images/dump1.png" },
    { value: "LOWBED", path: "../../images/lowBed1.png" },
    { value: "CHASSIS", path: "../../images/chassis1.png" },
    { value: "TANK", path: "../../images/tank1.png" },
    { value: "TANK CRUDE", path: "../../images/tankCrude1.png" },
    { value: "TANK DRY BULK", path: "../../images/tankDryBulk1.png" },
    { value: "TANK PETROLEUM", path: "../../images/tankPetroleum1.png" },
    { value: "TANK PROPANE", path: "../../images/tankPropane1.png" },
    { value: "TANK CHEMICAL", path: "../../images/tankChemical1.png" },
    { value: "OTHER", path: "../../images/other1.png" },
  ];

  static getEquipemntDiv(equipType: string): string {
    if (equipType === "DRY VAN") {
      return "divDryVan";
    } else if (equipType === "PLATE DRY VAN") {
      return "divPlateDryVan";
    } else if (equipType === "STORAGE VAN") {
      return "divStorageVan";
    } else if (equipType === "REEFER VAN") {
      return "divReeferVan";
    } else if (equipType === "HEATED VAN") {
      return "divHeatedVan";
    } else if (equipType === "FLATBED") {
      return "divflatBed";
    } else if (equipType === "LOG") {
      return "divLog";
    } else if (equipType === "SCRAP METAL") {
      return "divScrapMetal";
    } else if (equipType === "RECYCLING") {
      return "divrecycling";
    } else if (equipType === "FLATBED") {
      return "divflatBed";
    } else if (equipType === "CHIP") {
      return "divchip";
    } else if (equipType === "DUMP") {
      return "divdump";
    } else if (equipType === "LOWBED") {
      return "divlowBed";
    } else if (equipType === "CHASSIS") {
      return "divchasis";
    } else if (equipType === "TANK") {
      return "divtank";
    } else if (equipType === "TANK CRUDE") {
      return "divtankCrude";
    } else if (equipType === "TANK DRY BULK") {
      return "divTankDryBulk";
    } else if (equipType === "TANK PETROLEUM") {
      return "divtankPetrol";
    } else if (equipType === "TANK PROPANE") {
      return "divTankPropane";
    } else if (equipType === "TANK CHEMICAL") {
      return "divChemical";
    } else if (equipType === "DROPDECK") {
      return "divdropDeck";
    } else if (equipType === "OTHER") {
      return "divOther";
    } else if (equipType === "CHASIS") {
      return "divchasis";
    }
    return "";
  }

  static getEquipemntImage(equipType: string): string {
    if (equipType === "DRY VAN") {
      return "../../images/dryVan.png";
    } else if (equipType === "PLATE DRY VAN") {
      return "../../images/plateDryVan1.png";
    } else if (equipType === "STORAGE VAN") {
      return "../../images/storageVan1.png";
    } else if (equipType === "REEFER VAN") {
      return "../../images/reeferVan1.png";
    } else if (equipType === "HEATED VAN") {
      return "../../images/heatedVan1.png";
    } else if (equipType === "FLATBED") {
      return "../../images/flatBed1.png";
    } else if (equipType === "LOG") {
      return "../../images/log1.png";
    } else if (equipType === "SCRAP METAL") {
      return "../../images/scrapMetal1.png";
    } else if (equipType === "RECYCLING") {
      return "../../images/recycling1.png";
    } else if (equipType === "FLATBED") {
      return "../../images/flatBed1.png";
    } else if (equipType === "CHIP") {
      return "../../images/chip1.png";
    } else if (equipType === "DUMP") {
      return "../../images/dump1.png";
    } else if (equipType === "LOWBED") {
      return "../../images/lowBed1.png";
    } else if (equipType === "CHASSIS") {
      return "../../images/chassis1.png";
    } else if (equipType === "TANK") {
      return "../../images/tank1.png";
    } else if (equipType === "TANK CRUDE") {
      return "../../images/tankCrude1.png";
    } else if (equipType === "TANK DRY BULK") {
      return "../../images/tankDryBulk1.png";
    } else if (equipType === "DROPDECK") {
      return "../../images/dropDeck1.png";
    } else if (equipType === "TANK PETROLEUM") {
      return "../../images/tankPetroleum1.png";
    } else if (equipType === "TANK PROPANE") {
      return "../../images/tankPropane1.png";
    } else if (equipType === "TANK CHEMICAL") {
      return "../../images/tankChemical1.png";
    } else if (equipType === "OTHER") {
      return "../../images/other1.png";
    } else if (equipType === "OTHER") {
      return "../../images/other1.png";
    } else if (equipType === "SPOTTER") {
      return "../../images/Spotter.png";
    }
    return "";
  }

  static ASSET_TYPES = [
    { key: "Trailer", value: "Trailer" },
    { key: "Truck", value: "Truck" },
  ];
  static INSPECTION_STATUS = [
    { key: "ACTIVE", value: "ACTIVE" },
    { key: "INACTIVE", value: "INACTIVE" },
  ];
  static INSPECTION_TYPE_STATUS = [
    { key: "Interchange", value: "Interchange" },
  ];
  static TRAILER_EQP_TYPES = [
    { Key: "DRY VAN", Value: "DRY VAN" },
    { Key: "PLATE DRY VAN", Value: "PLATE DRY VAN" },
    { Key: "STORAGE VAN", Value: "STORAGE VAN" },
    { Key: "REEFER VAN", Value: "REEFER VAN" },
    { Key: "HEATED VAN", Value: "HEATED VAN" },
    { Key: "FLATBED", Value: "FLATBED" },
    { Key: "DROPDECK", Value: "DROPDECK" },
    { Key: "LOG", Value: "LOG" },
    { Key: "SCRAP METAL", Value: "SCRAP METAL" },
    { Key: "RECYCLING", Value: "RECYCLING" },
    { Key: "CHIP", Value: "CHIP" },
    { Key: "DUMP", Value: "DUMP" },
    { Key: "LOWBED", Value: "LOWBED" },
    { Key: "CHASIS", Value: "CHASIS" },
    { Key: "TANK", Value: "TANK" },
    { Key: "TANK CRUDE", Value: "TANK CRUDE" },
    { Key: "TANK DRY BULK", Value: "TANK DRY BULK" },
    { Key: "TANK PETROLEUM", Value: "TANK PETROLEUM" },
    { Key: "TANK PROPANE", Value: "TANK PROPANE" },
    { Key: "TANK CHEMICAL", Value: "TANK CHEMICAL" },
    { Key: "OTHER", Value: "OTHER" },
  ];

  static TRUCK_EQP_TYPES = [{ Key: "SPOTTER", Value: "SPOTTER" }];

  static DEF_CURRENCY = [
    { key: "USD", value: "USD" },
    { key: "CAD", value: "CAD" },
  ];
  static LOAD_TYPE = [
    { key: "Load out", value: "Load out" },
    { key: "Tow away", value: "Tow away" },
  ];

  static AVAILABLE_RENTAL = [
    { key: "PUBLIC_VIEW", value: "Public" },
    { key: "PRIVATE_VIEW", value: "Private" },
    { key: "SPECIFIC_VIEW", value: "Specific" },
  ];


  static TRAILER_BRAND = [
    { Key: "ABS", Value: "ABS" },
    { Key: "Advance", Value: "Advance" },
    { Key: "Alutrec", Value: "Alutrec" },
    { Key: "Arne's", Value: "Arne's" },
    { Key: "Bedard", Value: "Bedard" },
    { Key: "Brault", Value: "Brault" },
    { Key: "Brenner", Value: "Brenner" },
    { Key: "BWS", Value: "BWS" },
    { Key: "Capacity", Value: "Capacity" },
    { Key: "Clark", Value: "Clark" },
    { Key: "Columbia Remtec", Value: "Columbia Remtec" },
    { Key: "Comptank", Value: "Comptank" },
    { Key: "Deloupe", Value: "Deloupe" },
    { Key: "Di-Mond", Value: "Di-Mond" },
    { Key: "Di-mon", Value: "Di-mon" },
    { Key: "Doonan", Value: "Doonan" },
    { Key: "Dorsey", Value: "Dorsey" },
    { Key: "East", Value: "East" },
    { Key: "Fericar", Value: "Fericar" },
    { Key: "Fontaine", Value: "Fontaine" },
    { Key: "Hyundai", Value: "Hyundai" },
    { Key: "Maxfield", Value: "Maxfield" },
    { Key: "Polar", Value: "Polar" },
    { Key: "Reitnouer", Value: "Reitnouer" },
    { Key: "Fruehauf Trailer", Value: "Fruehauf Trailer" },
    { Key: "Strick", Value: "Strick" },
    { Key: "Mac", Value: "Mac" },
    { Key: "Mac Trailer", Value: "Mac Trailer" },
    { Key: "Manac", Value: "Manac" },
    { Key: "Max-Atlas", Value: "Max-Atlas" },
    { Key: "Maxfield", Value: "Maxfield" },
    { Key: "Polar", Value: "Polar" },
    { Key: "Stoughton", Value: "Stoughton" },
    { Key: "East Manufacturing", Value: "East Manufacturing" },
    { Key: "Strickland", Value: "Strickland" },
    { Key: "Superior", Value: "Superior" },
    { Key: "Temisko", Value: "Temisko" },
    { Key: "Trailmobile", Value: "Trailmobile" },
    { Key: "Transcraft", Value: "Transcraft" },
    { Key: "Trail King", Value: "Trail King" },
    { Key: "Tremcar", Value: "Tremcar" },
    { Key: "Trout River", Value: "Trout River" },
    { Key: "Vanco", Value: "Vanco" },
    { Key: "Vanguard", Value: "Vanguard" },
    { Key: "Wabash", Value: "Wabash" },
    { Key: "Great Dane", Value: "Great Dane" },
    { Key: "Utility", Value: "Utility" },
    { Key: "Westmor", Value: "Westmor" },
    { Key: "Wilson", Value: "Wilson" },
    { Key: "Other", Value: "Other" },
  ];
  static TRUCK_BRAND = [
    { Key: "Tico", Value: "Tico" },
    { Key: "Ottawa", Value: "Ottawa" },
    { Key: "Capacity", Value: "Capacity" },
    { Key: "Autocar", Value: "Autocar" },
    { Key: "Kalmar", Value: "Kalmar" },
    { Key: "Other", Value: "Other" },
  ];

  static languages: object[] = [
    { Key: "en", Value: "English" },
    { Key: "fr", Value: "French" },
    {
      Key: "sp",
      Value: "Spanish",
    },
  ];

  static sourceData: object[] = [
    {
      Key: "Email marketing / Email Campaigns",
      Value: "Email marketing / Email Campaigns",
    },
    { Key: "Organic search", Value: "Organic search" },
    { Key: "Google Ads", Value: "Google Ads" },
    { Key: "Social media", Value: "Social media" },
    { Key: "Referrals or word-of-mouth", Value: "Referrals or word-of-mouth" },
    { Key: "Referrals from Other sites", Value: "Referrals from Other sites" },
    { Key: "Blog articles", Value: "Blog articles" },
    {
      Key: "Events (in-person or virtual)",
      Value: "Events (in-person or virtual)",
    },
    { Key: "The Freight Coach / Radio", Value: "The Freight Coach / Radio" },
    { Key: "Other", Value: "Other(not mandatory to type in)" },
  ];

  static RESERVATION_STATUSES = [
    { key: "", value: "All" },
    { key: "Upcoming", value: "Upcoming" },
    { key: "Activation Due", value: "Activation Due" },
    { key: "In progress", value: "In progress" },
    { key: "Done", value: "Done" },
    { key: "Deleted", value: "Deleted" },
    { key: "Deactivation Due", value: "Deactivation Due" },
    { key: "Late Activation", value: "Late Activation" },
    { key: "Late Deactivation", value: "Late Deactivation" },
  ];
  static EQUIPMENT_STATUSES = [
    { key: "", value: "All" },
    { key: "Available", value: "Available" },
    { key: "Inactive", value: "Inactive" },
    { key: "Active", value: "Active" },
    { key: "Rented", value: "Rented" },
    { key: "Late", value: "Late" },
  ];

  static CUSTOM_DATES = [
    { key: "1", value: "Today" },
    { key: "2", value: "Yesterday" },
    { key: "3", value: "Current Week" },
    { key: "4", value: "Previous Week" },
    { key: "5", value: "last 7 days" },
    { key: "6", value: "Current Month" },
    { key: "7", value: "Previous Month" },
    { key: "8", value: "last 1 month" },
    { key: "9", value: "current quarter" },
    { key: "10", value: "previous quarter" },
    { key: "11", value: "current half year" },
    { key: "12", value: "previous half year" },
    { key: "13", value: "current year" },
    { key: "14", value: "last year" },
    { key: "15", value: "last 1 year" },
  ];

  static loginAsArr: any[] = [
    { Key: "OWNER", Value: "Trailer owner" },
    { Key: "RENTER", Value: "Trailer renter" },
    { Key: "OWNER_RENTER", Value: "Owner & Renter" },
  ];
  static statuses: any[] = [
    { Key: "Active", Value: "Active" },
    { Key: "Inactive", Value: "Inactive" },
  ];
  static countryList: any[] = [
    { Key: "Canada", Value: "Canada" },
    { Key: "United States", Value: "United States" },
  ];
  static financeCountryList: any[] = [
    { Key: "", Value: "" },
    { Key: "Canada", Value: "Canada" },
    { Key: "United States", Value: "United States" },
  ];
  static bankCountryList: any[] = [
    { Key: "Canada", Value: "Canada" },
    {
      Key: "United States",
      Value: "United States",
    },
    { Key: "Cross Border", Value: "Cross Border" },
  ];
  static currencyList: any[] = [
    { Key: "CAD", Value: "CAD" },
    { Key: "USD", Value: "USD" },
  ];
  static canadaProvinces: any[] = [
    { Key: "Ontario", Value: "Ontario" },
    { Key: "Quebec", Value: "Quebec" },
    { Key: "British Columbia", Value: "British Columbia" },
    { Key: "Alberta", Value: "Alberta" },
    { Key: "Manitoba", Value: "Manitoba" },
    { Key: "Saskatchewan", Value: "Saskatchewan" },
    { Key: "Nova Scotia", Value: "Nova Scotia" },
    { Key: "New Brunswick", Value: "New Brunswick" },
    { Key: "Newfoundland and Labrador", Value: "Newfoundland and Labrador" },
    { Key: "Prince Edward Island", Value: "Prince Edward Island" },
    { Key: "Northwest Territories", Value: "Northwest Territories" },
    { Key: "Nunavut", Value: "Nunavut" },
    { Key: "Yukon", Value: "Yukon" },
  ];

  static usStates: any[] = [
    { Key: "Alabama", Value: "Alabama" },
    { Key: "Alaska", Value: "Alaska" },
    { Key: "Arizona", Value: "Arizona" },
    { Key: "Arkansas", Value: "Arkansas" },
    { Key: "California", Value: "California" },
    { Key: "Colorado", Value: "Colorado" },
    { Key: "Connecticut", Value: "Connecticut" },
    { Key: "Delaware", Value: "Delaware" },
    { Key: "Florida", Value: "Florida" },
    { Key: "Georgia", Value: "Georgia" },
    { Key: "Hawaii", Value: "Hawaii" },
    { Key: "Idaho", Value: "Idaho" },
    { Key: "Illinois", Value: "Illinois" },
    { Key: "Indiana", Value: "Indiana" },
    { Key: "Iowa", Value: "Iowa" },
    { Key: "Kansas", Value: "Kansas" },
    { Key: "Kentucky", Value: "Kentucky" },
    { Key: "Louisiana", Value: "Louisiana" },
    { Key: "Maine", Value: "Maine" },
    { Key: "Maryland", Value: "Maryland" },
    {
      Key: "Massachusetts",
      Value: "Massachusetts",
    },
    { Key: "Michigan", Value: "Michigan" },
    { Key: "Minnesota", Value: "Minnesota" },
    {
      Key: "Mississippi",
      Value: "Mississippi",
    },
    { Key: "Missouri", Value: "Missouri" },
    { Key: "Montana", Value: "Montana" },
    { Key: "Nebraska", Value: "Nebraska" },
    { Key: "Nevada", Value: "Nevada" },
    { Key: "New Hampshire", Value: "New Hampshire" },
    {
      Key: "New Jersey",
      Value: "New Jersey",
    },
    { Key: "New Mexico", Value: "New Mexico" },
    { Key: "New York", Value: "New York" },
    {
      Key: "North Carolina",
      Value: "North Carolina",
    },
    { Key: "North Dakota", Value: "North Dakota" },
    { Key: "Ohio", Value: "Ohio" },
    {
      Key: "Oklahoma",
      Value: "Oklahoma",
    },
    { Key: "Oregon", Value: "Oregon" },
    { Key: "Pennsylvania", Value: "Pennsylvania" },
    {
      Key: "Rhode Island",
      Value: "Rhode Island",
    },
    { Key: "South Carolina", Value: "South Carolina" },
    {
      Key: "South Dakota",
      Value: "South Dakota",
    },
    { Key: "Tennessee", Value: "Tennessee" },
    { Key: "Texas", Value: "Texas" },
    { Key: "Utah", Value: "Utah" },
    { Key: "Vermont", Value: "Vermont" },
    { Key: "Virginia", Value: "Virginia" },
    { Key: "Washington", Value: "Washington" },
    {
      Key: "West Virginia",
      Value: "West Virginia",
    },
    { Key: "Wisconsin", Value: "Wisconsin" },
    { Key: "Wyoming", Value: "Wyoming" },
  ];
  static roles: any[] = [
    { Key: "Admin", Value: "Admin" },
    { Key: "Customer", Value: "Customer" },
  ];
  static departments: any[] = [
    { Key: "Administration", Value: "Administration" },
    { Key: "Billing", Value: "Billing" },
    { Key: "Maintenance", Value: "Maintenance" },
    { Key: "Fleet Manager", Value: "Fleet Manager" },
  ];
  static onlyAdminDepartment: any[] = [
    { Key: "Administration", Value: "Administration" },
  ];

  static inspectionOrgName: any[] = [
    { Key: "Citridot", Value: "Citridot" },
    { Key: "Solax", Value: "Solax" },
  ];

  static status: any[] = [
    { Key: "Active", Value: "Active" },
    { Key: "Inactive", Value: "Inactive" },
  ];

  static inspectorStatus: any[] = [
    { Key: "ACTIVE", Value: "ACTIVE" },
    { Key: "INACTIVE", Value: "INACTIVE" },
  ];

  static userType: any[] = [
    { Key: "INSPECTOR", Value: "INSPECTOR" },
    { Key: "ADMIN INSPECTOR", Value: "ADMIN INSPECTOR" },
  ];

  static country: any[] = [
    { Key: "USA", Value: "USA" },
    { Key: "Canada", Value: "Canada" },
  ];

  static documentStatus: any[] = [{ Key: "SCHEDULED", Value: "SCHEDULED" }];

  static isLoaded: any[] = [
    { Key: "Is Loaded", Value: "Is Loaded" },
    { Key: "Not Loaded", Value: "Not Loaded" },
  ];

  static truckAxles: any[] = [
    { Key: "1", Value: "1" },
    { Key: "2", Value: "2" },
    { Key: "3", Value: "3" },
    { Key: "4", Value: "4" },
    { Key: "5", Value: "5" },
    { Key: "6", Value: "6" },
    { Key: "7", Value: "7" },
    { Key: "8", Value: "8" },
    { Key: "9", Value: "9" },
    { Key: "10", Value: "10" },
  ];

  static trailerAxles: any[] = [
    { Key: "1", Value: "1" },
    { Key: "2", Value: "2" },
    { Key: "3", Value: "3" },
    { Key: "4", Value: "4" },
    { Key: "5", Value: "5" },
    { Key: "6", Value: "6" },
    { Key: "7", Value: "7" },
    { Key: "8", Value: "8" },
    { Key: "9", Value: "9" },
    { Key: "10", Value: "10" },
  ];

  static inspectionType: any[] = [{ Key: "Interchange", Value: "Interchange" }];

  static EQUIP_YEARS = [
    { Key: "1990", Value: "1990" },
    { Key: "1991", Value: "1991" },
    {
      Key: "1992",
      Value: "1992",
    },
    { Key: "1993", Value: "1993" },
    { Key: "1994", Value: "1994" },
    { Key: "1995", Value: "1995" },
    { Key: "1996", Value: "1996" },
    {
      Key: "1997",
      Value: "1997",
    },
    { Key: "1998", Value: "1998" },
    { Key: "1999", Value: "1999" },
    { Key: "2000", Value: "2000" },
    { Key: "2001", Value: "2001" },
    {
      Key: "2002",
      Value: "2002",
    },
    { Key: "2003", Value: "2003" },
    { Key: "2004", Value: "2004" },
    { Key: "2005", Value: "2005" },
    { Key: "2006", Value: "2006" },
    {
      Key: "2007",
      Value: "2007",
    },
    { Key: "2008", Value: "2008" },
    { Key: "2009", Value: "2009" },
    { Key: "2010", Value: "2010" },
    { Key: "2011", Value: "2011" },
    {
      Key: "2012",
      Value: "2012",
    },
    { Key: "2013", Value: "2013" },
    { Key: "2014", Value: "2014" },
    { Key: "2015", Value: "2015" },
    { Key: "2016", Value: "2016" },
    {
      Key: "2017",
      Value: "2017",
    },
    { Key: "2018", Value: "2018" },
    { Key: "2019", Value: "2019" },
    { Key: "2020", Value: "2020" },
    { Key: "2021", Value: "2021" },
    {
      Key: "2022",
      Value: "2022",
    },
  ];

  static EQUIP_AXIS = [
    { Key: "1", Value: "1" },
    { Key: "2", Value: "2" },
    { Key: "3", Value: "3" },
    {
      Key: "4",
      Value: "4",
    },
    { Key: "5", Value: "5" },
    { Key: "6", Value: "6" },
    { Key: "7", Value: "7" },
    { Key: "8", Value: "8" },
    { Key: "9", Value: "9" },
    { Key: "10", Value: "10" },
    {
      Key: "11",
      Value: "11",
    },
    { Key: "12", Value: "12" },
  ];

  static EQUIP_AXEL_SPREAD = [
    { Key: "49", Value: 'Fixed 49"' },
    { Key: "60", Value: 'Fixed 60"' },
    {
      Key: "72",
      Value: 'Fixed 72"',
    },
    { Key: "120", Value: 'Fixed 120"' },
    { Key: "adjustable_2", Value: "Adjustable 2 positions" },
    { Key: "adjustable_3", Value: "Adjustable 3 positions" },
    { Key: "other", Value: "Other" },
  ];
  static TIRE_INFLATION_SYSTEM = [
    { Key: "Yes", Value: "Yes" },
    { Key: "No", Value: "No" },
  ];
  static SUSPENSION_TYPE = [
    { Key: "Air Ride", Value: "Air Ride" },
    { Key: "spring", Value: "spring" },
    {
      Key: "Other",
      Value: "Other",
    },
  ];
  static REAR_DOOR = [
    { Key: "Roll-up Door", Value: "Roll-up Door" },
    { Key: "Swing Doors", Value: "Swing Doors" },
  ];
  static FLOOR_TYPE = [
    { Key: "Flat Floor", Value: "Flat Floor" },
    { Key: "Duct Floor", Value: "Duct Floor" },
  ];
  static SIDE_DOOR = [
    { Key: "None", Value: "None" },
    { Key: "Front Driver Door", Value: "Front Driver Door" },
    { Key: "Front Passenger Door", Value: "Front Passenger Door" },
    {
      Key: "Back Driver Door",
      Value: "Back Driver Door",
    },
    { Key: "Back Passenger Door", Value: "Back Passenger Door" },
  ];
  static ROOF_DOOR = [
    { Key: "aluminum", Value: "Aluminum" },
    { Key: "translucent", Value: "Translucent" },
  ];
  static FLOOR_EQUIP = [
    { Key: "Wood", Value: "Wood" },
    { Key: "Aluminum", Value: "Aluminum" },
  ];
  static INTERIOR_FINISH_EQUIP = [
    { Key: "Plastic", Value: "Plastic" },
    { Key: "Plywood", Value: "Plywood" },
    { Key: "Steel", Value: "Steel" },
    { Key: "Kemlite", Value: "Kemlite" },
  ];
  static LOGISTIC_TYPES_EQUIP = [
    { Key: "16", Value: '16"' },
    { Key: "24", Value: '24"' },
    {
      Key: "48",
      Value: '48"',
    },
    { Key: "other", Value: "Other" },
  ];
  static LIFT_TYPES = [
    { Key: "Slider", Value: "Slider" },
    { Key: "Tuck Away", Value: "Tuck Away" },
    {
      Key: "Railgate",
      Value: "Railgate",
    },
  ];
  static LIFT_CAPACITY = [
    { Key: "3,000 lbs or less", Value: "3,000 lbs or less" },
    {
      Key: "3,001 lbs @ 4,000 lbs",
      Value: "3,001 lbs @ 4,000 lbs",
    },
    { Key: "4,001 lbs @ 5,000 lbs", Value: "4,001 lbs @ 5,000 lbs" },
    {
      Key: "5,001 lbs @ 6,000 lbs",
      Value: "5,001 lbs @ 6,000 lbs",
    },
    { Key: "Other", Value: "Other" },
  ];

  static TRACKING_TYPES = [
    { Key: "None", Value: "None" },
    { Key: "Hubodometer", Value: "Hubodometer" },
  ];

  static EQUIP_UNIT_MAKES = [
    { Key: "Carrier", Value: "Carrier" },
    {
      Key: "Thermoking",
      Value: "Thermoking",
    },
    { Key: "Zanotti", Value: "Zanotti" },
  ];
  static EQUIP_UNIT_MODELS = [
    { Key: "Model1", Value: "Model1" },
    { Key: "Model2", Value: "Model2" },
  ];
  static EQUIP_UNIT_YEAR = [
    { Key: "2011", Value: "2011" },
    { Key: "2012", Value: "2012" },
  ];
  static EQUIP_MULTI_TEMP = [
    { Key: "Yes", Value: "Yes" },
    { Key: "No", Value: "No" },
  ];
  static EQUIP_MULTI_TEMP_DEF = { Key: "No", Value: "No" };
  static EQUiP_MODELS = [
    { key: "Model1", value: "Model1" },
    { key: "Model2", value: "Model2" },
    {
      key: "Model3",
      value: "Model3",
    },
  ];

  static EQUIP_IMAGES = "pictures";
  static EQUIP_DAMAGES = "damages";
  static EQUIP_DOCS = "documents";
  static EQUIP_CLAIMS = "claims";
  static EQUIP_RESPONSES = "messages";
  static EQUIP_HODOMETER = "hodometer";
  static EQUIP_REEFER_TYPE = "reefertype";
  static EQUIP_POD = "POD";
  static INSURANCE_TERMS_CONDITIONS = "Insurance Terms & Conditions";
  static INSURANCE_BROUCHER = "Insurance Broucher";
  static INSURANCE_CUST_DOC = "Insurance Customer Document";

  static EQUIP_DRIVER_SIDE = "Driver side Exterior";
  static DRIVER_SIDE_EXTERIOR = "Driver Side Exterior";
  static EQUIP_DRIVER_TIRES = "Driver side Tires";
  static EQUIP_REAR_EXTERIOR = "Rear Exterior";
  static VAN_INTERIOR = "Van Interior";
  static PASSENGER_SIDE = "Exterior Passenger Side";
  static PASSENGER_SIDE_TIRE = "Passenger Side Tire";
  static FRONT_EXTERIOR = "Front Exterior";

  static LICENSE_IMAGES = "license";
  static ACTIVATION = "Activation";

  static PRIVATE_EQUIP = "private";
  static PUBLIC_EQUIP = "public";
  static RENTER_EQUIP = "renter";

  static SCREEN_NAME = "";

  static EQUIPMENTS = Events.EQUIPMENTS;
  static AVAILABILITIES = "Availabilities";

  static OWNER_RESERVATION = "Owner Reservations";
  static RENTER_RESERVATIONS = "Renter Reservations";

  static OWNER_OFFERS = "Owner Offers";
  static RENTER_OFFERS = "Renter Offers";
  static INSURANCE_PLANS = "Insurance Plans";

  static SELECT_EQUIP_ID = "";
  static SELECT_EQUIP_NAME = "";
  static SELECT_EQUIP_AVAIL_ID = "";
  static EQUIP_AVAIL_STATUS = "";
  static SELECT_EQUIP_AVAIL_TYPE = "";
  static SELECT_RESERVATION_ID = "";
  static SELECT_RESERVATION_IS_ANY_AVAILABLE: boolean = false;
  static SELECT_RESERVATION_CODE = "";
  static SELECT_RESERVATION_STATUS = "";
  static SCHEDULED_START_DATE_MILLI_SECONDS = ""; //scheduledStartDateMilliSeconds
  static EQUIP_RESERVE_ASSET_TYPE = "";
  static EQUIP_RESERVE_EQUIPMENT_TYPE = "";
  static EQUIP_RESERVE_IS_GENERIC = "";
  static SELECT_EQUIPMENT_TYPE = "";
  static IS_VIN_EXIST: boolean = false;
  static REEFER_EQUIP_TYPE = "REEFER VAN";
  static DEFAULT_EQUIP = {
    key: CommonUtil.ASSET_TYPE_TRAILER,
    value: CommonUtil.ASSET_TYPE_TRAILER,
  };
  static DEFAULT_INSPECTION_STATUS = {
    key: CommonUtil.INSPECTION_STATUS_ACTIVE,
    value: CommonUtil.INSPECTION_STATUS_ACTIVE,
  };

  static SCREEN_FROM = "";
  static OFFER_RECEIVED = "Offer_Received";
  static OFFER_MADE = "Offer_Made";
  static EQUIP_AVAILABILITY_STATUS = "";
  static EQUIP_AVAILABILITY_STATUS_COMPLETED = "Completed";
  static EQUIP_AVAILABILITY_STATUS_IN_PROGRESS = "In Progress";
  static EQUIP_AVAILABILITY_STATUS_OPEN = "Open";

  static RESERVATION_ACTIVATION = "Activation";
  static RESERVATION_IN_ACTIVATION = "Inactivation";

  static RESERVATION_CATEGORY = "";

  static RESERVATION_UPCOMING: string = "Upcoming";
  static RESERVATION_INPROGRESS: string = "In Progress";
  static RESERVATION_COMPLETED: string = "Done";
  static RESERVATION_ACTIVATION_DUE: string = "Activation Due";
  static RESERVATION_DE_ACTIVATION_DUE: string = "Deactivation Due";
  static RESERVATION_LATE_ACTIVATION: string = "Late Activation";
  static RESERVATION_DELETED: string = "Deleted";

  static ADD_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;

  static SCREEN_TYPE: string = "";

  static RESERVATION_SCREEN_TYPE: string = "Reservation";

  static ORG_CURRENCY:string = "";

  static PERMITTED_SCREENS = [
    {
      mainMenu: "DASHBOARDS",
      subMenu: "Search Dashboard",
      icon: "../../images/arrow.svg",
    },
    {
      mainMenu: "DASHBOARDS",
      subMenu: "Company Dashboard",
      icon: "../../images/map.svg",
    },
    {
      mainMenu: "DASHBOARDS",
      subMenu: "Invoices",
      icon: "../../images/invoices.svg",
    },
    {
      mainMenu: "PROCESS",
      subMenu: " Process Reservations",
      icon: "../../images/360.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: "Organizations",
      icon: "../../images/cust-profile.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: " Customer Profile",
      icon: "../../images/360.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: "Equipment",
      icon: "../../images/equipment.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: "Drivers",
      icon: "../../images/driver-mgmt.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: "Parking Lots",
      icon: "../../images/360.svg",
    },
    {
      mainMenu: "ADMINISTRATION",
      subMenu: "Notifications",
      icon: "../../images/notifications.svg",
    },
    {
      mainMenu: "REPORTS",
      subMenu: "Process Reservations",
      icon: "../../images/360.svg",
    },
    { mainMenu: "REPORTS", subMenu: "Equipment", icon: "../../images/360.svg" },
    {
      mainMenu: "REPORTS",
      subMenu: "Availabilities",
      icon: "../../images/360.svg",
    },
    { mainMenu: "PROCESS", subMenu: "Invoices", icon: "../../images/360.svg" },
    {
      mainMenu: "PROCESS",
      subMenu: "Reservations",
      icon: "../../images/360.svg",
    },
  ];

  static CLAIM_TYPES = [
    { key: "Fines", value: "Fines" },
    { key: "Toll Bridge or Roads", value: "Toll Bridge or Roads" },
    { key: "Repairs and Maintenance", value: "Repairs and Maintenance" },
    { key: "Administration Fees", value: "Administration Fees" },
    { key: "Physical Damage", value: "Physical Damage" },
    { key: "Others", value: "Others" },
  ];

  static CLAIM_STATUES = [
    { key: "Approved", value: "Approved" },
    { key: "Reject", value: "Reject" },
  ];

  static getCountry(): string {
    var str = "";
    if (localStorage.getItem("country")) {
      str = localStorage.getItem("country") as string;
      return str;
    }
    return "";
  }

  static getMesurementByCountry(): string {
    var str = "";
    if (localStorage.getItem("country")) {
      str = localStorage.getItem("country") as string;
      if (str == this.US) {
        return "MILES";
      } else if (str == this.CANADA) {
        return "KM";
      }
      return "MILES";
    }
    return "";
  }
  static getMesurementByCountryName(country: string): string {
    if (country == this.US) {
      return "MILES";
    } else if (country == this.CANADA) {
      return "KM";
    }
    return "MILES";
  }

  static getSelectedOrgName(): string {
    var str = "";
    if (localStorage.getItem("orgName")) {
      str = localStorage.getItem("orgName") as string;
      return str;
    }
    return "";
  }

  static getCustomerCountry(): string {
    return this.CUSTOMER_COUNTRY;
  }

  static showAvailableCount(cnt?: number): any {
    var svgIcon: any = {};
    var template = [
      '<?xml version="1.0"?>',
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">' +
      '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
      '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
      '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>' +
      '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>' +
      '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
      '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
      '<text x="15" y="25" fill="white" font-weight="bold">' +
      (cnt ? cnt : "") +
      "</text></g></g>" +
      "</svg>",
    ].join("\n");

    if (cnt && cnt.toString().length > 1) {
      template = [
        '<?xml version="1.0"?>',
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">' +
        '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
        '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
        '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>' +
        '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>' +
        '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
        '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
        '<text x="10" y="25" fill="white" font-weight="bold">' +
        (cnt ? cnt : "") +
        "</text></g></g>" +
        "</svg>",
      ].join("\n");
    }
    svgIcon.url =
      "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(template);
    svgIcon.scaledSize = new google.maps.Size(50, 50);
    return svgIcon;
  }

  static showClusterAvailableCount(cnt?: number): any {
    var svgIcon: any = {};
    var template = [
      '<?xml version="1.0"?>',
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">' +
      '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
      '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
      '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>' +
      '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>' +
      '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
      '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
      '<text x="15" y="25" fill="white" font-weight="bold">' +
      (cnt ? cnt : "") +
      "</text></g></g>" +
      "</svg>",
    ].join("\n");

    if (cnt && cnt.toString().length > 1) {
      template = [
        '<?xml version="1.0"?>',
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="63" height="70"><defs><filter id="a" width="144.4%" height="139%" x="-22.2%" y="-17.7%" filterUnits="objectBoundingBox">' +
        '<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>' +
        '<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.215686275 0 0 0 0 0.223529412 0 0 0 0 0.207843137 0 0 0 0.3 0"/></filter>' +
        '<path id="b" d="M31.866 49.043l-4.738 6.783a2.415 2.415 0 0 1-3.96 0l-4.737-6.783C8.024 46.115.395 36.554.395 25.212.395 11.54 11.477.458 25.149.458c13.67 0 24.753 11.082 24.753 24.754 0 11.342-7.629 20.903-18.036 23.83z"/></defs>' +
        '<g fill="none" fill-rule="evenodd" transform="translate(6.5 5.5)"><ellipse cx="25" cy="59.066" fill="#000" opacity=".2" rx="10.264" ry="5.434"/>' +
        '<g fill-rule="nonzero"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g>' +
        '<g transform="translate(5.225 4.08)"><circle cx="19.924" cy="20.835" r="22.327" fill="#E31B1A" fill-rule="nonzero" stroke="#FFF" stroke-width="4.807"/>' +
        '<text x="10" y="25" fill="white" font-weight="bold">' +
        (cnt ? cnt : "") +
        "</text></g></g>" +
        "</svg>",
      ].join("\n");
    }

    svgIcon.url =
      "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(template);
    svgIcon.scaledSize = new google.maps.Size(50, 50);
    return svgIcon;
  }

  static getCurrency(): string {
    return this.CUSTOMER_COUNTRY === this.CANADA
      ? this.CANADA_CURRENCY
      : this.US_CURRENCY;
  }

  static getSelectedOrgId(): string {
    let str = "";
    if (localStorage.getItem("saasorgId")) {
      str = localStorage.getItem("saasorgId") as string;
      return str;
    }
    return "";
  }
  static getSelectedOrgIdOnly(): string {
    let str = "";
    if (localStorage.getItem("orgId")) {
      str = localStorage.getItem("orgId") as string;
      return str;
    }
    return "";
  }

  static getUserId(): string {
    let str = "";
    if (localStorage.getItem("userId")) {
      str = localStorage.getItem("userId") as string;
      return str;
    }
    return "";
  }

  static getSelectOrgType(): string {
    return this.SELECT_ORG_TYPE;
  }

  static addValidClass(tag: HTMLInputElement) {
    if (tag) {
      tag.classList.add("is-valid");
    }
  }
 static setOrgCurrency(val:string) {
    this.ORG_CURRENCY = val;
  }
  static isRootUser(): boolean {
    if (
      localStorage.getItem("root") &&
      localStorage.getItem("root") === "true"
    ) {
      return true;
    }
    return false;
  }

  static isRootSwitchUser(): boolean {
    if (
      (localStorage.getItem("root") &&
        localStorage.getItem("root") === "true") ||
      (localStorage.getItem("switchCustomer") &&
        localStorage.getItem("switchCustomer") === "true")
    ) {
      return true;
    }
    return false;
  }

  static getSaasOrgId(): any {
    return localStorage.getItem("saasorgId");
  }

  static getInspectorId(): any {
    return localStorage.getItem("inspectorId");
  }

  static getDocumentStatus(): any {
    return localStorage.getItem("DocumentStatus");
  }

  static getInspectionSaasOrgId(): any {
    return localStorage.getItem("inspectionorgId");
  }

  static getInterchangeDocumentId(): any {
    return localStorage.getItem("documentId");
  }

  static getInterchangeDocumentCode(): any {
    return localStorage.getItem("documentCode")
  }

  static getOwnerOrgId(): any {
    return localStorage.getItem("ownerOrgId");
  }

  static isValidEmail(mail: string): boolean {
    var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    if (email_regex.test(mail)) {
      return true;
    }
    return false;
  }

  static isEmailMandatory(fldRef: any): boolean {
    var fieldValue = fldRef.current?.value;
    if (fieldValue === "") {
      fldRef.current?.classList.add("is-invalid");
      fldRef.current?.classList.remove("is-valid");
      return false;
    } else {
      var flag: boolean = CommonUtil.isValidEmail(fieldValue);
      if (flag) {
        fldRef.current?.classList.add("is-valid");
        fldRef.current?.classList.remove("is-invalid");
        return true;
      } else {
        fldRef.current?.classList.add("is-invalid");
        fldRef.current?.classList.remove("is-valid");
        return false;
      }
    }
  }

  static trim(str: string): string {
    return str.trim();
  }

  static isMandatory(fldRef: any): boolean {
    var fieldValue = fldRef.current?.value;
    if (fieldValue) {
      fieldValue = fieldValue.trim();
      if (fieldValue === "") {
        fldRef.current?.classList.add("is-invalid");
        fldRef.current?.classList.remove("is-valid");
        return false;
      } else {
        fldRef.current?.classList.add("is-valid");
        fldRef.current?.classList.remove("is-invalid");
        return true;
      }
    }
    if (fldRef?.current?.required) {
      fieldValue = fieldValue.trim();
      if (fieldValue === "") {
        fldRef.current?.classList.add("is-invalid");
        fldRef.current?.classList.remove("is-valid");
        return false;
      }
    }
    return false;
  }

  static setClaimId(id: any) {
    this.CLAIMID = id;
  }
  static setBrokerId(id: any) {
    this.BROKERID = id;
  }
  static setCarrierId(id: any) {
    this.CARRIERID = id;
  }
  static setDocumentCode(documentCode: any) {
    this.DOCUMENTCODE = documentCode;
  }
  static getDocumentCode(): any {
    return this.DOCUMENTCODE
  }
  static getClaimId(): any {
    return this.CLAIMID;
  }
  static getBrokerId(): any {
    return this.BROKERID
  }
  static getCarrierId(): any {
    return this.CARRIERID
  }


  static isDropDownMandatory = (dataObj: any, setObj: any) => {
    dataObj ? setObj("select-box-valid") : setObj("select-box-invalid");
  };

  static getServerDate = (strDt: any) => {
    if (strDt) {
      let stDtArr: any = strDt.split("-");
      if (stDtArr.length > 0) {
        /* let dt = new Date();
                 dt.setDate(stDtArr[2]);
                 dt.setMonth((stDtArr[1])-1);
                 dt.setFullYear(stDtArr[0]);*/

        let dt = new Date(stDtArr[0], stDtArr[1] - 1, stDtArr[2]);

        return dt;
      }
    }

    return null;
  };
  static getServerSearchDBDate = (strDt: any) => {
    if (strDt) {
      let stDtArr: any = strDt.split("-");
      if (stDtArr.length > 0) {
        let dt = new Date(stDtArr[0], stDtArr[1] - 1, stDtArr[2]);
        //dt.setDate(stDtArr[2]);
        //dt.setMonth((stDtArr[1])-1);
        //dt.setFullYear(stDtArr[0]);

        return dt;
      }
    }

    return null;
  };

  static getReservationServerDate = (strDt: any) => {
    if (strDt) {
      let stDtArr: any = strDt.split(" ");
      if (stDtArr.length > 0) {
        let dt = null; //this.getServerDate(stDtArr[0]);
        let dateArr = stDtArr[0].split("-");
        let strTmArr = stDtArr[1].split(":");
        if (strTmArr.length > 0 && dateArr.length) {
          dt = new Date(
            dateArr[0],
            dateArr[1] - 1,
            dateArr[2],
            strTmArr[0],
            strTmArr[1],
            strTmArr[2]
          );
          // dt.setHours(strTmArr[0]);
          // dt.setMinutes(strTmArr[1]);
          // dt.setSeconds(strTmArr[2]);
        } else {
          dt = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
        }

        return dt;
      }
    }

    return null;
  };

  static getReservationBillDate = (strDt: any) => {
    if (strDt) {
      let stDtArr: any = strDt.split(" ");
      if (stDtArr.length > 0) {
        //let dt = new Date();//CommonUtil.getServerDate(stDtArr[0]);
        let strDateArr = stDtArr[0].split("-");
        /*dt.setDate(strDateArr[2]);
                dt.setMonth((strDateArr[1])-1);
                dt.setFullYear(strDateArr[0]);*/

        let dt = new Date(strDateArr[0], strDateArr[1] - 1, strDateArr[2]);

        let strTmArr = stDtArr[1].split(":");
        if (strTmArr.length > 0 && dt) {
          // dt.setHours(strTmArr[0]);
          // dt.setMinutes(strTmArr[1]);
          // dt.setSeconds(strTmArr[2]);
        }

        return dt;
      }
    }

    return null;
  };

  static getDate(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strYear + "-" + strMonth + "-" + strDate;
      }
    }
    return "";
  }

  static getDateFormatForDatePicker(dt: any): string {
    if (dt) {
      let arr: any = dt.split("-");
      let date = new Date(Number(arr[0]), Number(arr[1] - 1), Number(arr[2])); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strMonth + "-" + strDate + "-" + strYear;
      }
    }
    return "";
  }

  static getDateInterchange(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strMonth + "-" + strDate + "-" + strYear;
      }
    }
    return "";
  }

  static getDateTimeInterchange(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strMonth + "-" + strDate + "-" + strYear;
      }
    }
    return "";
  }

  static getDateTime(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return (
          strYear +
          "-" +
          strMonth +
          "-" +
          strDate +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds()
        );
      }
    }
    return "";
  }
  static getBillDate(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return (
          strYear +
          "-" +
          strMonth +
          "-" +
          strDate +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds()
        );
      }
    }
    return "";
  }
  static getRentalBillDate(dt: any): string {
    if (dt && dt.length > 0) {
      let date = new Date(dt[0]); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        let hours = "23";
        let minutes = "59";
        let seconds = "59";
        return (
          strYear +
          "-" +
          strMonth +
          "-" +
          strDate +
          " " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
        );
      }
    }
    return "";
  }

  static getAcceptDate(dt: any): string {
    if (dt) {
      let date = new Date(dt); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strYear + "-" + strMonth + "-" + strDate;
      }
    }
    return "";
  }

  static getOfferDate(dt: any): string {
    if (dt) {
      let date = new Date(dt); //this.getServerDate(dt[0]);//
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strMonth + "-" + strDate + "-" + strYear;
      }
    }
    return "";
  }

  static getDateString(dt: any): string {
    if (dt) {
      let date = this.getServerDate(dt); //new Date(dt);//this.getServerDate(dt);//new Date(dt);
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strYear + "-" + strMonth + "-" + strDate;
      }
    }
    return "";
  }
  static getReservationDateString(dt: any): string {
    if (dt) {
      let date = new Date(dt); //new Date(dt);//this.getServerDate(dt);//new Date(dt);
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strYear + "-" + strMonth + "-" + strDate;
      }
    }
    return "";
  }
  static getReservationRepositionDateString(dt: any): string {
    if (dt) {
      let date = new Date(dt); //new Date(dt);//this.getServerDate(dt);//new Date(dt);
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        let hours = 23;
        let minutes = 59;
        let seconds = 59;
        return (
          strYear +
          "-" +
          strMonth +
          "-" +
          strDate +
          " " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
        );
      }
    }
    return "";
  }
  static getDateTimeString(dt: any): string {
    if (dt) {
      let date = new Date(dt); //this.getServerDate(dt);//new Date(dt);
      if (date) {
        let strMonth: string = (
          (date.getMonth() + 1).toString().length === 1
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        ) as string;
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        let strHour: string = (
          date.getHours().toString().length === 1
            ? "0" + date.getHours().toString()
            : date.getHours().toString()
        ) as string;
        let strMinutes: string = (
          date.getMinutes().toString().length === 1
            ? "0" + date.getMinutes().toString()
            : date.getMinutes().toString()
        ) as string;
        let strSeconds: string = (
          date.getSeconds().toString().length === 1
            ? "0" + date.getSeconds().toString()
            : date.getSeconds().toString()
        ) as string;
        return (
          strYear +
          "-" +
          strMonth +
          "-" +
          strDate +
          " " +
          strHour +
          ":" +
          strMinutes +
          ":" +
          strSeconds
        );
      }
    }
    return "";
  }

  static getDay(dt: any): string {
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        return "" + date.getDate();
      }
    }
    return "";
  }

  static getMonth(dt: any): string {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        return "" + months[date.getMonth()];
      }
    }
    return "";
  }

  static getFullMonth(dt: any): string {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        return "" + months[date.getMonth()];
      }
    }
    return "";
  }

  static getFullYear(dt: any): string {
    // let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        return "" + date.getFullYear();
      }
    }
    return "";
  }

  static getReservationDate(dt: any): string {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (dt && dt.length > 0) {
      let date = this.getReservationServerDate(dt); //new Date(dt);
      if (date) {
        let strMonth: string = months[date.getMonth()];
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        let strHours: string = (
          date.getHours().toString().length === 1
            ? "0" + date.getHours()
            : date.getHours()
        ) as string;
        let strMins: string = (
          date.getMinutes().toString().length === 1
            ? "0" + date.getMinutes()
            : date.getMinutes()
        ) as string;
        let strSecs: string = (
          date.getSeconds().toString().length === 1
            ? "0" + date.getSeconds()
            : date.getSeconds()
        ) as string;
        return (
          strDate +
          " " +
          strMonth +
          " " +
          strYear +
          " at " +
          strHours +
          ":" +
          strMins +
          ":" +
          strSecs
        ); //+ " UTC";
      }
    }
    return "";
  }

  static getReservationMobileDate(dt: any): string {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        let strMonth: string = months[date.getMonth()];
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        let strHours: string = (
          date.getHours().toString().length === 1
            ? "0" + date.getHours()
            : date.getHours()
        ) as string;
        let strMins: string = (
          date.getMinutes().toString().length === 1
            ? "0" + date.getMinutes()
            : date.getMinutes()
        ) as string;
        let strSecs: string = (
          date.getSeconds().toString().length === 1
            ? "0" + date.getSeconds()
            : date.getSeconds()
        ) as string;
        return (
          strDate +
          " " +
          strMonth +
          " " +
          strYear +
          " at " +
          strHours +
          ":" +
          strMins +
          ":" +
          strSecs
        ); //+ " UTC";
      }
    }
    return "";
  }

  static getDBDate(dt: any): string {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        let strMonth: string = months[date.getMonth()];
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strDate + " " + strMonth + " " + strYear;
      }
    }
    return "";
  }
  static getSearchDBDate(dt: any): string {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerSearchDBDate(dt); //new Date(dt);
      if (date) {
        let strMonth: string = months[date.getMonth()];
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strDate + " " + strMonth + " " + strYear;
      }
    }
    return "";
  }
  static getMobileDBDate(dt: any): string {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (dt && dt.length > 0) {
      let date = this.getServerDate(dt); //new Date(dt);
      if (date) {
        let strMonth: string = months[date.getMonth()];
        let strDate: string = (
          date.getDate().toString().length === 1
            ? "0" + date.getDate()
            : date.getDate()
        ) as string;
        let strYear: string = date.getFullYear().toString() as string;
        return strDate + " " + strMonth + " " + strYear;
      }
    }
    return "";
  }

  static getDays(stDate: any, endDate: any): number {
    let endDate1: any = new Date(endDate);
    let stDate1: any = new Date(stDate);
    endDate1 = new Date(
      Date.UTC(
        endDate1.getFullYear(),
        endDate1.getMonth(),
        endDate1.getDate(),
        23,
        59,
        59
      )
    );
    stDate1 = new Date(
      Date.UTC(
        stDate1.getFullYear(),
        stDate1.getMonth(),
        stDate1.getDate(),
        0,
        0,
        0
      )
    );
    let days: number = 0;
    if (endDate1 && stDate1) {
      // endDate1.setHours(23, 59, 59);
      let endMs = 0;
      if (endDate1) {
        endMs = endDate1.getTime();
      }
      // stDate1.setHours(0, 0, 0);
      let stMs = 0;
      if (stDate1) {
        stMs = stDate1.getTime();
      }

      days = Math.abs(endMs - stMs) / (24 * 3600 * 1000);
      days = Math.ceil(days);
    }
    return days;
  }

  static getReservationDays(stDate: any, endDate: any): number {
    let endDate1: any = this.getServerDate(endDate); //new Date(endDate);
    let stDate1: any = this.getServerDate(stDate); //new Date(stDate);
    endDate1 = new Date(
      Date.UTC(
        endDate1.getFullYear(),
        endDate1.getMonth(),
        endDate1.getDate(),
        23,
        59,
        59
      )
    );
    stDate1 = new Date(
      Date.UTC(
        stDate1.getFullYear(),
        stDate1.getMonth(),
        stDate1.getDate(),
        0,
        0,
        0
      )
    );
    let days: number = 0;
    if (endDate1 && stDate1) {
      // endDate1.setHours(23, 59, 59);
      let endMs = 0;
      if (endDate1) {
        endMs = endDate1.getTime();
      }
      // stDate1.setHours(0, 0, 0);
      let stMs = 0;
      if (stDate1) {
        stMs = stDate1.getTime();
      }

      days = Math.abs(endMs - stMs) / (24 * 3600 * 1000);
      days = Math.ceil(days);
    }
    return days;
  }

  static getPlaceAddressByMap(inputField: any, result: any): any {
    let autocomplete = new google.maps.places.Autocomplete(inputField, {
      componentRestrictions: { country: ["us", "ca", "in"] },
    });
    autocomplete.addListener("place_changed", () => {
      var address: any = [];
      const place = autocomplete.getPlace();
      let postalcode: string = "";
      let country: string = "";
      let city: string = "";
      let state: string = "";
      let address1: string = "";
      let subLocality: string = "";
      let subLocalityToken: string = "";
      console.log(place);
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];
        // console.log(component);

        switch (componentType) {
          case "route": {
            address1 = component.long_name;
            break;
          }
          case "street_number": {
            address1 = address1 + "," + component.long_name;
            break;
          }
          case "postal_code": {
            postalcode = component.long_name; //`${component.long_name}${postcode}`;
            break;
          }
          case "locality":
            city = component.long_name;
            address1 = address1 + ", " + city;
            break;

          case "administrative_area_level_1": {
            state = component.long_name;
            address1 = address1 + ", " + state;
            break;
          }
          case "sublocality_level_1": {
            subLocality = component.long_name;
            //address1 = address1+", "+locality;
            break;
          }

          case "country":
            // country = component.long_name;
            country = component.short_name === "US"? "United States" : "Canada";
            address1 = address1 + ", " + country;
            break;
        }
      }
      //console.log(place.formatted_address);
      if (place) {
        address["rawAddress"] = place.formatted_address;
      } else {
        address["rawAddress"] = "";
      }
      address["address1"] = address1;
      address["postalcode"] = postalcode;
      address["country"] = country;
      address["state"] = state;
      if (subLocality) {
        address["city"] = subLocality;
      } else {
        address["city"] = city;
      }
      address["city"] = CommonUtil.getCityFromAddress(place.formatted_address);
      if (place && place.geometry && place.geometry.location) {
        address["lat"] = place.geometry.location.lat();
        address["lng"] = place.geometry.location.lng();
      }

      result(address);
    });
  }

  static getGoogleMapAddress(autoComplete: any): any {
    var address: any = [];
    const place = autoComplete.getPlace();
    let postalcode: string = "";
    let country: string = "";
    let city: string = "";
    let state: string = "";
    let subLocality: string = "";
    let subLocalityToken: string = "";
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      //console.log(component);
      switch (componentType) {
        case "route": {
          //address1 = component.short_name;
          break;
        }
        case "street_number": {
          // address1 = address1+","+`${component.long_name} ${address1}`;
          break;
        }
        case "postal_code": {
          postalcode = component.long_name; //`${component.long_name}${postcode}`;
          break;
        }
        case "locality": {
          city = component.long_name;
          //address1 = address1+", "+locality;
          break;
        }
        case "sublocality_level_1": {
          subLocality = component.long_name;
          //address1 = address1+", "+locality;
          break;
        }

        case "administrative_area_level_1": {
          state = component.long_name;
          //address1 = address1+", "+administrative;
          break;
        }

        case "country":
          // country = component.long_name;
          country = component.short_name === "US"? "United States" : "Canada";
          //address1 = address1+", "+country;
          break;
      }
    }
    address["rawAddress"] = place.formatted_address;
    address["postalcode"] = postalcode;
    address["country"] = country;
    address["state"] = state;
    if (subLocality) {
      address["city"] = subLocality;
    } else {
      address["city"] = city;
    }

    address["city"] = CommonUtil.getCityFromAddress(place.formatted_address);

    address["lat"] = place.geometry.location.lat();
    address["lng"] = place.geometry.location.lng();
    return address;
  }

  static getCityFromAddress(addr: any): string {
    let city = "";
    let tokens: any = addr.split(",");
    if (tokens.length > 2) {
      city = tokens[tokens.length - 3];
    }
    return city;
  }

  static getTimeZome(lat: string, lng: string, result: any): void {
    let timeZoneUrl: string =
      "https://maps.googleapis.com/maps/api/timezone/json?location=" +
      (lat + "," + lng) +
      "&timestamp=1331161200&key=" +
      this.GOOGLE_MAP_KEY;

    fetch(timeZoneUrl)
      .then((response) => response.json())
      .then((data) => {
        result(data);
      });
    //return timezone;
  }

  static getFormatedAddress(lat: number, lng: number, result: any): void {
    let timeZoneUrl: string =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      (lat + "," + lng) +
      "&key=" +
      this.GOOGLE_MAP_KEY;

    fetch(timeZoneUrl)
      .then((response) => response.json())
      .then((data) => {
        result(data);
      });
    //return timezone;
  }

  static getDistanceBetweenPoints(
    point1: any,
    point2: any,
    results: any
  ): void {
    if (window.google) {
      //const dakota = {lat: 40.7767644, lng: -73.9761399};
      //const frick = {lat: 40.771209, lng: -73.9673991};

      const route: any = {
        origin: point1,
        destination: point2,
        travelMode: "DRIVING",
      };

      let directionsService = new google.maps.DirectionsService();
      directionsService.route(route, (response: any, status) => {
        if (status === "OK") {
          let directionsData = response.routes[0].legs[0];
          console.log(directionsData);
          results(directionsData);
        } else {
          results(null);
        }
      });
    }
  }

  static convertMetersToKM(meters: number): number {
    return Number((meters / 1000).toFixed(0));
  }
  static convertMetersToMiles(meters: number): string {
    return (meters / 1609).toFixed(0);
  }

  static displayAddress(str: string): string {
    var addr: string = "";
    if (str.length > 40) {
      addr = str.substring(0, 40);
      addr = addr + " ...";
    } else {
      addr = str;
    }
    return addr;
  }

  static displayEnglsiMessages(result: any) {
    fetch("/english.json")
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item: any, idx: number) => {
          this.ENGLISH_JSON[item.MN] = item.MSG;
        });
        result();
      });
  }

  static displayFrenchiMessages(result: any) {
    fetch("/french.json")
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item: any, idx: number) => {
          this.FRENCH_JSON[item.MN] = item.MSG;
        });
        result();
      });
  }

  static displaySpanishiMessages(result: any) {
    //  let file:string = "/spanish.json";
    fetch("/spanish.json")
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item: any, idx: number) => {
          this.SPANISH_JSON[item.MN] = item.MSG;
        });
        result();
        //this.SPANISH_JSON = data;
      });
  }

  static getAllOrgs(result: any) {
    NetworkManager.sendJsonRequest(
      "api/organization/all",
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = JSON.parse(dataObj);
          if (restData) {
            if (restData.statusCode) {
              //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
              ToastManager.showToast(
                StatusCodeUtil.getCodeMessage(restData.statusCode),
                "Error"
              );
            } else {
              let orgs: any = [];
              if (Array.isArray(restData.organizations)) {
                orgs = restData.organizations;
              } else {
                orgs.push(restData.organizations);
              }
              result(orgs);
            }
          }
        }
      },
      () => { }
    );
  }

  static getAllOwnerOrgs(result: any) {
    NetworkManager.sendJsonRequest(
      "api/organization/allOwnerOrganizations",
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = JSON.parse(dataObj);
          if (restData) {
            if (restData.statusCode) {
              //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
              ToastManager.showToast(
                StatusCodeUtil.getCodeMessage(restData.statusCode),
                "Error"
              );
            } else {
              let orgs: any = [];
              if (Array.isArray(restData.organizations)) {
                orgs = restData.organizations;
              } else {
                orgs.push(restData.organizations);
              }
              result(orgs);
            }
          }
        }
      },
      () => { }
    );
  }

  static getAllAssetTypes(result: any) {
    let qParams: string = "saasOrgId=" + localStorage.getItem("saasorgId");
    let equipAllAPI: string = "api/equipment/assettypes?" + qParams;
    NetworkManager.sendJsonRequest(
      equipAllAPI,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = JSON.parse(dataObj);
          if (restData) {
            if (restData.statusCode) {
              //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
              ToastManager.showToast(
                StatusCodeUtil.getCodeMessage(restData.statusCode),
                "Error"
              );
            } else {
              let assets: any = [];
              if (Array.isArray(restData)) {
                assets = restData;
              } else {
                assets.push(restData);
              }
              result(assets);
            }
          }
        }
      },
      () => { }
    );
  }

  static getAllModels(result: any) {
    let qParams: string = "saasOrgId=" + localStorage.getItem("saasorgId");
    let equipAllAPI: string = "api/equipment/models?" + qParams;
    NetworkManager.sendJsonRequest(
      equipAllAPI,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = this.parseData(dataObj);
          if (restData) {
            let modals: any = [];
            if (Array.isArray(restData)) {
              modals = restData;
            } else {
              modals.push(restData);
            }
            result(modals);
          }
        }
      },
      () => { }
    );
  }

  static getEquipmentTypes(aTypeId: string, result: any) {
    let qParams: string = "saasOrgId=" + localStorage.getItem("saasorgId");
    let equipAllAPI: string =
      "api/equipment/equipmenttypes?" + qParams + "&assetTypeId=" + aTypeId;
    NetworkManager.sendJsonRequest(
      equipAllAPI,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = this.parseData(dataObj);
          if (restData) {
            let modals: any = [];
            if (Array.isArray(restData)) {
              modals = restData;
            } else {
              modals.push(restData);
            }
            result(modals);
          }
        }
      },
      () => { }
    );
  }

  static getBrands(aTypeId: string, result: any) {
    let qParams: string = "saasOrgId=" + localStorage.getItem("saasorgId");
    let equipAllAPI: string =
      "api/equipment/brands?" + qParams + "&assetTypeId=" + aTypeId;
    NetworkManager.sendJsonRequest(
      equipAllAPI,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = this.parseData(dataObj);
          if (restData) {
            let modals: any = [];
            if (Array.isArray(restData)) {
              modals = restData;
            } else {
              modals.push(restData);
            }
            result(modals);
          }
        }
      },
      () => { }
    );
  }

  static parseData(data: any): any {
    if (data) {
      var restData = JSON.parse(data);
      if (restData) {
        if (restData.statusCode) {
          ToastManager.showToast(restData.errorMessage, "Error");
        } else {
          return restData;
        }
      }
    }
    return null;
  }

  static getMessageText(msg: string, msgText?: string): string {
    var LAN: any = null;

    let lang: any = "";
    if (localStorage.getItem("Language")) {
      lang = localStorage.getItem("Language");
    } else {
      lang = "en";
    }
    if (lang === CommonUtil.ENG_LANGUAGE) {
      LAN = CommonUtil.ENGLISH_JSON;
    } else if (lang === CommonUtil.FR_LANGUAGE) {
      LAN = CommonUtil.FRENCH_JSON;
    } else if (lang === CommonUtil.SP_LANGUAGE) {
      LAN = CommonUtil.SPANISH_JSON;
    }
    if (LAN) {
      return LAN[msg] || msgText;
    }

    return msgText || "";
  }

  static onLoginError = (err: any) => {
    console.log(err);
  };

  static getDropdownText(
    list: any,
    searchText: string,
    searchKey: string,
    searchValue: string
  ): string {
    if (list) {
      for (let idx: number = 0; idx < list.length; idx++) {
        let item: any = list[idx];
        if (item && item[searchKey] === searchText) {
          return item[searchValue];
        }
      }
    }
    return "";
  }
  static getDropdownObject(
    list: any,
    searchText: string,
    searchKey: string,
    searchValue: string
  ): string {
    if (list) {
      for (let idx: number = 0; idx < list.length; idx++) {
        let item: any = list[idx];
        if (item && item[searchKey] === searchText) {
          return item; //[searchValue];
        }
      }
    }
    return "";
  }

  static dispatchEVents(): void {
    Events.dispatchOfferEVent();
    Events.dispatchReservationEVent();
    Events.dispatchShowAvailabilityButton();
  }

  static addScreensToLastAccessed(sName: string): void {
    if (sName.indexOf("requestor") >= 0) {
    } else {
      sName = "requestor=" + sName;
    }

    sessionStorage.setItem("LAS", sName);
  }

  static getOrganizationType(): string {
    let str = "";
    if (localStorage.getItem("orgType")) {
      str = localStorage.getItem("orgType") as string;
      return str;
    }
    return "";
  }

  static allowNumbersDecimal(event: any) {
    if (!/[0-9.]/.test(event.key)) {
      return event.preventDefault();
    }
  }
  static allowNumbersIntegers(event: any) {
    if (!/[0-9]/.test(event.key)) {
      return event.preventDefault();
    }
  }

  static handleNumbersDecimalInput({ event, ref, setState }: any) {
    if (/^\d*\.?\d{0,2}$/.test(event?.target?.value)) {
      let inputString = event.target.value.toString();
      if (inputString?.length === 1 && inputString === ".") {
        setState(`0.`);
      } else {
        setState(event.target.value);
      }
      if (ref) {
        this.isMandatory(ref);
      }
    } else {
      event.preventDefault();
    }
  }

  static getLastAccessScreens(): any {
    var lastAccessScreens: any = [];
    let obj = localStorage.getItem("lastAccessed");
    if (obj) {
      lastAccessScreens = JSON.parse(obj);
    }
    return lastAccessScreens;
  }
  static getLastAccessScreensByUser(): any {
    var lastAccessScreens: any = [];
    let obj = localStorage.getItem("lastAccessed");
    if (obj) {
      lastAccessScreens = JSON.parse(obj);
    }
    let screens: any = [];
    lastAccessScreens.forEach((item: any, idx: number) => {
      if (item.userName === CommonUtil.getUserId()) {
        screens.push(item);
      }
    });
    lastAccessScreens = screens;
    return lastAccessScreens;
  }

  static addScreensToCache(sName: string, aName: string, uName: string) {
    var lastAccessScreens: any = [];
    if (localStorage.getItem("lastAccessed")) {
      let obj = localStorage.getItem("lastAccessed");
      if (obj) {
        lastAccessScreens = JSON.parse(obj);
      }
    }
    let accessObject: any = {};
    accessObject.userName = uName;
    accessObject.screenName = sName;
    accessObject.actualName = aName;
    if (lastAccessScreens.length === 0) {
      lastAccessScreens.push(accessObject);
    } else {
      let screens: any = [];
      lastAccessScreens.forEach((item: any, idx: number) => {
        if (item.screenName !== sName) {
          screens.push(item);
        }
      });
      lastAccessScreens = screens;
      lastAccessScreens.unshift(accessObject);
    }

    localStorage.setItem("lastAccessed", JSON.stringify(lastAccessScreens));
  }

  static onLogout() {
    var lastAccessed = CommonUtil.getLastAccessScreens();
    localStorage.removeItem("country");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("orgId");
    localStorage.removeItem("saasorgId");
    localStorage.removeItem("root");
    const gridData = JSON.parse(String(localStorage.getItem("GridData"))) || {};
    localStorage.clear();
    localStorage.setItem("GridData", JSON.stringify(gridData));
    sessionStorage.clear();
    //navigate("/");
    setTimeout(() => {
      if (lastAccessed && lastAccessed.length > 0) {
        localStorage.setItem("lastAccessed", JSON.stringify(lastAccessed));
      }
      //window.location.reload();
      let newloc = window.location.origin;
      window.location.href = newloc;
    });
  }
  static onPLogout() {
    var lastAccessed = CommonUtil.getLastAccessScreens();
    localStorage.removeItem("country");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("orgId");
    localStorage.removeItem("saasorgId");
    localStorage.removeItem("root");
    const gridData = JSON.parse(String(localStorage.getItem("GridData"))) || {};
    localStorage.clear();
    localStorage.setItem("GridData", JSON.stringify(gridData));
    sessionStorage.clear();
    setTimeout(() => {
      if (lastAccessed && lastAccessed.length > 0) {
        localStorage.setItem("lastAccessed", JSON.stringify(lastAccessed));
      }
    });
    //navigate("/");
  }

  static validateInput(e: any) {
    if (!/^\d*\.?\d{0,2}$/.test(e?.target?.value)) return;
    return e;
  }
  static ifDecimal(val: any) {
    const value = val.toString();
    if (value?.length === 1 && value === ".") {
      return "0.";
    } else {
      return value;
    }
  }

  static getDatesDiff(startDate: string, endDate: string) {
    const date1: any = new Date(startDate);
    const date2: any = new Date(endDate);
    console.log(date2, date1);
    const diffTime: any = Math.abs(date2 - (date1 - 1));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffDays);
    return diffDays;
  }

  static getDocStatus(): any {
    return localStorage.getItem("INTERCHANGE-DOCSTATUS");
  }
  static setDocStatus(status:string): any {
    localStorage.setItem("INTERCHANGE-DOCSTATUS",status);
  }

  
  static getSelectedEventForCompare(): any {
    return localStorage.getItem("SELECTED-EVENT-COMPARE");
  }
  static setSelectedEventForCompare(status:string): any {
    localStorage.setItem("SELECTED-EVENT-COMPARE",status);
  }

  static daysToMonths(days: number) {
    return Math.ceil(days / 30);
  }

  static daysToWeeks(days: number) {
    return Math.ceil(days / 7);
  }

  static setCostEstimate(restData: any) {
    let resultObj: any = {
      Noofdays: restData.noOfDays,
      NoofWeeks: CommonUtil.daysToWeeks(restData.noOfDays),
      NoofMonths: CommonUtil.daysToMonths(restData.noOfDays),
      type:
        restData.dailyRatePlanType === "Monthly"
          ? Constants.MONTH
          : restData.dailyRatePlanType === "Weekly"
            ? Constants.WEEK
            : Constants.DAY,
      rate: restData.durationPrice,
      ratePerDay:
        restData.dailyRatePlanType === "Monthly"
          ? (restData.monthlyDailyRate ? restData.monthlyDailyRate/30 : 0)
          : restData.dailyRatePlanType === "Weekly"
            ? (restData.weeklyDailyRate ? restData.weeklyDailyRate/7 : 0)
            : restData.dailyRate,
    };
    CommonUtil.EQ_DETAILS_COSTESTIMATE = resultObj;
    console.log(resultObj);
  }
  static getCostEstimate() {
    return CommonUtil.EQ_DETAILS_COSTESTIMATE;
  }
  static registerProfileStatusCallback = (callback:Function)=>{
    CommonUtil.profilecompleteCallback = callback;
  }
  static setProfileStatus = (status:string)=>{
    CommonUtil.PROFILE_STATUS = status;
    if(CommonUtil.profilecompleteCallback && status === "done"){
      CommonUtil.profilecompleteCallback();
    }
  }
}



export const getBambooraCode = (usCode: string, token: string) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", usCode); //"Passcode MzYxNDIwMDAzOkM4ODYzQTgzRkM1MTQzRDc4QjZGNTg1NkE0QzJERDAz"
  myHeaders.append("Content-Type", "application/json");
  //myHeaders.append("Cookie", "TS013f0544=01c54dc23504302fe4e2b829c90ceda9ebe9148968d09fce2b3096b5b294171fee41c2db7746c9205bb94faf0c1f99377c922f5afd; TS013f0544028=018238f46cad86f2d1f03fc9bda5e35563fd5ad37cf1340ed2d8530e304513a1f7c0bb111ea49eacb576aa007cb220dcbc1c74d194");

  var usRestObj: any = {};
  usRestObj.token = {};
  usRestObj.token.name = CreditCardDetails.getCardHoderName();
  usRestObj.token.code = token; //res.token;

  var raw = JSON.stringify(usRestObj);
  var requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://api.na.bambora.com/v1/profiles", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error, "error");
      // BusyLoader.hideLoader()
      return error;
    });
};

export const timeZoneValues = [
  { name: "MST", value: "America/Denver" },
  { name: "CST", value: "America/Chicago" },
  { name: "EST", value: "America/New_York" },
  { name: "PST", value: "America/Los_Angeles" },
];

// Price value -0 check

export const CheckPriceValue = (value: any) => {
  if (Math.abs(value) < 1e-10) {
    return 0.0;
  } else {
    return value.toFixed(2);
  }
};

export const getSelectedRangeRate = (
  Noofdays: number,
  dailyRate: number | null,
  weeklyRate: number | null,
  monthlyRate: number | null
) => {
  let resultObj: any = {
    Noofdays: Noofdays,
    NoofWeeks: CommonUtil.daysToWeeks(Noofdays),
    NoofMonths: CommonUtil.daysToMonths(Noofdays),
    type: Constants.DAY,
    rate: "",
    ratePerDay: "",
  };
  if (Noofdays >= 30) {
    if (monthlyRate != null) {
      resultObj.type = Constants.MONTH;
      resultObj.rate = monthlyRate;
      resultObj.ratePerDay = monthlyRate / 30;
    } else if (weeklyRate != null) {
      resultObj.type = Constants.WEEK;
      resultObj.rate = weeklyRate;
      resultObj.ratePerDay = weeklyRate / 7;
    } else {
      resultObj.type = Constants.DAY;
      resultObj.rate = dailyRate;
      resultObj.ratePerDay = dailyRate;
    }
    return resultObj;
  } else if (Noofdays >= 7) {
    if (weeklyRate != null) {
      resultObj.type = Constants.WEEK;
      resultObj.rate = weeklyRate;
      resultObj.ratePerDay = weeklyRate / 7;
    } else {
      resultObj.type = Constants.DAY;
      resultObj.rate = dailyRate;
      resultObj.ratePerDay = dailyRate;
    }
    return resultObj;
  } else {
    resultObj.type = Constants.DAY;
    resultObj.rate = dailyRate;
    resultObj.ratePerDay = dailyRate;
    return resultObj;
  }
};

export const calculatePlatformFees = ({days,noOfRepDays,excessDays,rMoveRate,pfPaidBy,rAdditionalRate}:any) => {
  let platformFeesOwner = 0;
  let platformFeesRenter = 0;
  let excessDaysPrice = 0;
  if (days > Number(noOfRepDays)) {
    //excessDays = days - Number(noOfDays);
    excessDaysPrice = excessDays * Number(rAdditionalRate);
  }

  if (pfPaidBy === "OWNER") {
    platformFeesOwner = 0;
      // Number(rMoveRate) * 0.1 + Number(rAdditionalRate) * 1.75;
      platformFeesRenter = (Number(rMoveRate) * 0.1 > 80 ? Number(rMoveRate) * 0.1 : 80) + excessDays*1.75 + excessDaysPrice * 0.1;
  } else if (pfPaidBy === "RENTER") {
    platformFeesOwner = excessDaysPrice * 0.1;
    platformFeesRenter = (Number(rMoveRate) * 0.1 > 80 ? Number(rMoveRate) * 0.1 : 80) + excessDays*1.75;
    // platformFeesOwner = Number(rAdditionalRate) * 1.75;
    // platformFeesRenter = (Number(rMoveRate) + excessDaysPrice) * 0.1;
  } else {
    platformFeesOwner = Number(excessDays) * 1.75;
    platformFeesRenter = (Number(rMoveRate) * 0.1 > 80 ? Number(rMoveRate) * 0.1 : 80) + excessDaysPrice * 0.1;
    // platformFeesOwner = Number(rAdditionalRate) * 1.75;
    // platformFeesRenter =
    //   (Number(rMoveRate) + Number(excessDaysPrice)) * 0.1;
  }
  return {platformFeesOwner,platformFeesRenter};
};

export const setSectionCompleted = (value: any, update:any) => {
  const onLoginError = (err: any) => {
    console.log(err);
  };
  const onSuccess = (dataObj: any) => {
    if(dataObj) {
        var restData = CommonUtil.parseData(dataObj);
        console.log(restData);
        // if(restData.bankDetails &&
        //   restData.creditCardDetails &&
        //   restData.customerDetails&&
        //   restData.financeInformation&&
        //   restData.insuranceDetails&&
        //   restData.openAccount){
            
        // }
        if(restData.isCompleted){
          CommonUtil.setProfileStatus("done");
        }
          update();
    }
  };

  var userUrl = value === "isCompleted" ? `api/organization/saveOrgProfileStatus/${CommonUtil.getSaasOrgId()}?key=${value}&value=true&isForReview=true`: `api/organization/saveOrgProfileStatus/${CommonUtil.getSaasOrgId()}?key=${value}&value=true`;
        var method = "POST";
        NetworkManager.sendJsonRequest(userUrl, value, '', method, true, onSuccess, onLoginError);

};

export const checkIsInterchangeMenuEnabled = async() => {
        let interchangeAPI: string = 'api/organization/interchangeMenuEnabled?saasOrgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        return new Promise((resolve,reject) => {
          const onLoginError = (err: any) => {
            reject(err);
          };
          const onGetInterchangeMenuEnabled = (dataObj:any)=>{
            var restData = CommonUtil.parseData(dataObj);                  
            resolve(restData?.isInterchangeMenuEnabled == "Y" ? true : false);
          }
          NetworkManager.sendJsonRequest(interchangeAPI, '', '', 'GET', true, onGetInterchangeMenuEnabled, onLoginError);
        })

        
    }

