import React, {createRef, useEffect, useLayoutEffect, useRef, useState} from 'react'
import SingleSelectComp from '../../Components/SingleSelectComp'
import {CommonUtil} from '../../utils/CommonUtil'
import {NetworkManager} from '../../Components/NetworkManager'
import Flatpickr from 'react-flatpickr'
import $ from 'jquery'
import BusyLoader from '../../Components/BusyLoader'
import {StatusCodeUtil} from '../../utils/StatusCodeUtil'
import ToastManager from '../../Components/ToastManager'
import DraggableComponent from '../../Components/DraggableComponent'
import {Modal} from 'react-bootstrap'
import Confirm from '../../Components/confirm'
import {useNavigate} from "react-router-dom";
import {DragNDrop} from "../../utils/DragDrop";
import {Events} from "../Languages/Events";
import {Constants} from "../Languages/Constants";
import DetectDevice from '../../utils/DetectDevice'
import EquipGalleryModal from "./EquipGalleryModal";
import CDTextarea from './CDTextarea'

var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};
var equiImages: any = []
var equipDamages: any = []
var equipDocs: any = []

var imagePath: string = ''
var fileType: string = ''
var attachmentList: any = []

var attchIdx: number = 0;
var equipmentType: string = 'DRY VAN';
var imageAttachmentId:string = "";
var fileAttachmentId:string = "";
var equipmentData:any = null;
var isCreateAvailability:boolean = false;

var timeDelay:number = 5000;

const docRef = createRef<HTMLInputElement>()
const damageDescRef = createRef<HTMLTextAreaElement>()

var VIN_LENGTH = 8;
let OriginalPricingData:any = {};
let OriginalYear:any = "";

const CreateEquipment = () => {
    const { isMobile } = DetectDevice();
    const fileref = useRef<HTMLInputElement>(null);
    const damageFileref = useRef<HTMLInputElement>(null)
    let filedocref = useRef<HTMLInputElement>(null);
    const [disLength] = useState(1000);

    const activateRef = useRef<HTMLTextAreaElement>(null);
    const deactivateRef = useRef<HTMLTextAreaElement>(null);

    const unitModalRef = useRef<HTMLInputElement>(null);
    const [unitModel, setUnitModel] = useState('');

    const yearRef = useRef<HTMLInputElement>(null);
    const [year, setYear] = useState('');
    const [estimatedValue, setEstimatedValue] = useState('');

    const unitYearRef = useRef<HTMLInputElement>(null);
    const [unitYear, setUnitYear] = useState('');

    const [fileNmae, setFileName] = useState('');

    const [docuName, setDocuName] = useState<any>('')
    const [damageDesc, setDamageDesc] = useState<any>('')

    const [assetTypes, setAssetTypes] = useState(CommonUtil.ASSET_TYPES);
    const [selAssetObj, setSelAssetObj] = useState<any>(CommonUtil.DEFAULT_EQUIP);

    const [equipType,setEquipType] = useState<string>("DRY VAN");

    const unitNumRef = useRef<HTMLInputElement>(null)
    const [unitNumber, setUnitNUmber] = useState('')

    const vinRef = useRef<HTMLInputElement>(null)
    const [vinNumber, seVINNumber] = useState('')

    const [brands, setBrands] = useState<any>([]);
    const [selBrandObj, setSelBransObj] = useState<any>(null)

    const [axisSpeeds, setAxisSpeeds] = useState(CommonUtil.EQUIP_AXEL_SPREAD)
    const [selAxisSpeedObj, setSelAxisSpeedObj] = useState<any>(null)

    const [tieInflations, setTireInflations] = useState(CommonUtil.TIRE_INFLATION_SYSTEM)
    const [selTireInflationObj, setSelTireInflationObj] = useState<any>(null)
    const [selSkirtsObj, setSelSkirtsObj] = useState<any>(null)
    const [selPrintleHookObj, setSelPrintleHookObj] = useState<any>(null)
    const [selPlacardHolderObj, setSelPlacardHolderObj] = useState<any>(null);

    const [floorType, setFloorTypes] = useState(CommonUtil.FLOOR_TYPE);
    const [selFloorType, setSelFloorTypeObj] = useState<any>(null);

    const [selLiftObj, setSelLiftObj] = useState<any>(null);
    const [liftClass, setLiftClass] = useState('mandatory');

    const [doubleDeckBeamObj, setDoubleDeckBeamObj] = useState<any>(null);
    const [doubleClass, setDoubleClass] = useState('mandatory');

    const [suspensionTypes, setSuspensionTypes] = useState(CommonUtil.SUSPENSION_TYPE,)
    const [selSuspensionObj, setSelSuspensionObj] = useState<any>(null)

    const [rearDoors, setRearDoors] = useState(CommonUtil.REAR_DOOR)
    const [selRearDoorObj, setSelRearDoorObj] = useState<any>(null);
    const [rearDoorClass, setRearDoorClass] = useState('mandatory');

    const [sideDoors, setSideDoors] = useState(CommonUtil.SIDE_DOOR)
    const [selSideDoorObj, setSelSideDoorObj] = useState<any>(null);
    const [sideDoorClass, setSideDoorClass] = useState('mandatory');

    const [roofs, setRoofs] = useState(CommonUtil.ROOF_DOOR);
    const [selRoofObj, setSelRoofObj] = useState<any>(null);
    const [roofClass, setRoofClass] = useState('mandatory');

    const [equipFloors, setEquipFloors] = useState(CommonUtil.FLOOR_EQUIP);
    const [selFloorObj, setSelFloorObj] = useState<any>(null);
    const [floorClass, setFloorClass] = useState('mandatory');

    const [interiorEquips, setInteriorEquips] = useState(CommonUtil.INTERIOR_FINISH_EQUIP,)
    const [selInteriorObj, setSelInteriorObj] = useState<any>(null);
    const [interiorClass, setInteriorClass] = useState('mandatory');

    const [logisticsEquips, setLogisticEquips] = useState(CommonUtil.LOGISTIC_TYPES_EQUIP,)
    const [selLogisticObj, setSelLogisticObj] = useState<any>(null);
    const [logisticClass, setLogisticClass] = useState('mandatory');

    const equiNameRef = useRef<HTMLInputElement>(null)
    const [equipName, setEquipName] = useState('')

    const [modals, setModals] = useState(CommonUtil.EQUiP_MODELS)
    const [selModalObj, setSetModalObj] = useState<any>(null)

    const modelRef = useRef<HTMLInputElement>(null)
    const [model, setModel] = useState('')

    const [years, setYears] = useState(CommonUtil.EQUIP_YEARS)
    const [selYearObj, setSelYearObj] = useState<any>(null)

    const [axis, setAxis] = useState(CommonUtil.EQUIP_AXIS);
    const [axisClass, setAxisClass] = useState('mandatory');
    const [selAxisObj, setSelAxisObj] = useState<any>(null);

    const [trackTypes, setTrackTypes] = useState(CommonUtil.TRACKING_TYPES)
    const [selTrackObj, setSelTrackObj] = useState<any>({Key: 'None', Value: 'None'});
    const [trackClass, setTrackClass] = useState('');//mandatory

    const [liftTypes, setLiftTypes] = useState(CommonUtil.LIFT_TYPES)
    const [selLiftTYpeObj, setSetLiftTypeObj] = useState<any>(null);
    const [liftTypeClass, setLiftTypeClass] = useState('mandatory');

    const [liftCapacities, setLiftCapacities] = useState(CommonUtil.LIFT_CAPACITY)
    const [selLiftCapObj, setSetLiftCapObj] = useState<any>(null);
    const [liftCapClass, setLiftCapClass] = useState('mandatory');

    const [unitMakes, setUnitMakes] = useState(CommonUtil.EQUIP_UNIT_MAKES)
    const [selUnitMakeObj, setSelUnitMakeObj] = useState<any>(null);
    const [unitMakeClass, setUnitMakeClass] = useState('mandatory');

    const [unitModels, setUnitModels] = useState(CommonUtil.EQUIP_UNIT_MODELS)
    const [selUnitModelObj, setSelUnitModelObj] = useState<any>(null);
    const [unitModeClass, setUnitModeClass] = useState('mandatory');

    const [unitYears, setUnitYears] = useState(CommonUtil.EQUIP_UNIT_YEAR)
    const [selUnitYearObj, setSelUnitYearObj] = useState<any>(null);
    const [unitYearClass, setUnitYearClass] = useState('mandatory');

    const [unitTemps, setUnitTemps] = useState(CommonUtil.EQUIP_MULTI_TEMP);
    const [selUnitTempObj, setSelUnitTempObj] = useState<any>(CommonUtil.EQUIP_MULTI_TEMP_DEF);
    const [unitTempClass, setUnitTempClass] = useState('mandatory');

    const tailerLenRef = useRef<HTMLInputElement>(null);
    const [tailerLength, seTrailerLength] = useState('');

    const licenseRef = useRef<HTMLInputElement>(null);
    const [licenseNum, setLicenseNum] = useState('');

    const dailyRateRef = useRef<HTMLInputElement>(null)
    const monthlyRateRef = useRef<HTMLInputElement>(null)
    const weeklyRateRef = useRef<HTMLInputElement>(null)
    const [dailyRate, setDailyRate] = useState('')
    const [monthlyRate, setMonthlyRate] = useState('')
    const [weeklyRate, setWeeklyRate] = useState('')

    const distanceRef = useRef<HTMLInputElement>(null)
    const [distanceRate, setDistanceRate] = useState('')

    const hourRef = useRef<HTMLInputElement>(null)
    const [hourRate, setHourlyRate] = useState('')

    const [insActivation, setInsActivation] = useState('')
    const [insDeActivation, setInsDeActivation] = useState('')
    const [emails, setEmails] = useState('')
    const [amount, setAmount] = useState('')

    const modalRef = useRef<HTMLInputElement>(null)
    const [modal, setModal] = useState('')

    const dateRef = useRef<Flatpickr>(null)
    const [expDate, setExpDate] = useState('')
    const [dateOptions, setDateOptions] = useState(dateformat)

    const [equipImagesPath, setEquipImagesPath] = useState<any>([])

    const chkFoodGrade = useRef<HTMLInputElement>(null)
    const [page, setPage] = useState(0)
    const [showDocPopup, setShowDocPopup] = useState(false)

    const prvRef = useRef<HTMLInputElement>(null)
    const [chkPrivate, setChkPrivate] = useState(true)

    const renterRef = useRef<HTMLInputElement>(null)
    const [chkRenters, setChkRenters] = useState(false)

    const publicRef = useRef<HTMLInputElement>(null)
    const [chkPublic, setChkPublic] = useState(false)

    const [assetTypeClass, setAssetTypeClass] = useState(CommonUtil.DROPDOWN_VALID_CLASS);
    const [brandClass, setBrandClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [yearClass, setYearClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [axelClass, setAxelClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [modalClass, setModalClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [startDateClass,setStartDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);

    const [vinClass,setVinClass] = useState(CommonUtil.TEXT_FIELD_MANDATORY_CLASS);

    const [isEquipNameVisible, setEquipNameVisible] = useState(true)
    const [isUnitNumVisible, setUnitNumVisible] = useState(true)
    const [isVinVisible, setVinVisible] = useState(true);
    const [docAvail, setDocAvail] = useState<boolean>(false);
    const [damageAvail, setDamageAvail] = useState<boolean>(false);
    const [showDamagePopup,setShowDamagePopup] = useState(false);

    const equipDivRef = useRef(null);
    const damagesDivRef = useRef(null);
    const documentsUploadDivRef = useRef(null);

    const dropFileObj = React.useRef<any>(null);

    const [mandatoryText,setMandatoryText] = useState("");
    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imageEquipPath,setEquipImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");

    const [imageModalShow,setImageMoalShow] = useState(false);
    const [imageDmagePath, setImageDamagePath] = useState<any>(null);



    const navigate = useNavigate();

    useEffect(()=>{
        if(year.length == 4){
            if(year != OriginalYear)
                updatePricingValues()
            else{
                setDailyRate(OriginalPricingData?.dailyRate || '')
                setWeeklyRate(OriginalPricingData?.weeklyRate || '')
                setMonthlyRate(OriginalPricingData?.monthlyRate || '')
                setDistanceRate(OriginalPricingData?.distanceRate || '')
                setHourlyRate(OriginalPricingData?.engineRate || '')
            }
        }
    },[year]);

    let onAssetTypeChange = (data: any) => {
        let obj: any = {}
        if (data) {
            obj = data
        } else {
            obj = {key: CommonUtil.ASSET_TYPE_TRAILER, value: CommonUtil.ASSET_TYPE_TRAILER};
        }
        //assetTypeClass
        (data) ? setAssetTypeClass('select-box-valid') : setAssetTypeClass('select-box-invalid');

        if (obj.value == CommonUtil.ASSET_TYPE_TRAILER) {
            equipmentType = "DRV VAN";
            onClickEquipTypeImage('divDryVan', 'DRV VAN')
        } else {
            equipmentType = "SPOTTER";
            onClickEquipTypeImage('divSpotter', 'SPOTTER')
        }
        setEquipType(equipmentType);        

        CommonUtil.EQUIP_ASSET_TYPE = obj.value


        setSelBransObj(null);
        //setSetModalObj(null);
        setSelAssetObj(obj);
        loadEquipmentTypes(obj);
    }

    let loadEquipmentTypes = (dataObj: any) => {
        if(dataObj.value == CommonUtil.ASSET_TYPE_TRAILER){
            setBrands(CommonUtil.TRAILER_BRAND);
        }else{
            setBrands(CommonUtil.TRUCK_BRAND);
        }
        CommonUtil.getBrands(dataObj.key, (data: any) => {
            if(CommonUtil.SELECT_EQUIP_ID){

            }

        })
        /*if (data) {
            data.Key == CommonUtil.ASSET_TYPE_TRAILER ? setBrands(CommonUtil.TRAILER_BRAND) : setBrands(CommonUtil.TRUCK_BRAND)
        }*/
    }

    let onModalChange = (dataObj: any) => {
        setSetModalObj(dataObj)
    }
    let onBrandChange = (data: any) => {
        setSelBransObj(data)
        if(!CommonUtil.IS_GENERIC_EQUIP){
            (data) ? setBrandClass('select-box-valid') : setBrandClass('select-box-invalid');
        }
    }

    let onYearChange = (data: any) => {
        setSelYearObj(data)
    }

    let onAxisChange = (data: any) => {
        setSelAxisObj(data);
        //CommonUtil.IS_GENERIC_EQUIP?"":axisClass
        if(!CommonUtil.IS_GENERIC_EQUIP){
           // (data) ? setAxisClass('select-box-valid') : setAxisClass('select-box-invalid');
        }

    }
    let onAxisSpeedChange = (dataObj: any) => {
        setSelAxisSpeedObj(dataObj)
    }

    let onTireInflationChange = (data: any) => {
        setSelTireInflationObj(data)
    }

    let onSkirtsChange = (data: any) => {
        setSelSkirtsObj(data)
    }

    let onPrintelHookChange = (data: any) => {
        setSelPrintleHookObj(data)
    }

    let onHazmatPlacardChange = (data: any) => {
        setSelPlacardHolderObj(data)
    }

    let onSuspensionChange = (data: any) => {
        setSelSuspensionObj(data)
    }

    let onTrackTypeChange = (data: any) => {
        setSelTrackObj(data);
        (data) ? setTrackClass('select-box-valid') : setTrackClass('select-box-invalid');
    }

    let onRearDoorChange = (data: any) => {
        setSelRearDoorObj(data);
        //(data) ? setRearDoorClass('select-box-valid') : setRearDoorClass('select-box-invalid');
    }

    let onSideDoorChange = (data: any) => {
        setSelSideDoorObj(data);
       // (data) ? setSideDoorClass('select-box-valid') : setSideDoorClass('select-box-invalid');
    }

    let onRoofChange = (data: any) => {
        setSelRoofObj(data);
        //(data) ? setRoofClass('select-box-valid') : setRoofClass('select-box-invalid');
    }

    let onFloorChange = (data: any) => {
        setSelFloorObj(data);
       // (data) ? setFloorClass('select-box-valid') : setFloorClass('select-box-invalid');
    }

    let onInteriorChange = (data: any) => {
        setSelInteriorObj(data);
       // (data) ? setInteriorClass('select-box-valid') : setInteriorClass('select-box-invalid');
    }

    let onLogisticChange = (data: any) => {
        setSelLogisticObj(data);
        //(data) ? setLogisticClass('select-box-valid') : setLogisticClass('select-box-invalid');
    }

    let onDeckBeamsChange = (dataObj: any) => {
        setDoubleDeckBeamObj(dataObj);
        //(dataObj) ? setDoubleClass('select-box-valid') : setDoubleClass('select-box-invalid');
    }

    let onFloorTypeChange = (dataObj:any) =>{
        setSelFloorTypeObj(dataObj);
        //(dataObj) ? setDoubleClass('select-box-valid') : setDoubleClass('select-box-invalid');
    }

    let onLiftChange = (data: any) => {
        setSelLiftObj(data);
        (data) ? setLiftClass('select-box-valid') : setLiftClass('select-box-invalid');
        setLiftTypeClass('select-box-valid');
        setLiftCapClass('select-box-valid');

        onLiftObjectChange(data);

    }

    let onLiftObjectChange = (data:any)=>{
        if(!CommonUtil.IS_GENERIC_EQUIP){
            if(data){
                if(data.Key == "No"){
                    setLiftTypeClass('select-box-valid');
                    setLiftCapClass('select-box-valid');
                }else{
                    if(selLiftTYpeObj){
                        setLiftTypeClass('select-box-valid');
                    }else{
                        setLiftTypeClass('select-box-invalid');
                    }

                    if(selLiftCapObj){
                        setLiftCapClass('select-box-valid');
                    }else{
                        setLiftCapClass('select-box-invalid');
                    }

                }
            }
        }
    }

    let onLiftTypeChange = (data: any) => {
        setSetLiftTypeObj(data);
        (data) ? setLiftTypeClass('select-box-valid') : setLiftTypeClass('select-box-invalid');

        onLiftObjectChange(selLiftObj);

    }

    let onLiftCapacityChange = (data: any) => {
        setSetLiftCapObj(data);
        (data) ? setLiftCapClass('select-box-valid') : setLiftCapClass('select-box-invalid');

        onLiftObjectChange(selLiftObj);
    }

    let onUnitMakeChange = (dataObj: any) => {
        setSelUnitMakeObj(dataObj);
        (dataObj) ? setUnitMakeClass('select-box-valid') : setUnitMakeClass('select-box-invalid');
    }

    let onUnitModelChange = (dataObj: any) => {
        setSelUnitModelObj(dataObj);
        (dataObj) ? setUnitModeClass('select-box-valid') : setUnitModeClass('select-box-invalid');
    }

    let onUnitYearChange = (dataObj: any) => {
        setSelUnitYearObj(dataObj);
        (dataObj) ? setUnitYearClass('select-box-valid') : setUnitYearClass('select-box-invalid');
    }

    let onUnitTempChange = (dataObj: any) => {
        setSelUnitTempObj(dataObj);
        if(!CommonUtil.IS_GENERIC_EQUIP){
            (dataObj) ? setUnitTempClass('select-box-valid') : setUnitTempClass('select-box-invalid');
        }

    }

    let onChangeeDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setExpDate(strDate)
    }

    let showMandatoryMessage = ()=>{
        setMandatoryText("Please fill in all the mandatory fields");
        setTimeout(()=>{
            setMandatoryText("");
        },timeDelay);
    }

    let onClickCreteEquip = () => {
        let isEquip: boolean = CommonUtil.isMandatory(equiNameRef);
        let isUnitNum: boolean = CommonUtil.isMandatory(unitNumRef);
        let isTrailerLen: boolean = CommonUtil.isMandatory(tailerLenRef);

        selAssetObj ? setAssetTypeClass('select-box-valid') : setAssetTypeClass('select-box-invalid')
        let flag:boolean = false;
        if (selAssetObj &&  isTrailerLen ) {//selBrandObj
            if (CommonUtil.IS_GENERIC_EQUIP) {
                if(isEquip){
                    flag = true;
                }else{
                    showMandatoryMessage();
                }
            }else{
                (selBrandObj) ? setBrandClass('select-box-valid') : setBrandClass('select-box-invalid');
                (selTrackObj) ? setTrackClass('select-box-valid') : setTrackClass('select-box-invalid');
                let isYear: boolean = CommonUtil.isMandatory(yearRef);
                let isLicenseNum: boolean = CommonUtil.isMandatory(licenseRef);
                if(isUnitNum && vinNumber && selBrandObj  && isYear &&  isLicenseNum && selTrackObj){//selLiftObj && selFloorObj && selInteriorObj &&  && selRearDoorObj
                    if(selLiftObj && selLiftObj.Key == "Yes"){
                        flag = true;
                    }else{
                        flag = true;
                    }

                    if(vinNumber.length>=VIN_LENGTH){
                        setVinClass(CommonUtil.TEXT_FIELD_VALID_CLASS);
                    }else{
                        flag = false;
                        setVinClass(CommonUtil.TEXT_FIELD_MANDATORY_CLASS);
                        ToastManager.showToast(CommonUtil.getMessageText(Constants.VIN_LENGTH_12,'The Vin number must be 8 characters minimum'), 'Error')
                    }

                }else{
                    showMandatoryMessage();
                }
            }
            if(flag){
                let restObj: any = {};
                restObj.VIN = (vinNumber?vinNumber:null);
                restObj.unitNumber = (unitNumber?unitNumber:null);
                restObj.genericEquipmentName = equipName;
                restObj.activationInstructions = insActivation;
                restObj.additionalEmailIds = emails;
                restObj.annualMechanicalInspExpDate = expDate;
                restObj.assetType = selAssetObj ? selAssetObj.value : '';
                restObj.brand = selBrandObj ? selBrandObj.Value : null;
                restObj.deactivationInstructions = insDeActivation;
                restObj.equipmentType = equipmentType;
                restObj.estimatedValue = estimatedValue;
                restObj.trackingType = selTrackObj ? selTrackObj.Value : '';

                restObj.isGeneric = CommonUtil.IS_GENERIC_EQUIP ? 'Y' : 'N';
                restObj.lengthOfTrailer = tailerLength;
                restObj.licensePlateNumber = licenseNum;
                restObj.model = model;// selModalObj ? selModalObj.value : null;
                restObj.numberOfAxles = selAxisObj ? selAxisObj.Key : '';
                restObj.securityDeposit = amount;

                restObj.year = year;//selYearObj ? selYearObj.Key : '';
                restObj.isGeneric = CommonUtil.IS_GENERIC_EQUIP ? 'Y' : 'N';

                restObj.pricing = {};
                restObj.pricing.dailyRate = dailyRate;
                restObj.pricing.weeklyRate = weeklyRate;
                restObj.pricing.monthlyRate = monthlyRate;
                restObj.pricing.distanceRate = distanceRate;
                restObj.pricing.engineRate = hourRate;

                restObj.specifications = {};
                restObj.specifications.general = {};
                restObj.specifications.general.axleSpread = selAxisSpeedObj ? selAxisSpeedObj.Value : '';
                restObj.specifications.general.foodGrade = chkFoodGrade.current?.checked ? 'Y' : 'N';
                restObj.specifications.general.hazmatPlacardHolder = selPlacardHolderObj ? selPlacardHolderObj.Value : '';
                restObj.specifications.general.pintleHook = selPrintleHookObj ? selPrintleHookObj.Value : '';
                restObj.specifications.general.skirts = selSkirtsObj ? selSkirtsObj.Value : '';
                restObj.specifications.general.suspensionType = selSuspensionObj ? selSuspensionObj.Value : '';
                restObj.specifications.general.tireInflationSystem = selTireInflationObj ? selTireInflationObj.Value : '';

                restObj.specifications.lift = {};
                restObj.specifications.lift.lift = selLiftObj ? selLiftObj.Value : ''
                restObj.specifications.lift.liftCapacity = selLiftCapObj ? selLiftCapObj.Value : ''
                restObj.specifications.lift.liftType = selLiftTYpeObj ? selLiftTYpeObj.Value : ''

                restObj.specifications.reefer = {};
                restObj.specifications.reefer.multiTemperature = selUnitTempObj ? selUnitTempObj.Value : ''
                restObj.specifications.reefer.unitMake = selUnitMakeObj ? selUnitMakeObj.Value : ''
                restObj.specifications.reefer.unitModel = unitModel;
                restObj.specifications.reefer.unitYear = unitYear;

                restObj.specifications.trailer = {};
                restObj.specifications.trailer.doubleDeckBeams = doubleDeckBeamObj ? doubleDeckBeamObj.Value : '';
                restObj.specifications.trailer.floor = selFloorObj ? selFloorObj.Value : '';
                restObj.specifications.trailer.interiorFinish = selInteriorObj ? selInteriorObj.Value : '';
                restObj.specifications.trailer.logisticPostsDistance = selLogisticObj ? selLogisticObj.Value : '';
                restObj.specifications.trailer.rearDoor = selRearDoorObj ? selRearDoorObj.Value : '';
                restObj.specifications.trailer.roof = selRoofObj ? selRoofObj.Value : '';
                restObj.specifications.trailer.sideDoor = selSideDoorObj ? selSideDoorObj.Value : '';
                restObj.specifications.trailer.floorType = selFloorType ? selFloorType.Value : '';

                let equipUrl: string = '';
                let method: string = 'POST';
                if (CommonUtil.SELECT_EQUIP_ID == '') {
                    equipUrl = 'api/equipment/?saasOrgId=' + CommonUtil.getSaasOrgId();
                } else {
                    method = 'PUT';
                    equipUrl = 'api/equipment/' + CommonUtil.SELECT_EQUIP_ID;
                }

                BusyLoader.showLoader();
                NetworkManager.sendJsonRequest(equipUrl, restObj, '', method, false, onEquipCreation, onLoginError);
            }else{
                showMandatoryMessage();
            }

        }else{
            showMandatoryMessage();
        }
    }

    let onEquipCreation = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
               // navigate(-1);
                enableEquipImages();
                let strmessage:string = "Equipment Created Successfully";
                if(CommonUtil.SELECT_EQUIP_ID){
                    BusyLoader.hideLoader();
                    strmessage = "Equipment Updated Successfully";
                    //navigate(-1);
                    navigateToParentScreen();
                }else{
                    CommonUtil.SELECT_EQUIP_ID = restData.equipmentId;
                    CommonUtil.SELECT_EQUIP_NAME = restData.unitNumber?restData.unitNumber:restData.genericEquipmentName;
                    uploadAllEquipPhotos();
                }
                ToastManager.showToast(strmessage, 'Info')

            }else{
                BusyLoader.hideLoader();
            }
        }
    }

    let uploadAllEquipPhotos = ()=>{
        let arr: any = equiImages;
        arr.forEach((item:any,idx:number)=>{
            item.entityId = CommonUtil.SELECT_EQUIP_ID;
        });


        if(CommonUtil.SELECT_EQUIP_ID){
            let attachmentUrl: string = 'api/equipment/attachments?saasOrgId=' + CommonUtil.getSelectedOrgId();//localStorage.getItem('saasorgId');
            NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', false, (dataObj:any)=>{
                BusyLoader.hideLoader();
               // navigate(-1);
                navigateToParentScreen();
            }, onLoginError);
        }else{
            BusyLoader.hideLoader();
        }
    }

    let onClickDiscardChanges = ()=>{
        //navigate(-1);
        navigateToParentScreen();
    }

    let navigateToParentScreen = () => {
        if(isCreateAvailability){
            Events.dispatchSaveEquipment();
        }else{
            Events.dispatchShowAvailabilityButton();
        }
        if(CommonUtil.SCREEN_TYPE == CommonUtil.RESERVATION_SCREEN_TYPE){
            localStorage.setItem("reservation","true");
        }

        navigate(-1);
    }

    const onClickDocBrowse = (fType: string) => {
            fileType = fType;
                $('#inputDOC').click()
            setFileName('')
    }

    const onClickDamagBrowse = (fType: string) => {
        fileType = fType;
        if (damageFileref.current) {
            damageFileref.current.value = ''
            // fileref.current.onclick();
            $('#inputDamages').click();
        }

        setFileName('')
    }

    const onSelectDocFiles = (e: any) => {
        if (e && e.target && e.target.files[0]) {
            setDocAvail(true)
            dropFileObj.current = e.target.files[0];
            setTimeout(()=>{
                e.target.value = '';
            }, 1000)
        }
    }

    let onSelectDamageFiles = (e:any)=>{
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name;
            setFileName(fileName);
            setDamageAvail(true)
            var oFReader = new FileReader()
            oFReader.readAsDataURL(e.target.files[0])
            oFReader.onload = function (oFREvent) {
                // console.log(oFREvent.target?.result);
               // setAddLogo(false)
               // setCompanyLogo(true)
                setImageDamagePath(oFREvent.target?.result)
                //setFileName(fileName)
            }
            dropFileObj.current = e.target.files[0];
            setTimeout(()=>{
                e.target.value = '';
            }, 1000)
        }
    }
    const saveUploadDocs = () => {
        if(!docuName.trim()) return ToastManager.showToast('Please enter document name.', "Error");
        if(dropFileObj.current === null || dropFileObj.current === '') return  ToastManager.showToast('Please select document.', "Error");;
        if(dropFileObj.current){
        let fileName = dropFileObj.current.name
            setFileName(fileName)
            BusyLoader.showLoader();
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.EQUIP_DOCS) {
                imagePath = 'documents/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            } else {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            }

            //equiImages.push(imagePath);
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }

    let saveUploadDamages = ()=>{
        if(dropFileObj.current){
            //let fileName = damageFileref
            //setFileName(fileName);
            BusyLoader.showLoader();
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.EQUIP_DAMAGES) {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileNmae + '-' + new Date().getTime()
            }

            //equiImages.push(imagePath);
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }
    let onClickBrowse = (fType: string) => {
        fileType = fType
        if (fileref.current) {
            fileref.current.value = ''
            // fileref.current.onclick();
            $('#inputGroupFile').click()
        }
        setFileName('')
    }

    let onSelectFiles = (e: any) => {
       // console.log(e)
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name
            setFileName(fileName)
            BusyLoader.showLoader()
            // var qParam:string = "objectPath=documents/organizations/"+CommonUtil.getSelectedOrgId()+"/"+fileName;
            if (fileType == CommonUtil.EQUIP_DOCS) {
                onCloseDocPopup()
                imagePath = 'documents/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            } else {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            }

            //equiImages.push(imagePath);
            var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
            NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
        }
    }
    const onUploadImage = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader()
                var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
                ToastManager.showToast(errMsg, 'Error')
            } else {
                var strUploadPath = dataObj;
                if (fileType == CommonUtil.EQUIP_DOCS) {
                        const formData = new FormData();
                        var myHeaders = new Headers();
                        var file:File = dropFileObj.current.files;
                        var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                        fetch(strUploadPath, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                //BusyLoader.hideLoader();
                                uploadEquipImages();
                            })
                            .catch((error) => {
                                //  BusyLoader.hideLoader();
                                console.log('error', error)
                            })
                }else if(fileType == CommonUtil.EQUIP_DAMAGES &&  damageFileref.current && damageFileref.current.files){
                    const formData = new FormData();
                    var myHeaders = new Headers();
                    var file:File = dropFileObj.current;
                    var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                    fetch(strUploadPath, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            //BusyLoader.hideLoader();
                            uploadEquipImages();
                        })
                        .catch((error) => {
                            //  BusyLoader.hideLoader();
                            console.log('error', error)
                        })
                }else if (fileType == CommonUtil.EQUIP_IMAGES && fileref.current && fileref.current.files) {
                        const formData = new FormData();
                        var myHeaders = new Headers();
                        var file = fileref.current.files[0];
                        var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
                        fetch(strUploadPath, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                //BusyLoader.hideLoader();
                                uploadEquipImages();
                            })
                            .catch((error) => {
                                //  BusyLoader.hideLoader();
                                console.log('error', error)
                            })
                    }
                }

            }
        }

    let uploadEquipImages = () => {
        if ((fileref.current && fileref.current.files && fileref.current.files[0]) || dropFileObj.current || (damageFileref.current && damageFileref.current.files && damageFileref.current.files[0])) {
            let reqObj: any = {}
            let obj: any = {}
            if (fileType == CommonUtil.EQUIP_DOCS) {
                obj.documentName = docuName;
            }else if(fileType == CommonUtil.EQUIP_DAMAGES) {
                if(damageFileref.current && damageFileref.current.files){
                    obj.documentName = fileNmae;//damageFileref.current.files[0].name;
                    onCloseDamagePopup();
                }
            }else{

                if(fileref.current && fileref.current.files){
                    obj.documentName = fileref.current.files[0].name;
                }
            }
            obj.documentType = fileType;
            obj.Type = fileType;
            obj.fullFilePath = imagePath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();//localStorage.getItem('saasorgId');
            if (fileType == CommonUtil.EQUIP_IMAGES || fileType == CommonUtil.EQUIP_DAMAGES) {
                obj.visibility = CommonUtil.PUBLIC_EQUIP;
            } else {
                obj.visibility = chkPrivate ? 'private' : chkPublic ? 'public' : 'renter'
            }

            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            obj.attachmentDescription = damageDesc;
            reqObj.attachments = [obj];
            let arr: any = [];
            arr.push(obj);

            if(CommonUtil.SELECT_EQUIP_ID){
                let attachmentUrl: string = 'api/equipment/attachments?saasOrgId=' + CommonUtil.getSelectedOrgId();//localStorage.getItem('saasorgId');
                NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', false, onUpdateEquipImageSuccess, onLoginError);
            }else{
                let qParam: string = 'objectPath=' + imagePath;
                NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                        obj.documentName = docuName;
                        obj.Path = dataObj;
                        equiImages.push(obj);
                        setEquipImagesPath(equiImages);
                        onCloseDocPopup()
                        BusyLoader.hideLoader();
                        setPage(Math.random()*100000);
                    },
                    onLoginError,
                )
            }

        }
    }

    let onUpdateEquipImageSuccess = (dataObj: any) => {
        //console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                dropFileObj.current = null;
                setDocAvail(false);
                setDamageAvail(false);
                setImageDamagePath("");
                showEquipmentDetails();
            }
        }
       // imageAttachmentId = dataObj[0].attachementId;
       // let qParam: string = 'objectPath=' + imagePath;
        //NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, onDownloadSuccess, onLoginError);
    }
    let onDownloadSuccess = (dataObj: any) => {
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                showEquipmentDetails();
            }
        }
        /*let obj: any = {}
        obj.Type = fileType;
        obj.Path = data;
        obj.attachementId = imageAttachmentId;
        equiImages.push(obj)
        setTimeout(() => {
            //console.log(equiImages);
            setEquipImagesPath(equiImages);
            setPage(Math.random() * 100000);
            setFileName('');
            BusyLoader.hideLoader();
        }, 5000)*/
    }
    let onUpdateOrgLogoSuccess = (dataObj: any) => {
        BusyLoader.hideLoader()
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData.statusCode) {
                BusyLoader.hideLoader();
                var errMsg = StatusCodeUtil.getCodeMessage(restData.statusCode);
                ToastManager.showToast(errMsg, 'Error');
            } else {
                ToastManager.showToast('Logo updated successfully', 'Info');
            }
        } else {
            ToastManager.showToast('Logo updated successfully', 'Info');
        }
    }

    const handleDragEnter = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragLeave = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragOver = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.EQUIP_IMAGES;//'GST506';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            setDocAvail(true)
            onSelectDrop(files[0]);
        }
    }

    const handleDropDamages = (e:any)=>{
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.EQUIP_DAMAGES;//'GST506';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            setDocAvail(true)
            onSelectDrop(files[0]);
        }
    }
    const handleDropUploads = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        fileType = CommonUtil.EQUIP_DOCS;//documents
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            setDocAvail(true)
        }
    }

    const onSelectDrop = (e: any) => {
        //console.log(e);
        if (e && e.name) {
            let fileName = e.name;
            setFileName(fileName);
            BusyLoader.showLoader();
            if (fileType == CommonUtil.EQUIP_DOCS) {
                imagePath = 'documents/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            } else {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            }
            let qParam: string = 'objectPath=' + imagePath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccessDrop, onLoginError);
        }
    }
    let onUploadSuccessDrop = (dataObj:any)=>{
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader()
                var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
                ToastManager.showToast(errMsg, 'Error')
            } else {
                var strUploadPath = dataObj;
                    if (dropFileObj.current) {
                        const formData = new FormData();
                        var myHeaders = new Headers();
                        //var file = dropFileObj.current;
                        var requestOptions: any = {method: 'PUT', headers: myHeaders, body: dropFileObj.current, redirect: 'follow'};
                        fetch(strUploadPath, requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                //BusyLoader.hideLoader();
                                uploadEquipImagesDrop();
                            })
                            .catch((error) => {
                                //  BusyLoader.hideLoader();
                                console.log('error', error)
                            })
                    }
            }
        }
    }

    let uploadEquipImagesDrop = () => {
        if (dropFileObj.current) {
            let reqObj: any = {}
            let obj: any = {}

            if (fileType == CommonUtil.EQUIP_DOCS) {
                obj.documentName = docRef.current?.value;
            }else{
                obj.documentName = dropFileObj.current.name;
            }
            obj.documentType = fileType;
            obj.Type = fileType;
            obj.fullFilePath = imagePath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();//localStorage.getItem('saasorgId');
            if (fileType == CommonUtil.EQUIP_IMAGES || fileType == CommonUtil.EQUIP_DAMAGES) {
                obj.visibility = CommonUtil.PUBLIC_EQUIP;
            } else {
                obj.visibility = chkPrivate ? 'private' : chkPublic ? 'public' : 'renter'
            }

            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            reqObj.attachments = [obj];
            let arr: any = [];
            arr.push(obj);

            if(CommonUtil.SELECT_EQUIP_ID){
                let attachmentUrl: string = 'api/equipment/attachments?saasOrgId=' + CommonUtil.getSelectedOrgId();//localStorage.getItem('saasorgId');
                NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', false, onUpdateEquipImageSuccess, onLoginError);
            }else{
                let qParam: string = 'objectPath=' + imagePath;
                NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                        obj.Path = dataObj;
                        equiImages.push(obj);
                        setEquipImagesPath(equiImages);
                        BusyLoader.hideLoader();
                        setPage(Math.random()*100000);
                    },
                    onLoginError,
                )
            }

        }
    }

    const handleInputHourlyRate = (e:any) => {
        CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setDailyRate})
    }
    const handleInputWeeklyRate = (e:any) => {
        CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setWeeklyRate})
    }
    const handleInputMonthlyRate = (e:any) => {
        CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setMonthlyRate})
        }
    const handleInputDistanceRate = (e:any) => {
        CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setDistanceRate})
        }
    const handleInputEngineRate = (e:any) => {
        CommonUtil.handleNumbersDecimalInput({event:e,ref:null,setState:setHourlyRate})
        }

    useEffect(() => {
        Events.dispatchHideAvailabilityButton();

        DragNDrop({drop:equipDivRef, handleDragOver, handleDrop, handleDragEnter, handleDragLeave})
        DragNDrop({drop:damagesDivRef, handleDragOver, handleDrop:handleDropDamages, handleDragEnter, handleDragLeave})

        setModals(CommonUtil.EQUiP_MODELS);
        CommonUtil.EQUIP_ASSET_TYPE = CommonUtil.ASSET_TYPE_TRAILER;

       // alert(localStorage.getItem("IsGeneric"));
        isCreateAvailability = false;
        setAssetTypes(CommonUtil.ASSET_TYPES);
        // updatePricingValues(equipmentType);
        if(localStorage.getItem("IsGeneric")){
            if(localStorage.getItem("IsGeneric") == "true"){
                CommonUtil.IS_GENERIC_EQUIP = true;
            }else {
                CommonUtil.IS_GENERIC_EQUIP = false;
            }
            isCreateAvailability = true;
            //CommonUtil.IS_GENERIC_EQUIP = Boolean(localStorage.getItem("IsGeneric"));
            localStorage.removeItem("IsGeneric");
        }
        if (CommonUtil.IS_GENERIC_EQUIP == true) {
            setUnitNumVisible(false)
            setVinVisible(false)
            setEquipNameVisible(true)
        } else {
            setUnitNumVisible(true)
            setVinVisible(true)
            setEquipNameVisible(false)
        }
        if(CommonUtil.SELECT_EQUIP_ID == ""){
            loadEquipmentTypes(CommonUtil.DEFAULT_EQUIP);
        }



        if(CommonUtil.IS_GENERIC_EQUIP){
            $("#divExpDate").hide();
            $("#divTrackType").hide();
            $("#divEmails").hide();
            $("#divEmailBox").hide();
        }else{
            $("#divEmails").show();
            $("#divExpDate").show();
            $("#divTrackType").show();
            $("#divEmailBox").show();
        }

        showEquipmentDetails();


        document.addEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        return () => {
            document.removeEventListener(Events.LANGUAGE_CHANGE_EVENT, onLanguageChange);
        }

    }, [])

    let onLanguageChange = ()=>{
        setPage(Math.random()*100000);
    }

    let showEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        BusyLoader.showLoader()
        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = 'api/equipment/' + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {}
            NetworkManager.sendJsonRequest(equipmentAPI, '', '', 'GET', false, onGetEquipmentDetails, onLoginError);
        }else{
            BusyLoader.hideLoader();
        }
    }
    let onGetEquipmentDetails = (dataObj: any) => {
        //console.log(dataObj)
        enableEquipImages();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                equipmentData = restData;
                    if (restData.isGeneric == 'Y') {
                        CommonUtil.IS_GENERIC_EQUIP = true
                    } else {
                        CommonUtil.IS_GENERIC_EQUIP = false
                    }

                    equipmentType  = restData.equipmentType;
                    CommonUtil.EQUIP_ASSET_TYPE = restData.assetType;

                    let aObj:any = {};
                    if(restData.assetType == CommonUtil.ASSET_TYPE_TRAILER){//"Trailer"
                        aObj.key = "1";
                        aObj.value = CommonUtil.ASSET_TYPE_TRAILER;
                    }else{
                        aObj.key = "2";
                        aObj.value = CommonUtil.ASSET_TYPE_TRUCK;//"Truck";
                    }

                    setEquipType(equipmentType);
                    // updatePricingValues(equipmentType);
                    setSelAssetObj(aObj);
                    loadEquipmentTypes(aObj);

                    
                       // let onClickEquipTypeImage = (divImageId: string, equipType: string) => {
                    //}

                    setTimeout(()=>{
                        if(restData.genericEquipmentName){
                            CommonUtil.isMandatory(equiNameRef);
                        }
                        if(restData.VIN && restData.VIN.length>=VIN_LENGTH){
                           setVinClass(CommonUtil.TEXT_FIELD_VALID_CLASS);
                        }
                        if(restData.unitNumber){
                            CommonUtil.isMandatory(unitNumRef);
                        }
                        if(restData.lengthOfTrailer){
                            CommonUtil.isMandatory(tailerLenRef);
                        }
                        if(restData.year){
                            CommonUtil.isMandatory(yearRef);
                        }
                        if(restData.licensePlateNumber){
                            CommonUtil.isMandatory(licenseRef);
                        }
                        (restData.brand) ? setBrandClass('select-box-valid') : setBrandClass('select-box-invalid');
                        (restData.numberOfAxles)?setAxisClass('select-box-valid'):setAxisClass('select-box-invalid');

                        if(restData.specifications && restData.specifications.reefer && restData.specifications.reefer.multiTemperature){
                            (restData.specifications.reefer.multiTemperature) ? setUnitTempClass('select-box-valid') : setUnitTempClass('select-box-invalid');
                        }

                    },100);

                    setEquipName(restData.genericEquipmentName);
                    setEstimatedValue(restData.estimatedValue);
                    setUnitNUmber(restData.unitNumber)
                    seVINNumber(restData.VIN)
                setDailyRate(restData?.pricing ? restData?.pricing?.dailyRate : '');
                
                setWeeklyRate(restData.pricing ? restData.pricing.weeklyRate : '')
                setMonthlyRate(restData.pricing ? restData.pricing.monthlyRate : '')
                    setDistanceRate(restData.pricing ? restData.pricing.distanceRate : '')
                    setHourlyRate(restData.pricing ? restData.pricing.engineRate : '')
                    setLicenseNum(restData.licensePlateNumber)
                    setSelAxisObj({Key: restData.numberOfAxles, Value: restData.numberOfAxles,})
                   // setSelYearObj({Key: restData.year, Value: restData.year})
                    setYear(restData.year);
                    OriginalPricingData=restData.pricing ? {...restData.pricing}:{};
                    OriginalYear = restData.year;     
                    seTrailerLength(restData.lengthOfTrailer)
                    setInsActivation(restData.activationInstructions)
                    setInsDeActivation(restData.deactivationInstructions)
                    setEmails(restData.additionalEmailIds);
                     setAmount(restData.securityDeposit);
                    setSelBransObj({Key: restData.brand, Value: restData.brand});
                     setModel(restData.model);
                     setSelTrackObj({Key:restData.trackingType,Value:restData.trackingType});
                   // setSetModalObj({key:restData.model,value:restData.model});

               // doubleDeckBeamObj
                    //setSelBransObj(restData.brand);

                    if(restData.annualMechanicalInspExpDate){
                        if(dateRef.current){
                            setExpDate(restData.annualMechanicalInspExpDate);
                            dateRef.current.flatpickr.setDate(new Date(restData.annualMechanicalInspExpDate));
                        }
                    }

                    if (restData.specifications) {
                        if (restData.specifications.general) {
                            setSelAxisSpeedObj({Key: restData.specifications.general.axleSpread, Value: restData.specifications.general.axleSpread})
                            setSelTireInflationObj({Key: restData.specifications.general.tireInflationSystem, Value: restData.specifications.general.tireInflationSystem})
                            setSelSkirtsObj({Key: restData.specifications.general.skirts, Value: restData.specifications.general.skirts})
                            setSelPrintleHookObj({Key: restData.specifications.general.pintleHook, Value: restData.specifications.general.pintleHook})
                            setSelPlacardHolderObj({Key: restData.specifications.general.hazmatPlacardHolder, Value: restData.specifications.general.hazmatPlacardHolder})
                            setSelSuspensionObj({Key: restData.specifications.general.suspensionType, Value: restData.specifications.general.suspensionType})
                            if (restData.specifications.general.foodGrade == 'Y') {
                                if (chkFoodGrade.current) {
                                    chkFoodGrade.current.checked = true
                                }
                            }
                        }
                        if (restData.specifications.trailer) {
                            setSelRearDoorObj({Key: restData.specifications.trailer.rearDoor, Value: restData.specifications.trailer.rearDoor});
                            setSelSideDoorObj({Key: restData.specifications.trailer.sideDoor, Value: restData.specifications.trailer.sideDoor});
                            setSelRoofObj({Key: restData.specifications.trailer.roof, Value: restData.specifications.trailer.roof});
                            setSelInteriorObj({Key: restData.specifications.trailer.interiorFinish, Value: restData.specifications.trailer.interiorFinish});
                            setSelFloorObj({Key: restData.specifications.trailer.floor, Value: restData.specifications.trailer.floor});
                            setSelLogisticObj({Key: restData.specifications.trailer.logisticPostsDistance, Value: restData.specifications.trailer.logisticPostsDistance});
                            setDoubleDeckBeamObj({Key:restData.specifications.trailer.doubleDeckBeams,Value:restData.specifications.trailer.doubleDeckBeams});
                            setSelFloorTypeObj({Key: restData.specifications.trailer.floorType, Value: restData.specifications.trailer.floorType});
                        }
                        if (restData.specifications.lift) {
                            setSelLiftObj({Key: restData.specifications.lift.lift, Value: restData.specifications.lift.lift});
                            setSetLiftTypeObj({Key: restData.specifications.lift.liftType, Value: restData.specifications.lift.liftType});
                            setSetLiftCapObj({Key: restData.specifications.lift.liftCapacity, Value: restData.specifications.lift.liftCapacity});
                        }

                        if (restData.specifications.reefer) {
                            setSelUnitTempObj({Key: restData.specifications.reefer.multiTemperature, Value: restData.specifications.reefer.multiTemperature});
                            setSelUnitMakeObj({Key: restData.specifications.reefer.unitMake, Value: restData.specifications.reefer.unitMake});
                            setUnitModel(restData.specifications.reefer.unitModel);
                            setUnitYear(restData.specifications.reefer.unitYear);
                            //setSelUnitModelObj({Key: restData.specifications.reefer.unitModel, Value: restData.specifications.reefer.unitModel});
                            //setSelUnitYearObj({Key: restData.specifications.reefer.unitYear, Value: restData.specifications.reefer.unitYear});
                        }
                    }

                    if (restData.attachments) {
                        attachmentList = [];
                        let tempAttachments = [];
                        if (Array.isArray(restData.attachments)) {
                            //attachmentList = restData.attachments
                            tempAttachments = restData.attachments;
                        } else {
                            tempAttachments.push(restData.attachments);
                            //attachmentList.push(restData.attachments)
                        }
                        if (tempAttachments.length > 0) {
                           // BusyLoader.showLoader()
                            tempAttachments.forEach((item:any,idx:number)=>{
                                if(item.fullFilePath && !item.reservationId){
                                    attachmentList.push(item);
                                }
                            });
                            showAttachments();
                        }else{
                            BusyLoader.hideLoader();
                        }
                    }else{
                        BusyLoader.hideLoader();
                    }
                    setTimeout(()=>{
                        try{
                            if(equipmentType != "SPOTTER"){
                                //console.log(CommonUtil.getEquipemntDiv(equipmentType));
                                onClickEquipTypeImage(CommonUtil.getEquipemntDiv(equipmentType),equipmentType);
                            }
                        }catch(e){
                            console.log(e);                            
                        }
                    })
                }
            }
        }


    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.documentName = attachmentList[attchIdx].documentName
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId;
                    obj.fullFilePath = attachmentList[attchIdx].fullFilePath;
                    obj.attachmentDescription = attachmentList[attchIdx].attachmentDescription;
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1
                    showAttachments();
                },
                onLoginError,
            )
        } else {
            setTimeout(()=>{
                BusyLoader.hideLoader();
            },4000);

            setEquipImagesPath(equiImages)
        }
    }

    /*let obj:any = {};
      obj.Type = fileType;
      obj.Path = data;
      equiImages.push(obj);
      setTimeout(()=>{
          //console.log(equiImages);
          setEquipImagesPath(equiImages);
          setPage(Math.random()*100000);
          setFileName("");
          BusyLoader.hideLoader();
      },5000);*/

    const onCloseDocPopup = () => {
        setShowDocPopup(false)
        setDocuName('');
        dropFileObj.current = null
        setDocAvail(false)
    }

    const onClickDocumentUpload = () => {
        uncheckVisibility()
        setChkPrivate(true)
        setShowDocPopup(true)
        setTimeout(()=>{
            DragNDrop({drop:documentsUploadDivRef, handleDragOver, handleDrop:handleDropUploads, handleDragEnter, handleDragLeave});
        }, 1000)
    }

    const uncheckVisibility = () => {
        setChkPrivate(false);
        setChkRenters(false);
        setChkPublic(false);
        $("#lnkPrivate").removeClass("active");
        $("#lnkRenters").removeClass("active");
        $("#lnkPublic").removeClass("active");
    }
    let onChangePrivate = (linkId:string) => {
        uncheckVisibility();
       if(linkId == "lnkPrivate"){
           setChkPrivate(true);
           $("#lnkPrivate").addClass("active");
       }else if(linkId == "lnkRenters"){
           setChkRenters(true);
            $("#lnkRenters").addClass("active");
        }else if(linkId == "lnkPublic"){
           setChkPublic(true);
            $("#lnkPublic").addClass("active");
        }
    }
    /*let onChangeRenters = (e: any) => {
        uncheckVisibility()
        setChkRenters(e.currentTarget.checked)
    }

    let onChangePublic = (e: any) => {
        uncheckVisibility()
        setChkRenters(e.currentTarget.checked)
    }*/

    let onClickRemoveImage = (attachId: string,type:string) => {
        //console.log(attachId)
        let confirmMsg = CommonUtil.getMessageText(Constants.DO_YOU_WANT_REMOVE,'Do you want remove picture?');
        if(type == CommonUtil.EQUIP_DOCS){
            confirmMsg = "Do you want remove document?";
        }
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), confirmMsg, [{label: 'Yes', callback: function () {
                    if (attachId) {
                        fileAttachmentId = attachId;
                        let removeAPI: string = 'api/equipment/attachment/' + attachId + '?saasOrgId=' + localStorage.getItem('saasorgId')
                        //console.log(removeAPI)
                        BusyLoader.showLoader();
                        NetworkManager.sendJsonRequest(removeAPI, '', '', 'DELETE', false, onDeleteEquipmentImage, onLoginError);
                    }
                },
            },
            {label: 'No'},
        ])
    }
    let onDeleteEquipmentImage = (dataObj: any) => {
        //console.log(dataObj)
        if(dataObj == "success"){
            let strRemove = CommonUtil.getMessageText(Constants.REMOVED_SUCCESS,'Removed Successfully');
            ToastManager.showToast(strRemove, 'Info');
           // removeSelectedImage(fileAttachmentId);
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.ERROR,'Error'), 'Error')
        }

        showEquipmentDetails();
    }

    let onDocDownload = (fullPath:string)=>{
        let qParam:string = "objectPath="+fullPath;
        NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadFileSuccess,onLoginError)
    }

    let onDownloadFileSuccess = (dataObj:any)=>{
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }

    let removeSelectedImage = (attachId:string)=>{
        if(equiImages.length>0){
            for(let idx:number = 0;idx<equiImages.length;idx++){
                if(equiImages[idx].attachementId == attachId){
                    equiImages[idx] = null;
                }
            }
        }

       // setEquipImagesPath(equiImages);
    }
    let onClickEquipTypeImage = (divImageId: string, equipType: string) => {
        equipmentType = equipType;
        setEquipType(equipType);
        let divImages = ['divDryVan', 'divPlateDryVan', 'divStorageVan', 'divReeferVan', 'divHeatedVan', 'divflatBed', 'divdropDeck',
            'divLog', 'divScrapMetal', 'divrecycling', 'divchip', 'divdump', 'divlowBed', 'divchasis', 'divtank', 'divtankCrude',
            'divTankDryBulk', 'divtankPetrol', 'divTankPropane', 'divTankDryBulk1', 'divChemical', 'divOther']
        divImages.forEach((item, idx) => {
                $('#' + item).removeClass('image-selection');
        })
            $('#' + divImageId).addClass('image-selection');

        // updatePricingValues(equipType);
    }

    const updatePricingValues = ()=>{
        let qParam:string = `saasOrgId=${localStorage.getItem('saasorgId')}&equipmentType=${equipType}&modelYear=${year}`;
        
        NetworkManager.sendJsonRequest("api/organization/getDefaultEquipmentTypePricing","",qParam,"GET",true,(response:any)=>{
            let restData:any = JSON.parse(response);
            setDailyRate(restData?.dailyRate ? restData?.dailyRate : '')
            setWeeklyRate(restData?.weeklyRate? restData.weeklyRate : '')
            setMonthlyRate(restData?.monthlyRate? restData.monthlyRate : '')
            setDistanceRate(restData?.distanceRate? restData.distanceRate : '')
            setHourlyRate(restData?.engineRate? restData.engineRate : '')
        },onLoginError)
    }

    let disableEquipImages = ()=>{
        if(!$("#equipImages").hasClass("disable-form_equip")){
            //$("#equipImages").addClass("disable-form_equip");
        }
        if(!$("#equipDamages").hasClass("disable-form_equip")){
            //$("#equipDamages").addClass("disable-form_equip");
        }
        if(!$("#equipDocs").hasClass("disable-form_equip")){
            //$("#equipDocs").addClass("disable-form_equip");
        }
    }
    let enableEquipImages = ()=>{
        //$("#equipImages").removeClass("disable-form_equip");
       // $("#equipDamages").removeClass("disable-form_equip");
        //$("#equipDocs").removeClass("disable-form_equip");
    }
    let onLoginError = (err: any) => {
        BusyLoader.hideLoader()
        console.log(err)
    }

    const onChangeDocName = (e : any) => {
        setDocuName(e.target.value);
        // CommonUtil.isMandatory(docRef);
    }

    const onChangeDesc = (e : any) => {
        setDamageDesc(e.target.value);
        // CommonUtil.isMandatory(docRef);
    }


    const removeSelectedDoc = () => {
        dropFileObj.current = '';
        setDocAvail(false)
    }
    let onClickEquipImage = (mTile:string,images:any,imgPath:string,idx:number,catagory?:string)=>{
        // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
        // setGallary(images);
        if(images.length == 0){
            setEquipImagePath(imgPath);
        }else{
            setImageIdx(idx);
        }
        let equipImages:any = [];

        images.forEach((item:any,eIdx:number)=>{
            if(item && item.Type == catagory){
                equipImages.push(item)
            }
        });
        for(let i=0;i<equipImages.length;i++){
            if(equipImages[i].Path == imgPath){
                idx =  i;
                break;
            }
        }
        setImageIdx(idx);
        setModalTile(mTile);
        setGallary(equipImages);

        setImageMoalShow(true);
    }

    let onClickShowDamagesPopup = ()=>{
        setShowDamagePopup(true)
    }

    let onCloseDamagePopup = ()=>{
        setShowDamagePopup(false)
        setDamageAvail(false);
        setImageDamagePath("");
        setDamageDesc("");
    }

    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
    }
    return (
        <div style={{backgroundColor: '#f2f7f4'}}>
            <input type="file" ref={fileref} className="custom-file-input" id="inputGroupFile"
                accept="image/*" style={{display: 'none'}} onChange={(e) => {onSelectFiles(e)}} ></input>
            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imageEquipPath}/>
            <Modal show={showDamagePopup} dialogAs={DraggableComponent} >
                <input type="file" ref={damageFileref} className="custom-file-doc-input" multiple={false}
                       id="inputDamages" accept="image/*" style={{display: 'none'}}  onChange={(e) => {onSelectDamageFiles(e)}}></input>
                <div className="modal-content" style={{width: '550px', height: '500px'}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.EQUIP_DAMAGES,'Equipment Damages')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDamagePopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            damageAvail?(<div className="boxed" style={{cursor:"pointer"}} onClick={()=>{onClickDamagBrowse(CommonUtil.EQUIP_DAMAGES)}}>
                                <div className="row">
                                    <div className="col-sm-12 col-12 text-center">
                                        <form action="" className="dropzone">
                                            <img src={imageDmagePath} className="upload-thumbnail" />
                                        </form>
                                    </div>
                                </div>
                            </div>):(<div className="boxed" style={{cursor:"pointer"}} onClick={()=>{onClickDamagBrowse(CommonUtil.EQUIP_DAMAGES)}}>
                                <div className="row">
                                    <div className="col-sm-12 col-12 text-center">
                                        <form action="" className="dropzone">
                                            <img src="../../images/Documents-Upload.png" className="mb-3" />
                                            <p className="text-center"><strong>{CommonUtil.getMessageText(Constants.DRAG_DRAP_DAMAGES,'browse damages')}</strong></p>
                                            <div className="fallback">
                                                <input name="file" type="file" style={{display:"none"}} multiple={true} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>)
                        }

                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.DESCRIPTION,'Description')}</label>
                            <textarea  className="form-control" rows={5} style={{height: '100px'}}
                                   placeholder={CommonUtil.getMessageText(Constants.DESCRIPTION,'Description')}
                                       value={damageDesc} ref={damageDescRef} onChange={onChangeDesc} />
                                       {/* <CDTextarea
                                          maxLength={disLength}
                                          ref={damageDescRef}
                                          value={damageDesc}
                                          placeholder={CommonUtil.getMessageText(Constants.DESCRIPTION,'Description')}
                                          rows={5}
                                          onChange={onChangeDesc}
                                        /> */}
                        </div>

                    </div>

                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal" onClick={onCloseDamagePopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={saveUploadDamages}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showDocPopup} dialogAs={DraggableComponent} >
                <input type="file" className="custom-file-doc-input" multiple={false}
                id="inputDOC" accept="application/pdf" style={{display: 'none'}}  onChange={(e) => {onSelectDocFiles(e)}}></input>
                <div className="modal-content" style={{width: '550px', height: '500px'}}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.NEW_DOCUMENT,'New Document')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDocPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{CommonUtil.getMessageText(Constants.DOC_NAME,'Document Name')} </label>
                            <input type="text" className="form-control clsStandardText clsFormInputControl"
                                   placeholder="Document" value={docuName} ref={docRef}
                                   onChange={onChangeDocName} />
                        </div>
                        <div className="form-group">
                            <label>Visibility</label>
                            <ul className="nav nav-tabs nav-justified" role="tablist" style={{height:"40px"}}>
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" id={"lnkPrivate"}  role="tab" onClick={()=>onChangePrivate('lnkPrivate')}>
                                        <i className="fa fa-lock"></i> {CommonUtil.getMessageText(Constants.PRIVATE,'Private')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " data-toggle="tab"  id={"lnkRenters"} role="tab" onClick={()=>onChangePrivate('lnkRenters')}>
                                        <i className="fa fa-users"></i> {CommonUtil.getMessageText(Constants.RENTERS,'Renters')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab"  id={"lnkPublic"} role="tab" onClick={()=>onChangePrivate('lnkPublic')}>
                                        <i className="fa fa-globe"></i> {CommonUtil.getMessageText(Constants.PUBLIC,'Public')}</a>
                                </li>
                            </ul>
                        </div>
                        <label>Upload</label>
                        {docAvail && dropFileObj.current ? (
                        <div className="boxed">
                            <div className="row">
                                <div className="col-sm-12 col-12 text-center">
                                    <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                        <img src="../../images/document.png" className="upload-thumbnail" />
                                        <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                         onClick={removeSelectedDoc} ></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        ):(
                        <div className="boxed" ref={documentsUploadDivRef} style={{cursor:"pointer"}} onClick={()=>{onClickDocBrowse(CommonUtil.EQUIP_DOCS)}}>
                            <div className="row">
                                <div className="col-sm-12 col-12 text-center">
                                    <form action="" className="dropzone">
                                        <img src="../../images/Documents-Upload.png" className="mb-3" />
                                        <p className="text-center"><strong>{CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES_BROWSE,'Drag & drop your documents here or browse documents')}</strong></p>
                                        <div className="fallback">
                                            <input name="file" type="file" style={{display:"none"}} multiple={true} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        )}
                        <div style={{display:"none"}} className="row fs-7">
                            <div className="col-md-12">
                                <span className="grey-text css-16ihq8s">Document Name</span>
                                <span className="clsFormMandatory"></span>
                                {/* <input  type="text" className="form-control clsStandardText  clsFormInputControl"
                                    placeholder="Document" aria-label="First Name" value={docuName}
                                    onChange={(e) => {setDocuName(e.currentTarget.value);CommonUtil.isMandatory(docRef)}} /> */}
                            </div>
                        </div>
                        <div style={{display:"none"}} className="row fs-7 clsFormStandardTopMargin">
                            <span className="grey-text css-16ihq8s">Visibility</span>
                            <span className="clsFormMandatory"></span>
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input ref={prvRef} type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"
                                     checked={chkPrivate} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio1">
                                    Private
                                </label>
                                <input ref={renterRef} type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                                     checked={chkRenters}/>
                                <label className="btn btn-outline-primary" htmlFor="btnradio2">
                                    Renters
                                </label>

                                <input ref={publicRef} type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"
                                    checked={chkPublic}
                                />
                                <label className="btn btn-outline-primary" htmlFor="btnradio3">
                                    Public
                                </label>
                            </div>
                        </div>
                        <div style={{display:"none"}} className="row fs-7 clsFormStandardTopMargin">
                            <span className="grey-text css-16ihq8s">Upload File</span>
                            <span className="clsFormMandatory"></span>
                            <div className="col-md-12 upload-file-box">
                                <div>
                                  <span className="grey-text css-16ihq8s">
                                    Drag and Drop files here
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <button className="btn btn-default browse" onClick={() => {onClickDocBrowse(CommonUtil.EQUIP_DOCS)}}>
                                        Browse Files
                                    </button>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal" onClick={onCloseDocPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={saveUploadDocs}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>

            <div className={`pt-1 bg-gray ${isMobile ? 'mx-0' : 'container'}`}>
                <br/>
                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right"><span className="count-span">1</span> {CommonUtil.getMessageText(Constants.GENERAL_DETAILS,'General Details')}<span style={{fontWeight: 300, fontSize: "15px", paddingLeft: "4px"}}>{`(Required)`}</span></h5>
                            <hr/>
                            <div className="row mb-4 padding-left-right">
                                <div className="col-md-4">
                                    <label className="col-form-label">{CommonUtil.getMessageText(Constants.ASSET_TYPE,'Asset Type')}</label>
                                    <SingleSelectComp dataSource={assetTypes} selectedValue={selAssetObj} handleChange={onAssetTypeChange}
                                        width={'100%'} value={'key'} label={'value'} className={assetTypeClass} disabled={CommonUtil.SELECT_EQUIP_ID?true:false} />
                                </div>
                                <div className="col-md-4" style={{display: CommonUtil.IS_GENERIC_EQUIP ? '' : 'none'}}>
                                    <label className="col-form-label">{CommonUtil.getMessageText(Constants.EQUIP_NAME,'Equipment Name')}</label>
                                    <input ref={equiNameRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                        placeholder={CommonUtil.getMessageText(Constants.EQUIP_NAME,'Equipment Name')} aria-label="trailer" value={equipName}
                                           onChange={(e) => {setEquipName(e.currentTarget.value);CommonUtil.isMandatory(equiNameRef)}} />
                                </div>
                                <div className="col-md-4" style={{display: CommonUtil.IS_GENERIC_EQUIP ? 'none' : ''}}>
                                    <label className="col-form-label">{CommonUtil.getMessageText(Constants.UNIT_NUMBER,'Unit Number')}</label>
                                    <input ref={unitNumRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                        placeholder={CommonUtil.getMessageText(Constants.UNIT_NUMBER,'Unit Number')} aria-label="trailer" value={unitNumber} disabled={CommonUtil.SELECT_EQUIP_ID?true:false}
                                           onChange={(e) => {setUnitNUmber(e.currentTarget.value);CommonUtil.isMandatory(unitNumRef)}} />
                                </div>
                                <div className="col-md-4" style={{display: CommonUtil.IS_GENERIC_EQUIP ? 'none' : ''}}>
                                    <label className="col-form-label">{CommonUtil.getMessageText(Constants.VIN_NO,'VIN Number')}</label>
                                    <input ref={vinRef} type="text" className={vinClass}
                                        placeholder={CommonUtil.getMessageText(Constants.VIN_NO,'VIN Number')}  maxLength={30} value={vinNumber} aria-label="Company Name(DBA)"
                                           onChange={(e) => {
                                               let eVin:string = e.currentTarget.value;
                                               seVINNumber(eVin);
                                               if(eVin.length>=VIN_LENGTH){
                                                   setVinClass(CommonUtil.TEXT_FIELD_VALID_CLASS);
                                               }else{
                                                   setVinClass(CommonUtil.TEXT_FIELD_MANDATORY_CLASS);
                                               }
                                           }

                                            } />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: CommonUtil.EQUIP_ASSET_TYPE == CommonUtil.ASSET_TYPE_TRAILER ? '' : 'none'}} className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-12">
                            <div id={'divDryVan'} className="equip-boxes d-inline-block image-selection"
                                 onClick={() => {onClickEquipTypeImage('divDryVan', 'DRY VAN')}}>
                                <img src="../../images/dryVan.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divPlateDryVan'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divPlateDryVan', 'PLATE DRY VAN')}}>
                                <img src="../../images/plateDryVan1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divStorageVan'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divStorageVan', 'STORAGE VAN')}}>
                                <img src="../../images/storageVan1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divReeferVan'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divReeferVan', 'REEFER VAN')}}>
                                <img src="../../images/reeferVan1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divHeatedVan'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divHeatedVan', 'HEATED VAN')}}>
                                <img src="../../images/heatedVan1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divflatBed'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divflatBed', 'FLATBED')}}>
                                <img src="../../images/flatBed1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divdropDeck'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divdropDeck', 'DROPDECK')}}>
                                <img src="../../images/dropDeck1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divLog'} className="equip-boxes d-inline-block"
                                 onClick={() => {onClickEquipTypeImage('divLog', 'LOG')}}>
                                <img src="../../images/log1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divScrapMetal'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divScrapMetal', 'SCRAP METAL')}}>
                                <img src="../../images/scrapMetal1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divrecycling'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divrecycling', 'RECYCLING')}}>
                                <img src="../../images/recycling1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divchip'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divchip', 'CHIP')}}>
                                <img src="../../images/chip1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divdump'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divdump', 'DUMP')}}>
                                <img src="../../images/dump1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divlowBed'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divlowBed', 'LOWBED')}}>
                                <img src="../../images/lowBed1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divchasis'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divchasis', 'CHASSIS')}}>
                                <img src="../../images/chassis1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divtank'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divtank', 'TANK')}}>
                                <img src="../../images/tank1.png" className={'equip-type-image'}/>
                            </div>
                            <div id={'divtankCrude'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divtankCrude', 'TANK CRUDE')}}>
                                <img src="../../images/tankCrude1.png" className={'equip-type-image'} />
                            </div>
                            <div id={'divTankDryBulk'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divTankDryBulk', 'TANK DRY BULK')}}>
                                <img src="../../images/tankDryBulk1.png" className={'equip-type-image'}/>
                            </div>
                            <div id={'divtankPetrol'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divtankPetrol', 'TANK PETROLEUM')}}>
                                <img src="../../images/tankPetroleum1.png" className={'equip-type-image'}/>
                            </div>
                            <div id={'divTankPropane'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divTankPropane', 'TANK PROPANE')}}>
                                <img src="../../images/tankPropane1.png" className={'equip-type-image'}/>
                            </div>

                            <div id={'divChemical'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divChemical', 'TANK CHEMICAL')}}>
                                <img src="../../images/tankChemical1.png" className={'equip-type-image'}/>
                            </div>
                            <div id={'divOther'} className="equip-boxes d-inline-block"
                                onClick={() => {onClickEquipTypeImage('divOther', 'Other')}}>
                                <img src="../../images/other1.png" className={'equip-type-image'}/>
                            </div>
                        </div>
                    </div>

                    <div style={{display: CommonUtil.EQUIP_ASSET_TYPE == CommonUtil.ASSET_TYPE_TRAILER ? 'none' : ''}}
                         className="row fs-7 padding-left-right">
                        <div className="col-md-12">
                            <div id={'divSpotter'} className="equip-boxes d-inline-block image-selection">
                                <img src="../../images/Spotter.png" className={'equip-type-image'} />
                            </div>
                        </div>

                    </div>

                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-4" style={{display:"flex",alignItems:"center"}}>
                            <input ref={chkFoodGrade} type="checkbox" className="checkBox" name="foodgrade" value="foodgrade" />
                            &nbsp;
                            <label className="col-form-label" htmlFor="vehicle1">{CommonUtil.getMessageText(Constants.FOOD_GRADE,'Food Grade')}</label>
                        </div>
                    </div>

                    <div className="row fs-7 pt-2 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.BRAND,'Brand')}</label>
                            <SingleSelectComp dataSource={brands} selectedValue={selBrandObj}
                                width={'100%'} value={'Key'} label={'Value'} handleChange={onBrandChange} className={CommonUtil.IS_GENERIC_EQUIP?"":brandClass}  />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.MODEL,'Model')}</label>
                            {/*<SingleSelectComp dataSource={modals} selectedValue={selModalObj}
                                width={'100%'} value={'key'} label={'value'} handleChange={onModalChange} className={CommonUtil.IS_GENERIC_EQUIP?"":modalClass}/>*/}

                            <input ref={modelRef} type="text" className={CommonUtil.IS_GENERIC_EQUIP?"form-control":"form-control"}
                                   placeholder={CommonUtil.getMessageText(Constants.MODEL,'Model')} aria-label="trailer" value={model}
                                   onChange={(e) => {setModel(e.currentTarget.value);}} />

                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.ESTIMATED_VALUE,'Estimated Value')}</label>
                            <input ref={yearRef} type="text" className={CommonUtil.IS_GENERIC_EQUIP?"form-control":"form-control"}
                                   placeholder={CommonUtil.getMessageText(Constants.ESTIMATED_VALUE,'Estimated Value')} aria-label="trailer" value={estimatedValue} min={0} maxLength={20}
                                   onChange={(e) => {setEstimatedValue(e.currentTarget.value);}} onKeyPress={CommonUtil.allowNumbersDecimal} />
                        </div>
                    </div>

                    <div className="row fs-7 clsFormStandardTopMargin padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.YEAR,'Year')}</label>
                            <input ref={yearRef} type="number" className={CommonUtil.IS_GENERIC_EQUIP?"form-control":"form-control clsFormInputControl"}
                                   placeholder={CommonUtil.getMessageText(Constants.YEAR,'Year')} aria-label="trailer" value={year} min={0} maxLength={4}
                                   onChange={(e) => {setYear(e.currentTarget.value);}} onKeyPress={CommonUtil.allowNumbersDecimal}/>
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.NO_OF_AXELS,'Number of Axles')}</label>
                            <SingleSelectComp dataSource={axis} selectedValue={selAxisObj} width={'100%'}
                                value={'Key'} label={'Value'} handleChange={onAxisChange} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER,'Length of Trailer')}</label>
                            <input ref={tailerLenRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                placeholder={CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER,'Length of Trailer')} aria-label="trailer" value={tailerLength}
                                //    onKeyPress={CommonUtil.allowNumbersDecimal}
                                //    onChange={(e) => {CommonUtil.handleNumbersDecimalInput({ event: e, ref: tailerLenRef, setState: seTrailerLength })}}
                                   onChange={(e) => {seTrailerLength(e.target.value); CommonUtil.isMandatory(tailerLenRef)}}
                                   />
                        </div>
                    </div>

                    <div className="row fs-7 clsFormStandardTopMargin padding-left-right">
                        {
                            CommonUtil.IS_GENERIC_EQUIP ? null :
                            <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO,'License Plate Number')}</label>
                            <input ref={licenseRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                placeholder={CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO,'License Plate Number')} aria-label="trailer" value={licenseNum}
                                onChange={(e) => {setLicenseNum(e.currentTarget.value);CommonUtil.isMandatory(licenseRef)}} />
                        </div>
                        }
                        <div className="col-md-4" id={"divExpDate"}>
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.ANNUAL_MECH_INSPECTION,'Annual Mechanical Inspection Expiration Date')}</label>
                            <Flatpickr placeholder={CommonUtil.getMessageText(Constants.EXPIRY_DATE,'Expiration Date')} ref={dateRef} options={dateOptions} onChange={onChangeeDate}
                                       className="form-control"  />
                        </div>
                        <div className="col-md-4" id={"divTrackType"}>
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.TRACK_TYPE,'Tracking Type')}</label>
                            <SingleSelectComp dataSource={trackTypes} selectedValue={selTrackObj} handleChange={onTrackTypeChange}
                                              width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":trackClass} />
                        </div>
                    </div>
                </div>



                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right"><span className="count-span">2</span> {CommonUtil.getMessageText(Constants.SPECIFICATIONS,'Specifications')}</h5>
                            <hr/>
                            {/* <h6 className="mb-3 padding-left-right">{CommonUtil.getMessageText(Constants.SPECIFIC_REQUIRED,'Specifications Required')}</h6> */}
                        </div>
                    </div>
                    
                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-12">
                            <h6 className="mb-3">{CommonUtil.getMessageText(Constants.TRAILERS,'Trailers')}</h6>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.REAR_DOOR,'Rear Door')}</label>
                            <SingleSelectComp dataSource={rearDoors} selectedValue={selRearDoorObj} handleChange={onRearDoorChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.SIDE_DOOR,'Side Door')}</label>
                            <SingleSelectComp dataSource={sideDoors} selectedValue={selSideDoorObj} handleChange={onSideDoorChange}
                                width={'100%'} value={'Key'} label={'Value'} // className={CommonUtil.IS_GENERIC_EQUIP?"":sideDoorClass}
                                />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.ROOF,'Roof')}</label>
                            <SingleSelectComp dataSource={roofs} selectedValue={selRoofObj} handleChange={onRoofChange}
                                width={'100%'} value={'Key'} label={'Value'} // className={CommonUtil.IS_GENERIC_EQUIP?"":roofClass}
                                />
                        </div>
                    </div>

                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.FLOOR,'Floor')}</label>
                            <SingleSelectComp dataSource={equipFloors} selectedValue={selFloorObj} handleChange={onFloorChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.INTERIOR_FINISH,'Interior Finish')}</label>
                            <SingleSelectComp dataSource={interiorEquips} selectedValue={selInteriorObj} handleChange={onInteriorChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LOGIS_POST_DIST,'Logistics Posts Distance')}</label>
                            <SingleSelectComp dataSource={logisticsEquips} selectedValue={selLogisticObj} handleChange={onLogisticChange}
                                              width={'100%'} value={'Key'} label={'Value'} // className={CommonUtil.IS_GENERIC_EQUIP?"":logisticClass}
                                              />
                        </div>
                    </div>

                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.DOUBLE_DECK_BEAMS,'Double Deck Beams')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={doubleDeckBeamObj} width={'100%'}
                                value={'Key'} label={'Value'} handleChange={onDeckBeamsChange}
                                             // className={CommonUtil.IS_GENERIC_EQUIP?"":doubleClass}
                                             />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.FLOOR_TYPE,'Floor Type')}</label>
                            <SingleSelectComp dataSource={floorType} selectedValue={selFloorType} width={'100%'}
                                              value={'Key'} label={'Value'} handleChange={onFloorTypeChange}
                                              className={CommonUtil.IS_GENERIC_EQUIP?"":""}/>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.AXLE_SPREAD,'Axle Spread')}</label>
                            <SingleSelectComp dataSource={axisSpeeds} selectedValue={selAxisSpeedObj} handleChange={onAxisSpeedChange}
                                width={'100%'} value={'Key'} label={'Value'} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.TIRE_INF_SYS,'Tire Inflation System')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={selTireInflationObj}
                                handleChange={onTireInflationChange} width={'100%'}
                                value={'Key'} label={'Value'} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.SKIRTS,'Skirts')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={selSkirtsObj}
                                handleChange={onSkirtsChange} width={'100%'} value={'Key'} label={'Value'}/>
                        </div>
                    </div>

                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.PINTLE_HOOK,'Pintle Hook')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={selPrintleHookObj}
                                handleChange={onPrintelHookChange} width={'100%'} value={'Key'} label={'Value'} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.HAZMAT_PLACARD,'Hazmat Placard')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={selPlacardHolderObj}
                                handleChange={onHazmatPlacardChange} width={'100%'} value={'Key'} label={'Value'} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.SUSPENSION,'Suspension')}</label>
                            <SingleSelectComp dataSource={suspensionTypes} selectedValue={selSuspensionObj}
                                handleChange={onSuspensionChange} width={'100%'} value={'Key'} label={'Value'} />
                        </div>
                    </div>




                    <div className="row fs-7 mt-3 padding-left-right">
                        <div className="col-md-12">
                            <h6 className="mb-3">{CommonUtil.getMessageText(Constants.LIFT,'Lift')}</h6>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right">
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LIFT,'Lift')}</label>
                            <SingleSelectComp dataSource={tieInflations} selectedValue={selLiftObj} handleChange={onLiftChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LIFT_TYPE,'Lift Type')}</label>
                            <SingleSelectComp dataSource={liftTypes} selectedValue={selLiftTYpeObj} handleChange={onLiftTypeChange}
                                width={'100%'} value={'Key'} label={'Value'} //className={CommonUtil.IS_GENERIC_EQUIP?"":liftTypeClass}
                                />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.LIFT_CAPACITY,'Lift Capacity')}</label>
                            <SingleSelectComp dataSource={liftCapacities} selectedValue={selLiftCapObj} handleChange={onLiftCapacityChange}
                                width={'100%'} value={'Key'} label={'Value'}  //className={CommonUtil.IS_GENERIC_EQUIP?"":liftCapClass}
                                 />
                        </div>
                    </div>

                    <div className="row fs-7 mt-3 padding-left-right" style={{display:((equipType != "REEFER VAN" && equipType != "HEATED VAN")?"none":"")}}>
                        <div className="col-md-12">
                            <h6 className="mb-3">{CommonUtil.getMessageText(Constants.TEMP_CONTROL,'Temperature Control')}</h6>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right" style={{display:((equipType != "REEFER VAN" && equipType != "HEATED VAN")?"none":"")}}>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.UNIT_MAKE,'Unit Make')}</label>
                            <SingleSelectComp dataSource={unitMakes} selectedValue={selUnitMakeObj} handleChange={onUnitMakeChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":""} />
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.UNIT_MODEL,'Unit Model')}</label>
                            <input ref={unitModalRef} type="text" className={CommonUtil.IS_GENERIC_EQUIP?"form-control":"form-control"}
                                   placeholder={CommonUtil.getMessageText(Constants.UNIT_MODEL,'Unit Model')} aria-label="trailer" value={unitModel}
                                   onChange={(e) => {setUnitModel(e.currentTarget.value);}} />

                            {/*<SingleSelectComp dataSource={unitModels} selectedValue={selUnitModelObj} handleChange={onUnitModelChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":unitModeClass} />*/}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.UNIT_YEAR,'Unit Year')}</label>
                            <input ref={unitYearRef} type="number" className={CommonUtil.IS_GENERIC_EQUIP?"form-control":"form-control"}
                                   placeholder={CommonUtil.getMessageText(Constants.UNIT_YEAR,'Unit Year')} aria-label="trailer" value={unitYear} maxLength={4}
                                   onChange={(e) => {setUnitYear(e.currentTarget.value);}}  />

                            {/*<SingleSelectComp dataSource={unitYears} selectedValue={selUnitYearObj} handleChange={onUnitYearChange}
                                width={'100%'} value={'Key'} label={'Value'} className={CommonUtil.IS_GENERIC_EQUIP?"":unitYearClass} />*/}
                        </div>
                    </div>

                    <div className="row fs-7 mt-3 padding-left-right" style={{display:(equipType != "REEFER VAN"?"none":"")}}>
                        <div className="col-md-4">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.MULTI_TEMP,'Multi Temperature')}</label>
                            <SingleSelectComp dataSource={unitTemps} selectedValue={selUnitTempObj} width={'100%'}
                                handleChange={onUnitTempChange} value={'Key'} label={'Value'}
                                              className={CommonUtil.IS_GENERIC_EQUIP?"":unitTempClass} />
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>

                {/* section 3 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                <span className="count-span">3</span> {CommonUtil.getMessageText(Constants.EQUIP_PHOTO,'Equipment Photos')}
                            </h5>
                            <hr/>
                        </div>
                    </div>
                    <div className="row fs-7 padding-left-right">
                        <div id={""} className="upload-border mt-3 col-12">
                            <div id="file-upload-form" className={`uploader col-md-6 col-lg-4`} style={{cursor:"pointer"}} onClick={() => {onClickBrowse(CommonUtil.EQUIP_IMAGES)}}>
                                <input id="file-upload" type="file" name="fileUpload" style={{display: 'none'}} accept="image/*" />
                                <label id="file-drag" className="pb-3 pt-3">
                                    <img id="file-image" src="#" alt="Preview" className="hidden" style={{display: 'none'}} />
                                    <div id="equipImages" ref={equipDivRef} style={{border:"1px solid #000",marginBottom:"0px",paddingTop:"5px",cursor:"pointer"}}>
                                        <img src="../../images/image-upload.svg" style={{maxWidth:"35px"}} className="upload-imageIcon mb-2 pb-1"
                                             />
                                        <div className="fw-500" style={{marginBottom:"1px",fontSize:"10px"}}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_PHOTOS,'Drag & Drop here or Browse Photos')}
                                        </div>
                                        <div className=" " style={{fontSize:"10px",marginBottom:"0px"}}> {CommonUtil.getMessageText(Constants.MAX_10_MB,'Max 10 mb')}</div>
                                        <div id="notimage" className="hidden">
                                            {CommonUtil.getMessageText(Constants.PLEASE_SELECT_IMAGE,'Please select an image')}
                                        </div>
                                    </div>
                                    <div id="response" className="hidden">
                                        <div id="messages"></div>
                                    </div>
                                </label>
                            </div>
                            <div style={{display: 'inline'}}>
                                {equipImagesPath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                                        var list = (
                                            <div className={'upload-image-item'} key={idx}>

                                                <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                    <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                       style={{"visibility":CommonUtil.SELECT_EQUIP_ID?"visible":"hidden"}}
                                                       onClick={() => {onClickRemoveImage(item.attachementId,CommonUtil.EQUIP_IMAGES)}} ></i>
                                                </a>
                                                <img src={item.Path} className="upload-thumbnail" style={{cursor:"pointer"}}
                                                     onClick={()=>{
                                                         //console.log(equipImagesPath,item.Path,idx);
                                                         onClickEquipImage(CommonUtil.getMessageText(Constants.EQUIP_PHOTO,"Equipment Photos"),equipImagesPath,item.Path,idx,CommonUtil.EQUIP_IMAGES);
                                                     }}/>

                                            </div>
                                        )
                                        return list
                                    }
                                })}

                            </div>
                        </div>
                    </div>
                </div>

                {/* section 4 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                <span className="count-span">4</span>Damage Photos
                            </h5>
                            <hr/>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right">
                        <div id={""} className="upload-border mt-3">
                            <div id="file-upload-form" className={`uploader col-md-6 col-lg-4`} style={{cursor:"pointer"}} onClick={onClickShowDamagesPopup}>
                                <input id="file-upload" type="file" name="fileUpload" style={{display: 'none'}} accept="image/*" />
                                <label id="file-drag" className="pb-3 pt-3">
                                    <img id="file-image" src="#" alt="Preview" className="hidden" style={{display: 'none'}} />
                                    <div id="equipDamages" ref={damagesDivRef} style={{border:"1px solid #000",marginBottom:"0px",paddingTop:"5px",cursor:"pointer"}}>
                                        <img src="../../images/image-upload.svg" style={{maxWidth:"35px"}} className="upload-imageIcon mb-2 pb-1"
                                             />
                                        <div className="fw-500" style={{marginBottom:"1px",fontSize:"10px",display:"none"}}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_PHOTOS,'Drag & Drop here or Browse Photos')}
                                        </div>
                                        <div className=" " style={{fontSize:"10px",marginBottom:"5px"}}>{CommonUtil.getMessageText(Constants.MAX_10_MB,'Max 10 mb')}</div>
                                        <div id="notimage" className="hidden">
                                            {CommonUtil.getMessageText(Constants.PLEASE_SELECT_IMAGE,'Please select an image')}
                                        </div>
                                    </div>
                                    <div id="response" className="hidden">
                                        <div id="messages"></div>
                                    </div>
                                </label>
                            </div>
                            <div style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesPath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DAMAGES) {
                                        var list = (
                                            <div className={'upload-image-item'} key={idx}>
                                                <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                    <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                       style={{"visibility":CommonUtil.SELECT_EQUIP_ID?"visible":"hidden"}}
                                                       onClick={() => {onClickRemoveImage(item.attachementId,CommonUtil.EQUIP_DAMAGES)}} ></i>
                                                </a>
                                                <div style={{display:"flex",flexDirection:"column"}}>
                                                <img src={item.Path} className="upload-thumbnail" style={{cursor:"pointer"}}
                                                     onClick={()=>{
                                                         //console.log(equipImagesPath,item.Path,idx);
                                                         onClickEquipImage(CommonUtil.getMessageText(Constants.DAMAGES,"Equipment Photos"),equipImagesPath,item.Path,idx,CommonUtil.EQUIP_DAMAGES);
                                                     }}/>
                                                    <span style={{width:"100px",textAlign:"left"}}>{item.attachmentDescription}</span>
                                                </div>

                                            </div>
                                        )
                                        return list
                                    }
                                })}

                            </div>

                            <div style={{display:"none"}} id="file-upload-form" className={`uploader col-md-6 col-lg-4`}>
                                <input id="file-upload" type="file" name="fileUpload" accept="image/*" style={{display: 'none'}} />
                                <label id="file-drag" className="pb-3 pt-3">
                                    <img id="file-image" src="#" alt="Preview" className="hidden" style={{display: 'none'}} />
                                    <div id="start" style={{paddingTop:"5px"}}>
                                        <img src="../../images/image-upload.svg" className="upload-imageIcon mb-2 pb-1"
                                            onClick={() => {onClickBrowse(CommonUtil.EQUIP_DAMAGES)}} />
                                        <div className="fs-7 darkgrey-text fw-500">
                                            Drag & Drop here or Browse Photos
                                        </div>
                                        <div className="fs-7 grey-text">Max 10 mb</div>
                                        <div id="notimage" className="hidden">
                                            Please select an image
                                        </div>
                                    </div>
                                    <div id="response" className="hidden">
                                        <div id="messages"></div>
                                    </div>
                                </label>
                            </div>
                            <div style={{display: 'none',overflow:"auto"}}>
                                {equipImagesPath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DAMAGES) {
                                        var list = (
                                            <div className={'upload-image-item'}>
                                                <img src={item.Path} className="upload-thumbnail m-3"/>
                                                <a style={{color: 'blue', paddingTop: '5px'}}>
                                                    <i className=" remove-btn fa fa-remove"
                                                       style={{cursor: 'pointer',color: 'red',"visibility":CommonUtil.SELECT_EQUIP_ID?"visible":"hidden"}}
                                                        onClick={() => {onClickRemoveImage(item.attachementId,CommonUtil.EQUIP_DAMAGES)}} ></i>
                                                </a>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>

                        </div>
                    </div>
                </div>

                {/* section 5 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                <span className="count-span">5</span>{CommonUtil.getMessageText(Constants.INSTRUCTIONS,'Instructions')}
                            </h5>
                            <hr/>
                        </div>
                    </div>

                    <div className="row fs-7 padding-left-right">
                        <div className="col-md-12">
                            <p className="fs-7 black-text ">
                                {CommonUtil.getMessageText(Constants.INSTRUCTIONS_REQUIRED,'These instructions will be requested of the drivers to apply and\n' +
                                    '                                confirm at the beginning and at the end of the rental. These\n' +
                                    '                                instructions may also assist the driver if needed.')}

                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                  <span className="fs-7 black-text mt-2 d-block css-16ihq8s">
                                    {CommonUtil.getMessageText(Constants.ACTIVATION_INSTR,'Activation Instructions')}
                                  </span>
                                    {/* <textarea ref={activateRef} className={"form-control"}
                                        rows={10} style={{height: '200px'}} placeholder=""
                                        value={insActivation} onChange={(e) => {setInsActivation(e.currentTarget.value);}}></textarea> */}
                                        <CDTextarea
                                          maxLength={disLength}
                                          ref={activateRef}
                                          value={insActivation}
                                          placeholder=""
                                          rows={10}
                                          onChange={(e:any) => {setInsActivation(e.currentTarget.value)}}
                                        />
                                </div>
                                <div className="col-md-6">
                                      <span className="fs-7 black-text mt-2 d-block css-16ihq8s">
                                        {CommonUtil.getMessageText(Constants.DEACTIVATION_INSTR,'Deactivation Instructions')}
                                      </span>
                                    {/* <textarea ref={deactivateRef} className={"form-control"}
                                        rows={10} style={{height: '200px'}} placeholder=""
                                        value={insDeActivation} onChange={(e) => {setInsDeActivation(e.currentTarget.value);}}></textarea> */}
                                        <CDTextarea
                                          maxLength={disLength}
                                          ref={deactivateRef}
                                          value={insDeActivation}
                                          placeholder=""
                                          rows={10}
                                          onChange={(e:any) => {setInsDeActivation(e.currentTarget.value);}}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 6 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                {' '}
                                <span className="count-span">6</span>{`Upload Documents (Ownership & Insurance Certificate)`}
                            </h5>
                            <hr/>
                        </div>
                    </div>
                    <div className="row fs-7 padding-left-right">
                        <div id={"equipDocs"} className="upload-border mt-3">
                            <div id="file-upload-form" className={`uploader col-md-6 col-lg-4`} onClick={onClickDocumentUpload}>
                                <input id="file-upload" type="file" name="fileUpload" style={{display: 'none'}} accept="image/*" />
                                <label id="file-drag" className="pb-3 pt-3">
                                    <img id="file-image" src="#" alt="Preview" className="hidden" style={{display: 'none'}} />
                                    <div id="start"  style={{border:"1px solid #000",marginBottom:"0px",paddingTop:"5px",cursor:"pointer"}}>
                                        <img src="../../images/image-upload.svg" style={{maxWidth:"35px"}} className="upload-imageIcon mb-2 pb-1"
                                              />
                                        <div className="fw-500" style={{marginBottom:"1px",fontSize:"10px"}}>
                                            {CommonUtil.getMessageText(Constants.BROWSE_FILE,'Browse Files')}
                                        </div>
                                        <div className=" " style={{fontSize:"10px",marginBottom:"5px"}}>{CommonUtil.getMessageText(Constants.MAX_10_MB,'Max 10 mb')}</div>
                                        <div id="notimage" className="hidden">
                                            {CommonUtil.getMessageText(Constants.SELECT_IMAGE,' Please select an image')}
                                        </div>
                                    </div>
                                    <div id="response" className="hidden">
                                        <div id="messages"></div>
                                    </div>
                                </label>
                            </div>
                            <div style={{display: 'flex',overflow:"auto"}}>
                                {equipImagesPath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DOCS) {
                                        var list = (
                                            <div className={'upload-image-item'} key={idx}>
                                                <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                    <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                       style={{"visibility":CommonUtil.SELECT_EQUIP_ID?"visible":"hidden"}}
                                                       onClick={() => {onClickRemoveImage(item.attachementId,CommonUtil.EQUIP_DOCS)}} ></i>
                                                </a>
                                                {
                                                    CommonUtil.SELECT_EQUIP_ID?
                                                        <img src="../../images/document.png" className="upload-thumbnail" onClick={()=>onDocDownload(item.fullFilePath)}/>:
                                                        <img src="../../images/document.png" className="upload-thumbnail" />
                                                }
                                                <span style={{textAlign:"center"}}>{item.documentName}</span>
                                            </div>
                                        )
                                        return list
                                    }
                                })}

                            </div>
                            <div style={{display:"none"}} id="" className={`uploader col-md-6 col-lg-4`}>
                                <label htmlFor="" id="" className="pb-1 pt-3 text-center"
                                    data-bs-toggle="modal">
                                    <div id="start"  onClick={onClickDocumentUpload}>
                                        <img src="../../images/doc-upload.svg" className="upload-docIcon"
                                             />
                                        <div className="fs-7 darkgrey-text fw-500">
                                            Drag & Drop here or Browse Files
                                        </div>
                                        <div className="fs-7 grey-text">
                                            JPG, PNG, PDF (Max 10 mb)
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div style={{display: 'none',overflow:"auto"}}>
                                {equipImagesPath.map((item: any, idx: number) => {
                                    if (item && item.Type == CommonUtil.EQUIP_DOCS) {
                                        var list = (
                                            <div className={'upload-image-item'}>
                                                <img src="../../images/document.png" className="upload-thumbnail m-3" />
                                                <a style={{color: 'blue', paddingTop: '5px'}}>
                                                    <i style={{color: 'red', cursor: 'pointer'}} className=" remove-btn fa fa-remove"
                                                        onClick={() => {onClickRemoveImage(item.attachementId,CommonUtil.EQUIP_DOCS)}} ></i>
                                                </a>
                                            </div>
                                        )
                                        return list
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 7 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''}`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                {' '}
                                <span className="count-span">7</span>{CommonUtil.getMessageText(Constants.PRICING,'Pricing')}<span style={{fontWeight: 300, fontSize: "15px", paddingLeft: "4px"}}>{`(Rental)`}</span>
                            </h5>
                            <hr/>
                        </div>
                    </div>
                    <div className="row fs-7 padding-left-right mb-2">
                        <div className="col-md-6">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE,'Daily Rate')}</label>
                            <div className={`col-md-12 pe-0`}>
                                <input ref={dailyRateRef} type="text"
                                    className="form-control equip-pricingInput fw-600 text-center"
                                    placeholder="Daily Rate" value={dailyRate} maxLength={10}
                                    onChange={handleInputHourlyRate} onKeyPress={CommonUtil.allowNumbersDecimal} />
                                <span className="fw-500 darkgrey-text">/ {CommonUtil.getMessageText(Constants.DAY,'DAY')}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE,'Weekly Rate')}</label>
                            <div className={`col-md-12 pe-0`}>
                                <input ref={weeklyRateRef} type="text"
                                    className="form-control equip-pricingInput fw-600 text-center"
                                    placeholder="Weekly Rate" value={weeklyRate} maxLength={10}
                                    onChange={handleInputWeeklyRate} onKeyPress={CommonUtil.allowNumbersDecimal} />
                                <span className="fw-500 darkgrey-text">/ {CommonUtil.getMessageText(Constants.WEEK,'WEEK')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row fs-7 padding-left-right mb-2">
                        <div className="col-md-6">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE,'Monthly Rate')}</label>
                            <div className={`col-md-12 pe-0`}>
                                <input ref={monthlyRateRef} type="text"
                                    className="form-control equip-pricingInput fw-600 text-center"
                                    placeholder="Monthly Rate" value={monthlyRate} maxLength={10}
                                    onChange={handleInputMonthlyRate} onKeyPress={CommonUtil.allowNumbersDecimal} />
                                <span className="fw-500 darkgrey-text">/ {CommonUtil.getMessageText(Constants.MONTH,'MONTH')}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.DIST_RATE,'Distance Rate')}</label>
                            <div className={`col-md-12 pe-0`}>
                                <input ref={distanceRef} type="text"
                                    className="form-control equip-pricingInput fw-600 text-center"
                                        placeholder="Distance Rate" value={distanceRate} maxLength={10}
                                        onChange={handleInputDistanceRate} onKeyPress={CommonUtil.allowNumbersDecimal}/>
                                <span className="fw-500 darkgrey-text">/ {CommonUtil.getMessageText(Constants.MI,'MI')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row fs-7 padding-left-right mb-3">
                        <div className="col-md-6">
                            <label className="col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE,'Engine Rate')}</label>
                            <div className={`col-md-12 pe-0`}>
                                <input ref={hourRef} type="text" className="form-control equip-pricingInput fw-600 text-center"
                                    placeholder="Engine Rate" value={hourRate} maxLength={10}
                                    onChange={handleInputEngineRate} onKeyPress={CommonUtil.allowNumbersDecimal}/>
                                <span className="fw-500 darkgrey-text">/ {CommonUtil.getMessageText(Constants.HOUR,'HOUR')}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Security Deposit <span style={{fontWeight: 300, fontSize: "13px", paddingLeft: "4px"}}>{`(Optional. vHub keeps the amount during reservation)`}</span></label>
                            <div className={`col-md-12 pe-0`}>
                                <input type="text" className="form-control clsStandardText" placeholder={CommonUtil.getMessageText(Constants.ENTER_AMOUNT, 'Enter Amount')}
                                    aria-label="amount" value={amount} onChange={(e) => {CommonUtil.handleNumbersDecimalInput({ event: e, ref: null, setState: setAmount})}} />
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <p className="grey-text">
                                    {CommonUtil.getMessageText(Constants.VHUB_RENTAL_RATES, ' These are the rates that will apply for this rental. vHub will\n' +
                                        '                                charge a fee in addition to these rates. Please refer to our\n' +
                                        '                                Payment Terms for full details.')}
                                </p>
                        </div>
                    </div>
                </div>

                {/* section 8 */}

                <div className={`create-equip-card ${isMobile ? 'mx-3' : ''} d-none`}>
                    <div className="row fs-7">
                        <div className="col-md-12">
                            <h5 className="mb-4 padding-left-right">
                                {' '}
                                <span className="count-span">8</span>{CommonUtil.getMessageText(Constants.GENERAL_SETTINGS, 'General Settings')}
                            </h5>
                            <hr/>
                        </div>
                    </div>

                    <div className="row fs-7">
                        <div className="col-md-12 padding-left-right">
                            <p className="fs-7 grey-text">
                                {CommonUtil.getMessageText(Constants.VHUB_RENTAL_RATES, ' These are the rates that will apply for this rental. vHub will\n' +
                                    '                                charge a fee in addition to these rates. Please refer to our\n' +
                                    '                                Payment Terms for full details.')}

                            </p>
                            <div className="row mx-0">
                                <div className="col-md-6 ps-0" id={"divEmails"}>
                                    {/* <span className="black-text ">Axle Spread</span> */}
                                    <span className="black-text css-16ihq8s">
                                        {CommonUtil.getMessageText(Constants.ADDITIONAL_EMAILS_COMMA_SEPARATED, 'Additional emails for renting notifications (Comma separated)')}
                                  </span>
                                </div>
                                <div className="col-md-6 pe-0">
                                  <span className="black-text css-16ihq8s">
                                    {CommonUtil.getMessageText(Constants.SECURE_DEPOSIT_RESERVATION, 'Security Deposit (Optional. vHub keeps the amount during reservation)')}
                                  </span>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className={`col-md-6 ${isMobile ? 'mb-1' : 'ps-0'}`} id={"divEmailBox"}>
                                    <input type="text" className="form-control clsStandardText"
                                        placeholder="Please enter email" aria-label="emails" value={emails}
                                        onChange={(e) => {setEmails(e.currentTarget.value)}} />
                                </div>
                                <div className={`col-md-6 ${isMobile ? 'mt-1' : 'pe-0'}`}>
                                    <input type="text" className="form-control clsStandardText" placeholder={CommonUtil.getMessageText(Constants.ENTER_AMOUNT, 'Enter Amount')}
                                        aria-label="amount" value={amount} onChange={(e) => {CommonUtil.handleNumbersDecimalInput({ event: e, ref: null, setState: setAmount})}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section buttons */}

            <section className="bottom equip-section-bottom">
                <div className="container">
                    <div className="row fs-7 equip-bottomBtns" style={isMobile ? {} : {marginLeft: '100px', marginRight: '100px'}}>
                        <div className="col-md-12 clsAlignRight">
                           <span className="css-16ihq8s" style={{color:"red"}}>{mandatoryText} </span>
                        </div>
                        <div className="col-md-12 clsAlignRight my-3 pr-0">
                            <button className="btn clsFormButtonNormal btn-outline-dark mx-2 p-0"
                                    onClick={onClickDiscardChanges} style={{height: '28px'}}>
                                {CommonUtil.getMessageText(Constants.DISCARD_CHANGES, 'Discard Changes')}
                            </button>
                            <button type="submit" className="btn btn-success"
                                style={{height: '28px', width: '80px !important'}} onClick={onClickCreteEquip}>{CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CreateEquipment