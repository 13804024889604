import React, {useEffect, useState} from 'react'
import './App.css'
import {NavLink as Link, Route, Routes, useNavigate} from 'react-router-dom'
import $ from 'jquery'
import LoginPage from './Pages/Login/LoginPage'
import MenuPage from './Pages/Login/MenuPage'
import BusyLoader from './Components/BusyLoader'
import Confirm from './Components/confirm'
import ToastManager from './Components/ToastManager'
import ForgetPassword from './Pages/Login/ForgetPassword'
import SignUpPage from './Pages/Login/SignUpPage'
import RegistrationPage from './Pages/Login/RegistrationPage'
import SignupRegistration from './Pages/Login/SignupRegistration'
import {NetworkManager} from './Components/NetworkManager'
import ResetPage from "./Pages/Login/ResetPage";
import ForgotPasswordReg from "./Pages/Login/ForgotPasswordReg";
import {CommonUtil} from "./utils/CommonUtil";
import OffersReceived from './Pages/Reports/OffersReceived'
import ProcessReservations from "./Pages/Reports/ProcessReservations";
import ReservationProcessView from "./Pages/Equipments/ReservationProcessView";
import {Constants} from "./Pages/Languages/Constants";
import {Events} from "./Pages/Languages/Events";
import SearchDashboardNew from "./Pages/DashBoards/SearchDashboardNew";
import EquipPublicView from "./Pages/Equipments/EquipPublicView";
import LoadBoardListingView from "./Pages/Equipments/LoadBoardListingView";
import LoginModal from "./Pages/Login/LoginModal";
import LoginPageModal from "./Pages/Login/LoginPageModal";
import ReservationView from "./Pages/Equipments/ReservationView";
import ReservationViewCopy from "./Pages/Equipments/ReservationViewCopy";
import LoadBoardReservationView from "./Pages/Equipments/LoadBoardReservationView";
import PublicSearchDashboard from "./Pages/DashBoards/PublicSearchDashboard";
import OffersMade from "./Pages/Reports/OffersMade";
import OfferOwnerView from './Pages/Reports/OfferOwnerView'
import OfferRenterView from './Pages/Reports/OfferRenterView'


function App() {
    const navigate = useNavigate();
    const [routeVisible, setRouteVisible] = useState(false);

    useEffect(() => {
        console.log('location:' + window.location.pathname, window.location.search)
        console.log("Last Accessed Screen:" + sessionStorage.getItem("LAS1"));
        console.log("URL:" + process.env["REACT_APP_BASE_URL "]);
        //console.log(localStorage.)
        getServerPath();
        clearBrowserCache();
        //window.addEventListener("beforeunload", alertUser);
        return () => {
            //window.removeEventListener("beforeunload", alertUser);
        };
    }, [])

    const alertUser = (e: any) => {
        console.log(e);
        //e.preventDefault();
        //e.returnValue = "";
    };

    let clearBrowserCache = () => {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            // window.location.reload();
        }
    }

    let getServerPath = () => {
        if (localStorage.getItem("Language")) {
                    CommonUtil.SELECTED_LANGUAGE = localStorage.getItem("Language");
                }
        $.ajax({
          url: 'server.xml?1.2',
          beforeSend: function () {},
          success: function (data, statusCode, jqXHR) {
            var xmlResponse = $(data).find('Server')
            for (var i = 0; i < xmlResponse.length; i++) {
              var item = xmlResponse[i]
              let routeUrl = $(item).find('URL').text();
              NetworkManager.serverPath = routeUrl;
                let gMapKey = $(item).find('gMapKey').text();
                CommonUtil.GOOGLE_MAP_KEY = gMapKey;
                let usPassCode = $(item).find('USPassCode').text();
                CommonUtil.US_PASSCODE = usPassCode;
                let canadaPassCode = $(item).find('CanadaPasscode').text();
                CommonUtil.CANADA_PASSCODE = canadaPassCode;
              let path = window.location.search;
              setRouteVisible(true);
              if(path){
                  path = path.substring(1,path.length);
                  const params = new URLSearchParams(window.location.search) // id=123
                  //console.log(params.get('token'),params.get('requestor'));

                  if(params.get('requestor') == "org"){
                    navigate("/vhubreg/?"+path);
                  }if(params.get('requestor') == "signup"){
                      navigate("/signup/?"+path);
                  }if(params.get('requestor') == "searchdashboard"){
                      CommonUtil.PUBLIC_SEARCH_DB = true;
                      CommonUtil.PRIVATE_SEARCH_DB = false;
                      navigate("/searchdashboard");
                  }else if(params.get('requestor') == "menu"){
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "equipview"){
                      CommonUtil.addScreensToLastAccessed(path);
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "reservationview"){
                      CommonUtil.addScreensToLastAccessed(path);
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "viewreservation"){
                    CommonUtil.addScreensToLastAccessed(path);
                    navigate("/reset/?"+path);
                  }else if(params.get('requestor') == "billingdoc"){
                      CommonUtil.addScreensToLastAccessed(path);
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "reset"){
                      navigate("/reset/?"+path);
                  }else if(params.get('requestor') == "equippublicview"){
                      if(localStorage.getItem("PublicSearch") == "true"){
                          CommonUtil.SELECT_EQUIP_ID = params.get('equipId') as string
                          //CommonUtil.EQUIP_RESERVE_ASSET_TYPE = params.get('assetType') as string
                          CommonUtil.SELECT_EQUIPMENT_TYPE = params.get('equipType') as string
                          CommonUtil.EQUIP_RESERVE_IS_GENERIC = params.get('isGeneric') as string
                          CommonUtil.SELECT_EQUIP_AVAIL_ID = params.get('avaiId') as string
                          navigate("/equippublic/?"+path);
                      }else{
                          CommonUtil.addScreensToLastAccessed(path);
                          navigate("/menu/?"+path);
                      }

                  }else if(params.get('requestor') == "custprofiles"){
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "showReservation"){
                      navigate("/menu/?"+path);
                  }else if(params.get('requestor') == "reservation"){
                      CommonUtil.addScreensToLastAccessed(path);
                      navigate("/reservation/?"+path);
                  }else if(params.get('requestor') == "createEquip"){
                      CommonUtil.addScreensToLastAccessed(path);
                      navigate("/menu/?"+path);
                  }else if(sessionStorage.getItem("LAS")){

                  }
              } else{
                  let locPath = (sessionStorage.getItem("locPath")) as string;
                  console.log(locPath);
                  if(locPath && locPath.length>0 && (locPath.indexOf("/equipment/")>=0 || locPath.indexOf("/reservation/")>=0 || locPath.indexOf("/offers/")>=0)){
                      sessionStorage.setItem("locPath","");
                      if(locPath.indexOf("/equipment/")>=0){
                          let pathArray = locPath.split("/equipment/");
                          if(pathArray && pathArray.length>1){
                              localStorage.setItem("listId",pathArray[1]);
                              navigate("/listpublic");
                          }
                      }else if(locPath.indexOf("/reservation/")>=0){
                          let pathArray = locPath.split("/reservation/");
                          console.log(pathArray)
                          if(pathArray && pathArray.length>1){
                              localStorage.setItem("listId",pathArray[1]);
                              localStorage.setItem("reservationview","true");
                              if(pathArray[0] === "/claim"){
                                localStorage.setItem("claimsView","true");
                              }
                            //   navigate("/listreservation"); Need to validate with LoadBoardReservation
                              navigate(locPath); // tinyURL refresh fix
                          }
                      }else if(locPath.indexOf("/offers/")>=0){
                        let pathArray = locPath.split("=");
                        console.log(pathArray)
                        let index = pathArray[0].lastIndexOf("/")
                        let endStr = pathArray[0].substring(index+1,pathArray[0].length)
                        let finalAttr = endStr.split("?")[0]
                        console.log(finalAttr)
                          if(pathArray && pathArray.length>1 && finalAttr){
                              localStorage.setItem("listId",pathArray[1]);
                              localStorage.setItem("offerView","true")
                              localStorage.setItem("offerId",finalAttr)
                                navigate("/offerOwnerView");
                          }
                      }
                  }else{
                    const locPath = (sessionStorage.getItem("locPath")) as string;
                      let requestor:string = sessionStorage.getItem("LAS1") as string;
                      console.log(requestor);
                      if(requestor){
                          if(requestor.indexOf("signup")>=0){
                              sessionStorage.setItem("LAS1","");
                              CommonUtil.addScreensToLastAccessed("signup");
                              navigate("/signup");
                          }else if(requestor.indexOf("menu")>=0){
                              sessionStorage.setItem("LAS1","");
                              CommonUtil.addScreensToLastAccessed("menu");
                              navigate("/menu");
                          }else if(requestor.indexOf("searchdb")>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.EQUIPMENTS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.EQUIP_REPORT)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.ORGANIZATIONS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.AVAILABILITIES)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.OFFERS_RECEIVED)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.OFFERS_SENT)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.RENTER_RESERVATIONS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.OWNER_RESERVATIONS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.ADMIN_RESERVATIONS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.DRIVER)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.PARKING_LOT)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.MASTER_DATA)>=0){
                              navigate("/menu");
                          } else if(requestor.indexOf(Constants.INSPECTION_COMPANY)>=0){
                              navigate("/menu")
                          }else if(requestor.indexOf(Events.FAVORITIES)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.CUSTOMER_PROFILES)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.API_HUB)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.INTERCHANGE_APPLICATION)>=0){
                            navigate("/menu");
                        }else if(requestor.indexOf(Constants.INSURANCE_PLANS)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.INSURANCE1_PLANS_RATE)>=0){
                              navigate("/menu");
                          }else if(requestor.indexOf(Constants.CONSOLIDATED_INVOICE)>=0){
                            CommonUtil.addScreensToLastAccessed(requestor);
                            navigate(locPath);
                        }else if(requestor.indexOf(Constants.CONSOLIDATED_STATEMENT)>=0){
                            CommonUtil.addScreensToLastAccessed(requestor);
                            navigate(locPath);
                        }else if(requestor.indexOf("equipview")>=0){
                              CommonUtil.addScreensToLastAccessed(requestor);
                              localStorage.setItem("ViewTab","true");
                              navigate("/menu/?"+requestor);
                          }else if(requestor.indexOf("equippublicview")>=0){
                              CommonUtil.addScreensToLastAccessed(requestor);
                              localStorage.setItem("PublicViewTab","true");
                              navigate("/menu/?"+requestor);
                          }else if(requestor.indexOf("viewreservation")>=0){
                            CommonUtil.addScreensToLastAccessed(requestor);
                            localStorage.setItem("ReservationTab","true");
                            navigate(locPath);
                            }else if(requestor.indexOf("reservationview")>=0){
                              CommonUtil.addScreensToLastAccessed(requestor);
                              localStorage.setItem("ReservationTab","true");
                              navigate("/menu/?"+requestor);
                          }else if(requestor.indexOf("billingdoc")>=0){
                              CommonUtil.addScreensToLastAccessed(requestor);
                              localStorage.setItem("BillingTab","true");
                              navigate("/menu/?"+requestor);
                          }else if(requestor.indexOf("reservation")>=0){
                            CommonUtil.addScreensToLastAccessed(requestor);
                            localStorage.setItem("processView","true");
                            navigate("/reservation/?"+requestor);
                        }

                      } else {
                          console.log(localStorage.getItem("userId"));
                          if(localStorage.getItem("userId")){
                              localStorage.setItem("ViewDB", "true");
                              CommonUtil.LOGIN_USER_ID = localStorage.getItem("userId") || "";
                              CommonUtil.addScreensToLastAccessed("menu");
                              navigate("/menu");
                          }
                      }
                  }

              }

            }
          },
          error: function () {},
        })
      }

    return (
        <div className="App">
            <BusyLoader/>
            <Confirm/>
            <ToastManager/>
            <div style={{display: (routeVisible ? "" : "none")}}>
                <Routes>
                    <Route path="/" element={<LoginPage/>}></Route>
                    <Route path="/login" element={<LoginPageModal />}></Route>
                    <Route path="/menu/*" element={<MenuPage/>}></Route>
                    <Route path="/signup" element={<SignUpPage/>}></Route>
                    <Route path="forgetPassword/*" element={<ForgetPassword/>}></Route>
                    <Route path="register" element={<RegistrationPage/>}></Route>
                    <Route path="vhubreg" element={<RegistrationPage/>}></Route>
                    <Route path="regsignup" element={<SignupRegistration/>}></Route>
                    <Route path="regreset" element={<ForgotPasswordReg/>}></Route>
                    <Route path="reset" element={<ResetPage/>}></Route>
                    <Route path="searchdashboard" element={<PublicSearchDashboard/>}></Route>
                    <Route path="equippublic/viewreservation" element={<ReservationViewCopy />}></Route>
                    <Route path="equippublic" element={< EquipPublicView/>}></Route>
                    <Route path="listpublic" element={< LoadBoardListingView/>}></Route>
                    <Route path="listreservation" element={< LoadBoardReservationView/>}></Route>
                    <Route path="listpublic/viewreservation" element={<ReservationViewCopy />}></Route>
                    <Route path="reservation" element={<ProcessReservations/>}></Route>
                    <Route path="reservation/processview" element={<ReservationProcessView/>}></Route>
                    <Route path="offerOwnerView" element={<OfferOwnerView/>}></Route>
                    <Route path="offerRenterView" element={<OfferRenterView/>}></Route>
                    

                </Routes>
            </div>
        </div>
    )
}

export default App
