import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import EquipmentAvail from "./EquipmentAvail";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import EquipmentPrices from "./EquipmentPrices";
import EquipmentDocuments from "./EquipmentDocuments";
import EquipmentDamages from "./EquipmentDamages";

import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {useNavigate} from "react-router-dom";
import EquipmentReservation from "./EquipmentReservation";
import EquipmentHistory from "./EquipmentHistory";
import ReservationDetails from "./ReservationDetails";
import ReserveActivation from "./ReserveActivation";
import ReserveDeActivation from "./ReserveDeActivation";
import ReserveClaims from "./ReserveClaims";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Modal} from "react-bootstrap";
import ReservationHistory from "./ReservationHistory";
import {Constants} from "../Languages/Constants";
import PhoneInput from "react-phone-input-2";
import StarRating from "../../Components/Rating";
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import DetectDevice from "../../utils/DetectDevice";
import Confirm from "../../Components/confirm";
import EquipGalleryModal from "./EquipGalleryModal";
import AGGrid from "../../Components/AGGrid";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import {Events} from "../Languages/Events";
import CarrierDetails from "./CarrierDetails";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
var policyId = "";
var reservationStatus:string = "";
var assignEquipId = "";
let reservationEquipId:string = "";
let reservationPublicView:boolean = false;

const ReservationView = (props:any)=>{
    const {isMobile} = DetectDevice();
    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);
    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [reservationData,setReservationData] = useState<any>(null);
    const [equipmentList,setEquipmentList] = useState<any>([]);
    const [genInfo,setGenInfo] = useState(true);
    const [docs,setDocs] = useState(false);
    const [equipments,setEquipments] = useState(false);
    const [activation,setActivation] = useState(false);
    const [deactivation,setDeactivation] = useState(false);
    const [claims,setClaims] = useState(false);
    const [ehistory,setEHistory] = useState(false);
    const [carrierDetails,setCarrierDetails] = useState(false);

    const [driver,setDriver] = useState("");
    const [ownerDriver,setOwnerDriver] = useState("");
    const [reservationCode,setReservationCode] = useState("");
    const [listingType,setListingType] = useState("");
    const [processUrl,setProcessUrl] = useState("");

    const [activationDate, setActivationDate] = useState("");
    const [deActivationDate, setDeActivationDate] = useState("");


    const [equipmentData,setEquipmentData] = useState<any>(null);

    const [showVINPopup,setShowVINPopup] = useState(false);
    const [equipList,setEquipList] = useState<any>([]);
    const [equipObj,setEquipObj] = useState<any>(null);

    const [assetType,setAssetType] = useState("");
    const [equipType,setEquipType] = useState("");
    const [unitNumber,setUnitNumber] = useState("");
    const [vinNumber,setVinNumber] = useState("");

    const [rvinNumber,setRVinNumber] = useState("");
    const [isVinAvailable,setVinAvailable] = useState(false);

    const [isDisplayAssignVin,setIsAssignVin] = useState(false);


    const [brand,setBrand] = useState("");
    const [model,setModel] = useState("");
    const [year,setYear] = useState("");
    const [equipName,setEquipName] = useState("");

    const [numOfAxes,setNumOfAxes] = useState("");
    const [trailerLength,setTrailerLength] = useState("");
    const [license,setELicense] = useState("");
    const [expDate,setExpDate] = useState("");

    const [axelSpread,setAxelSpread] = useState("");
    const [tireInflation,setTireInflation] = useState("");
    const [skirts,setSkirts] = useState("");
    const [pintleHook,setPintleHook] = useState("");
    const [hazmat,setHazmat] = useState("");
    const [suspension,setSuspension] = useState("");

    const [rearDoor,setRearDoor] = useState("");
    const [sideDoor,setSideDoor] = useState("");
    const [roof,setRoof] = useState("");
    const [floor,setFloor] = useState("");
    const [floorType,setFloorType] = useState("");
    const [interior,setInterior] = useState("");
    const [logistics,setLogistics] = useState("");
    const [deckBeams,setDeckBeams] = useState("");

    const [liftType,setLiftType] = useState("");
    const [lift,setLift] = useState("");
    const [liftCap,setLiftCap] = useState("");

    const [unitCapacity,setUnitCapacity] = useState("");
    const [unitMake,setUnitMake] = useState("");
    const [unitModal,setUnitModal] = useState("");
    const [unitYear,setUnitYear] = useState("");
    const [multiTemp,setMultiTemp] = useState("");

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [emails,setEmails] = useState("");
    const [deposit,setDeposit] = useState("");
    const [imagePath,setImagePath] = useState("");

    const [showDriverPopup,setShowDriverPopup] = useState(false);
    const [driverList,setDriverList] = useState<any>([]);
    const [driverObj,setDriverObj] = useState<any>(null);
    const [driverName,setDriverName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneClass, setPhoneClass] = useState('mandatory');

    const [email, setEmail] = useState('');
    const [truckNumber, setTruckNumber] = useState('');
    const[carrierName,setCarrierName] = useState("");
    const[usdotNumber,setUSDOTNumber] = useState("");
    const [driverStatus, setDriverStatus] = useState('Active');
    const [status, setStatus] = useState('Active');

    const [ratingCount,setRatingCount] = useState("");
    const [overAllRate,setOverAllRate] = useState("");

    const [policyPerDayAmount,setPolicyPerdayAmount] = useState("");
    const [policyName,setPolicyName] = useState("");

    const [isDriverVisible,setDriverVisible] = useState(false);

    const [imageIdx,setImageIdx] = useState(0);
    const [gallary,setGallary] = useState([]);
    const [imageEquipPath,setEquipImagePath] = useState("");
    const [modalTitle,setModalTile] = useState("");

    const [imageModalShow,setImageMoalShow] = useState(false);
    const [isRenter,setIsRenter] = useState(false);
    const [renterOrg,setRenterOrg] = useState(null);
    const [page,setPage] = useState("");
    const [equipGeneric,setEquipGeneric] = useState("");



    const genRef = useRef<HTMLButtonElement>(null);
    const avlRef = useRef<HTMLButtonElement>(null);
    const priceRef = useRef<HTMLButtonElement>(null);
    const docRef = useRef<HTMLButtonElement>(null);
    const damageRef = useRef<HTMLButtonElement>(null);
    const reserveRef = useRef<HTMLButtonElement>(null);
    const historyRef = useRef<HTMLButtonElement>(null);

    const chkAddNewDriver = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const gridRef = useRef<IAGrid>(null);

    const [vinColumnDefs] = useState([
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN,"VIN"), flex:1,resizable: true, width: 150, sortable: true,  filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), flex:1,resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUPMENT_TYPE,"Eqipment Type"), flex:1,resizable: true, width: 150, sortable: true, filter: 'equipmentType', suppressToolPanel: true,  hide: true,},
        { field: 'licencePlateNumber', headerName: CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO,"License Plate Number"), flex:1,resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
    ])

    let deSelectButtons = ()=>{
        setGenInfo(false);
        setDocs(false);
        setEquipments(false);
        setActivation(false);
        setDeactivation(false);
        setClaims(false);
        setEHistory(false);
        setCarrierDetails(false);
    }

    /*let onClickGenInfo = ()=>{
        deSelectButtons();
        setGenInfo(true);
        genRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickAvailability = ()=>{
        deSelectButtons();
        //setAvailability(true);
        avlRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickPrice = ()=>{
        deSelectButtons();
        //setPrices(true);
        priceRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDocs = ()=>{
        deSelectButtons();
        setDocs(true);
        docRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDamages = ()=>{
        deSelectButtons();
        //setDamages(true);
        damageRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickReservations = ()=>{
        deSelectButtons();
       // setReservations(true);
        reserveRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickHistory = ()=>{
        deSelectButtons();
        //setEHistory(true);
        historyRef.current?.classList.add("equi-view-select-btn");
    }*/

    useEffect(()=>{
        console.log(localStorage.getItem("reservationview"));
        if(localStorage.getItem("reservationview") == "true") {
            if(CommonUtil.getSelectedOrgId()){
                getPublicReservationDetails();
            }
        }
        setTimeout(()=>{
            localStorage.setItem("reservationview","");
        },2000);
    },[props.isLogin]);

    useEffect(()=>{
        //console.log(CommonUtil.SELECT_EQUIP_ID);
        //getEquipmentDetails();
        //CommonUtil.SELECT_EQUIP_ID = "32";
        //CommonUtil.SELECT_RESERVATION_ID = "5"
        assignEquipId = "";
        policyId = "";
        reAdjust();
        if(localStorage.getItem("reservationview") == "true"){
            if(CommonUtil.getSelectedOrgId()){
                getPublicReservationDetails();
            }
        }else{
            if(reservationEquipId == ""){
                reservationEquipId = CommonUtil.SELECT_EQUIP_ID;
            }else{
                CommonUtil.SELECT_EQUIP_ID = reservationEquipId;
            }

            getReservationDetails();
            
        }
        setTimeout(()=>{
            localStorage.setItem("reservationview","");
        },2000);
        //localStorage.setItem("reservationview","");

       // getAllDrivers();
        window.addEventListener('resize',function(e){
            reAdjust();
        })
        if(CommonUtil.SCREEN_TYPE == CommonUtil.RESERVATION_SCREEN_TYPE) {
            if (localStorage.getItem("reservation") == "true") {
                onClickShowVIN();
                localStorage.removeItem("reservation");
            }
        }
       // document.addEventListener(Events.SHOW_AVAILABILITY, onSaveEquipment);
    return () => {
        document.removeEventListener(Events.SHOW_AVAILABILITY, onSaveEquipment);
      window.removeEventListener('resize', function(){
                reAdjust();
            });
    }
    },[])


    let getPublicReservationDetails = ()=>{
        reservationPublicView = true
        let revId = localStorage.getItem("listId");
        console.log(revId);
        try{
            CommonUtil.ORGANIZATION_TYPE = "OWNER";//JSON.parse(String(localStorage.getItem('orgType')));
        }
        catch(e){}

        let reservationAPI:string = "api/session/reservation/"+revId+ "&saasOrgId=" + CommonUtil.getSaasOrgId();;//CommonUtil.SELECT_RESERVATION_ID;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,(dataObj:any)=>{
            console.log(dataObj);
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj)
                if (restData) {
                    CommonUtil.SELECT_RESERVATION_ID = restData.reservationId;
                    CommonUtil.SELECT_EQUIP_ID = restData.equipmentId;
                    console.log(CommonUtil.getSelectedOrgId());                    
                    if(CommonUtil.getSelectedOrgId()){
                        reservationEquipId = CommonUtil.SELECT_EQUIP_ID;
                        getEquipmentDetails();
                        onGetReservationDetails(dataObj);
                    }

                }
            }
        },onLoginError);
    }
    let getReservationDetails = ()=>{
        let requestor:string = sessionStorage.getItem("LAS") as string;
        const match = requestor.match(/reservationId=(\d+)/);
        const reservationId = match ? match[1] : null;
        let reservationAPI: string = `api/reservation/${CommonUtil.SELECT_RESERVATION_ID !== null ? CommonUtil.SELECT_RESERVATION_ID : reservationId}`;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetReservationDetails,onLoginError);
    }

    let onGetReservationDetails = (dataObj:any)=>{
        console.log("data",dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                CommonUtil.SELECT_RESERVATION_ID = restData.reservationId;
                reservationStatus = restData.reservationStatus;
                reservationEquipId = CommonUtil.SELECT_EQUIP_ID = restData.equipmentId;
               setReservationData(restData);
               setReservationCode(restData.reservationCode?restData.reservationCode:"-");
              // setDriver(restData.driverName?restData.driverName:"");
                if(restData.driver && restData.driver.driverName){
                    setDriver(restData.driver.driverName?restData.driver.driverName:"");
                }else{
                    setOwnerDriver(restData?.carrierInformation?.driverNameInfo || "");
                }

                setRVinNumber(restData.vin?restData.vin:"");
                setVinAvailable(restData.vin?true:false);
                setProcessUrl(restData.processURL?restData.processURL:"");
                setListingType(restData.listingType?restData.listingType:"");
                setRenterOrg(restData.renterOrgId);

                let msg = "";
                if(restData.isEquipmentGeneric == "N"){
                    msg = "Specific";
                }else{
                    msg = "Generic";
                }
                setEquipGeneric(msg);

                if(restData.listingEquipmentId == restData.equipmentId){
                    if(reservationPublicView && (restData.vin === "" || restData.vin === null)){
                        setIsAssignVin(true); 
                    }
                    else{
                        setIsAssignVin(false);
                    }
                }else{
                    setIsAssignVin(true);
                }

                // if(restData.vin === "" || restData.vin === null){
                //     setIsAssignVin(true);
                // }else{
                //     setIsAssignVin(false);
                // }

                if(restData.renterOrgId == CommonUtil.getSelectedOrgId()){
                    setIsAssignVin(false);
                    setIsRenter(true);
                }

                if(reservationStatus == CommonUtil.RESERVATION_DELETED || reservationStatus == CommonUtil.RESERVATION_INPROGRESS || reservationStatus == CommonUtil.RESERVATION_COMPLETED || reservationStatus == CommonUtil.RESERVATION_DE_ACTIVATION_DUE){
                    setIsAssignVin(false);
                }

                setActivationDate(restData.activationDate);
                setDeActivationDate(restData.deactivationDate);
                policyId = restData.policyId;
                setPolicyName(restData.policyName);
                setPolicyPerdayAmount(restData.insurancePerDayAmount);

                //console.log(CommonUtil.ORGANIZATION_TYPE);
                if(restData.saasOrgId == CommonUtil.getSaasOrgId()){
                    if(restData?.isReservationCreatedThroughAvailbility && CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER){ 
                        $("#btnDriver").show();
                    }else{
                        $("#btnDriver").hide();
                    }
                }else{
                    $("#btnDriver").show();
                    //setDriverVisible(true);
                }
                if(reservationStatus == CommonUtil.RESERVATION_DELETED || reservationStatus == CommonUtil.RESERVATION_COMPLETED){
                    $("#btnDriver").hide();
                }

                if(restData.deactivationDate){
                    $("#btnDriver").hide();
                    $("#spnVIN").hide();
                    //setDriverVisible(false);
                }
                if(restData.activationDate){
                    setIsAssignVin(false);
                }

                console.log(CommonUtil.ORGANIZATION_TYPE, "venkart",restData.saasOrgId,CommonUtil.getSaasOrgId());

                $("#btnReservationCancel").hide();

                if(reservationStatus == CommonUtil.RESERVATION_UPCOMING || reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                    $("#btnReservationCancel").show();
                }
                /*if(restData.saasOrgId == CommonUtil.getSaasOrgId()){
                    if((CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)){
                        if(reservationStatus == CommonUtil.RESERVATION_UPCOMING || reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                            $("#btnReservationCancel").show();
                        }
                    }
                }*/

                //|| reservationStatus == CommonUtil.RESERVATION_INPROGRESS

               // $("#btnReservationCancel").hide();
                /*if(!restData.activationDate && (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)){
                    $("#btnReservationCancel").show();
                }else if(reservationStatus == "Deleted"){
                    $("#btnReservationCancel").hide();
                }else if(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER){
                    $("#btnReservationCancel").hide();
                }else if(restData.activationDate && !restData.deactivationDate && (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)){
                    $("#btnReservationCancel").show();
                }else if((reservationStatus == CommonUtil.RESERVATION_UPCOMING || reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE || reservationStatus == CommonUtil.RESERVATION_INPROGRESS){
                    $("#btnReservationCancel").show();
                }*/
                if(localStorage.getItem("claimsView")=== "true")
                {
                    onClickTabs('lnkClaims')
                    
                }
                getEquipmentDetails();
            }
        }
    }

    let getEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        if(reservationEquipId){
            let equipmentAPI:string = `api/equipment/${reservationEquipId}`;
            console.log(equipmentAPI);
            let obj:any = {};
            NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
        }
    }

    let onGetEquipmentDetails = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                    CommonUtil.IS_GENERIC_EQUIP = (restData.isGeneric == "Y"?true:false);
                    setRatingCount(restData.ratingCount?restData.ratingCount:"");
                    setOverAllRate(restData.overallRating?restData.overallRating:"");
                    setEquipmentData(restData);
                    setEquipType(restData.equipmentType?restData.equipmentType:"-");
                    setEquipName(restData.genericEquipmentName?restData.genericEquipmentName:"-");
                    setUnitNumber(restData.unitNumber?restData.unitNumber:"-");
                    setVinNumber(restData.VIN?restData.VIN:"-");

                    setYear(restData.year?restData.year:"-");
                    setModel(restData.model?restData.model:"-");
                    setBrand(restData.brand?restData.brand:"-");
                    setNumOfAxes(restData.numberOfAxles?restData.numberOfAxles:"-");
                    setTrailerLength(restData.lengthOfTrailer?restData.lengthOfTrailer:"-");
                    setELicense(restData.licensePlateNumber?restData.licensePlateNumber:"-");
                    setExpDate(restData.expDate?restData.expDate:"-");
                    setAssetType(restData.assetType?restData.assetType:"-");
                    setExpDate(restData.annualMechanicalInspExpDate?restData.annualMechanicalInspExpDate:"-");

                    setActivative(restData.activationInstructions?restData.activationInstructions:"-");
                    setDeActive(restData.deactivationInstructions?restData.deactivationInstructions:"-");

                    setEmails(restData.additionalEmailIds?restData.additionalEmailIds:"-");
                    setDeposit(restData.securityDeposit?restData.securityDeposit:"-");

                    if(restData.equipmentType == "DRY VAN" ){
                        setImagePath("../../images/dryVan.png");
                    }else if(restData.equipmentType == "PLATE DRY VAN" ){
                         setImagePath("../../images/plateDryVan1.png");
                     }

                    //console.log(CommonUtil.getEquipemntImage(restData.equipmentType));
                    //console.log(CommonUtil.SELECT_EQUIPMENT_TYPE);
                     setImagePath(CommonUtil.getEquipemntImage(restData.equipmentType));
               // getEquipemntImage

                    if(restData.specifications){
                        if(restData.specifications.general){
                            setAxelSpread(restData.specifications.general.axleSpread?restData.specifications.general.axleSpread:"-");
                            setTireInflation(restData.specifications.general.tireInflationSystem?restData.specifications.general.tireInflationSystem:"-");
                            setSkirts(restData.specifications.general.skirts?restData.specifications.general.skirts:"-");
                            setPintleHook(restData.specifications.general.pintleHook?restData.specifications.general.pintleHook:"-");
                            setHazmat(restData.specifications.general.hazmatPlacardHolder?restData.specifications.general.hazmatPlacardHolder:"-");
                            setSuspension(restData.specifications.general.suspensionType?restData.specifications.general.suspensionType:"-");
                        }
                        if(restData.specifications.trailer){
                            setRearDoor(restData.specifications.trailer.rearDoor?restData.specifications.trailer.rearDoor:"-");
                            setSideDoor(restData.specifications.trailer.sideDoor?restData.specifications.trailer.sideDoor:"-");
                            setRoof(restData.specifications.trailer.roof?restData.specifications.trailer.roof:"-");
                            setFloor(restData.specifications.trailer.floor?restData.specifications.trailer.floor:"-");
                            setFloorType(restData.specifications.trailer.floorType?restData.specifications.trailer.floorType:"-");
                            setInterior(restData.specifications.trailer.interiorFinish?restData.specifications.trailer.interiorFinish:"-");
                            setLogistics(restData.specifications.trailer.logisticPostsDistance?restData.specifications.trailer.logisticPostsDistance:"-");
                            setDeckBeams(restData.specifications.trailer.doubleDeckBeams?restData.specifications.trailer.doubleDeckBeams:"-");
                        }
                        if (restData.specifications.lift) {
                            setLift(restData.specifications.lift.lift);
                            setLiftType(restData.specifications.lift.liftType);
                            setLiftCap(restData.specifications.lift.liftCapacity);
                        }

                        if (restData.specifications.reefer) {
                            setMultiTemp(restData.specifications.reefer.multiTemperature);
                            setUnitModal(restData.specifications.reefer.unitMake);
                            setUnitMake(restData.specifications.reefer.unitModel);
                            setUnitYear(restData.specifications.reefer.unitYear);
                        }
                    }

                if (restData.attachments) {
                    attachmentList = []
                    if (Array.isArray(restData.attachments)) {
                        attachmentList = restData.attachments
                    } else {
                        attachmentList.push(restData.attachments)
                    }
                    if (attachmentList.length > 0) {
                        BusyLoader.showLoader()
                        showAttachments()
                    }
                }
                }
            }
        }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {}
                    obj.Type = attachmentList[attchIdx].documentType
                    obj.Path = dataObj
                    obj.attachementId = attachmentList[attchIdx].attachementId
                    equiImages.push(obj)
                    attchIdx = attchIdx + 1;
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }



    let onClickTabs = (divTag:string)=>{
        let tabs:any = ['lnkDetails','lnkEquipment','lnkActivation','lnkDeactivation','lnkCarrierDetails','lnkDocuments','lnkClaims','lnkHistory'];
        let tabScreens:any = ['pnlDetails','pnlEquipment','pnlActivation','pnlDeactivation','pnlCarrierDetails','pnlDocuments','pnlClaims', 'pnlHistory'];

        tabs.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("active");
        });
        tabScreens.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("show active");
            $("#"+tab).addClass("fade");
        });

        $("#"+divTag).addClass("active");
        let tabScreen:string = "pnlDetails";

        if(divTag == "lnkDetails"){
           deSelectButtons();
           setGenInfo(true);
            tabScreen = 'pnlDetails';
        }else if(divTag == "lnkEquipment"){
            deSelectButtons();
            setEquipments(true);
            tabScreen = 'pnlEquipment';
        }else if(divTag == "lnkActivation"){
            deSelectButtons();
           setActivation(true);
            tabScreen = 'pnlActivation';
        }else if(divTag == "lnkDocuments"){
            deSelectButtons();
            setDocs(true);
            tabScreen = 'pnlDocuments';
        }else if(divTag == "lnkDeactivation"){
            deSelectButtons();
           setDeactivation(true);
            tabScreen = 'pnlDeactivation';
        }else if(divTag == "lnkClaims"){
            deSelectButtons();
            setClaims(true);
            tabScreen = 'pnlClaims';
        }else if(divTag == "lnkHistory"){
            deSelectButtons();
            setEHistory(true);
            tabScreen = 'pnlHistory';
        }else if(divTag == "lnkCarrierDetails"){
            deSelectButtons();
            setCarrierDetails(true);
            tabScreen = 'pnlCarrierDetails';
        }

        $("#"+tabScreen).addClass("show active");
    }

    let onEquipChange = (dataObj:any)=>{
        setEquipObj(dataObj);
    }

    let onCloseDriverPopup = ()=>{
        setShowDriverPopup(false);
    }

    let onDriverChange = (data:any)=>{
        setDriverObj(data);
    }

    let onClickAssignDriver = ()=>{
        if(CommonUtil.getSelectedOrgId()){
            getAllDrivers();
            setShowDriverPopup(true);
        }
    }

    let onClickSaveDriver = ()=>{
        if(driverObj){
            let qParam:string = "driverId="+driverObj.driverId+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
            let assignAPI:string = "api/reservation/assignDriver?"+qParam;

            NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignDriver, onLoginError);
        }
    }

    let onGetAssignDriver = (dataObj:any)=>{
        //console.log(dataObj);
        showHideDriverPanel();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strSriver = CommonUtil.getMessageText(Constants.ASSIGN_DRIVER_SUCCESS,'Assign driver to reservation Successfully');
                ToastManager.showToast(strSriver, 'Info');
                getReservationDetails();
                onCloseDriverPopup();
                setDriver(restData.driverName);
                setProcessUrl(restData.processURL?restData.processURL:"");
            }
        }
    }

    let onCloseVINPopup = ()=>{
        setShowVINPopup(false);
    }

    let onClickShowVIN = ()=>{
      //  if(!isVinAvailable){
            setShowVINPopup(true);            
        //}
    }
    useEffect(()=>{
        if(showVINPopup && reservationCode){
            getEquipmentList();
        }
    },[showVINPopup,reservationCode])

    let getEquipmentList = ()=>{
        let equipAllAPI: string = `api/equipment/available?saasOrgId=${CommonUtil.getSaasOrgId()}&reservationCode=${reservationCode}`;
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips:any = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                setEquipmentList(equips);
                //setEquipData(equips);
            }
        }
    }

    let onClickSaveVIN = ()=>{
        if(gridRef.current){
            let selRow = gridRef.current.getSelectedRows();
            console.log(selRow);
            if(selRow && selRow[0]){
                assignEquipId = selRow[0].key;
                let qParam:string = "equipmentId="+assignEquipId+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
                let assignAPI:string = "api/reservation/assignEquipment?"+qParam;

                NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignEquipment, onLoginError);
            }
        }

        /*if(equipObj){
            assignEquipId = equipObj.key;
            let qParam:string = "equipmentId="+equipObj.key+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
            let assignAPI:string = "api/reservation/assignEquipment?"+qParam;

            NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignEquipment, onLoginError);
        }*/
    }

    let onGetAssignEquipment = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setRVinNumber(restData.vin);
                setVinAvailable(restData.vin?true:false);
                let strEquip = CommonUtil.getMessageText(Constants.ASSIGN_EQUIPMENT_SUCCESS,'Assign Equipment to reservation Successfully');
                ToastManager.showToast(strEquip, 'Info');
                setShowVINPopup(false);
                setPage(""+Math.random()*100000);
                getAssignedEquipmentDetails();
            }
        }
    }

    let getAssignedEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        if(assignEquipId){
            let equipmentAPI:string = "api/equipment/"+assignEquipId;
            let obj:any = {};
            NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
        }
    }

    let onClickBill = ()=>{
        navigate('invoice');
    }

    let printPDF = async () => {
        if(CommonUtil.getSelectedOrgId()){
            var myHeaders = new Headers()
            myHeaders.append('Content-Type', 'application/json')
            if (localStorage.getItem('token')) {
                var token = 'Bearer ' + localStorage.getItem('token')
                myHeaders.append('Authorization', token);
            }
            await fetch(NetworkManager.serverPath + `api/download/reservationInvoice?reservationCode=${reservationCode}&saasOrgId=${CommonUtil.getSaasOrgId()}`, {method:'GET', headers:myHeaders })
                .then((response:any) => {
                    response.blob().then((blob:any) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `reservation_${reservationCode}.pdf`;
                        a.click();
                    });

                }).catch((error)=>{})
        }
    }

    let onChangeAddDriver = ()=>{
        showHideDriverPanel();
    }
    const handleInputName = (e: any) => {
        setDriverName(e.target.value);
        CommonUtil.isMandatory(nameRef);
    }
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e);
        setPhoneClass('mandatory');
    }
    const handleInputEmail = (e: any) => {
        setEmail(e.target.value);
        let flag:boolean = CommonUtil.isValidEmail(e.target.value);
        if(flag){
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        }else{
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }

    }

    const handleInputNumber=(e: any)=>{
        setTruckNumber(e.target.value)
     }

    let handleCarrierName = (e:any)=>{
        setCarrierName(e.target.value);
    }

    let handleUSDOTNumber = (e:any)=>{
        setUSDOTNumber(e.target.value);
    }


    let getAllDrivers = () => {
        // let qParam = CommonUtil.getSaasOrgId();
        let orgUrl = "api/driver/all?saasOrgId=" + renterOrg;
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDrivers, onLoginError);
    }

    let onGetAllDrivers = (data: any) => {
        if (data) {
            let restData = CommonUtil.parseData(data);
            if (restData) {
                let drivers = [];
                if(Array.isArray(restData)){
                    drivers = restData;
                }else{
                    drivers.push(restData);
                }
                setDriverList(drivers);
            }
        }
    }

    let onCreateDriver = ()=>{
        let isName: boolean = CommonUtil.isMandatory(nameRef);
        let isPhone: boolean = true;
        let isEmail: boolean = true;
        if (email && email.length > 0){
            isEmail = CommonUtil.isValidEmail(email);
        }
        if(!phoneNumber.trim() || phoneNumber.length < 11){
            isPhone = false
            setPhoneClass('is-invalid')
        }
        if( isName && isEmail && isPhone) {
            let newData = {name:driverName.trimStart().trimEnd(), phoneNumber, status, email, truckNumber,carrierName,usdot:usdotNumber}
            let orgUrl = "api/driver/?saasOrgId="+ renterOrg;
            BusyLoader.showLoader();
            NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", false, onSuccess, onLoginError);
        }
    }

    let onSuccess = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                //console.log(restData);
                if(chkAddNewDriver.current){
                    chkAddNewDriver.current.checked = false;
                }
                BusyLoader.hideLoader();
                let qParam:string = "driverId="+restData.driverId+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
                let assignAPI:string = "api/reservation/assignDriver?"+qParam;

                NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignDriver, onLoginError);
                /*setDriverObj({driverId:restData.driverId,name:restData.name});
                setTimeout(()=>{
                    BusyLoader.hideLoader();
                    onClickSaveDriver();
                },1000)*/

               // ToastManager.showToast('Driver Created Successfully', 'Info');
                //setDriverObj({driverId:restData.driverId,name:restData.name});
                //getAllDrivers();
            }
        }
    }

    let showHideDriverPanel = ()=>{
        setPhoneNumber("");
        setDriverName("");
        setEmail("");
        setTruckNumber("");
        $("#btnSave").show();
        $("#btnSaveAssign").hide();
        $("#divDriverPanel").hide();
        if(chkAddNewDriver.current){
            if(chkAddNewDriver.current.checked){
                $("#divDriverPanel").show();
                $("#btnSaveAssign").show();
                $("#btnSave").hide();
            }
        }

    }

    let onClickCancelReservation = ()=>{
        if(CommonUtil.getSelectedOrgId()){
            Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,"Confirm"), CommonUtil.getMessageText(Constants.DO_U_WANT_CANCEL_RESERVATION,"Are you sure. Do you want Cancel Reservation?"), [{label:"Yes", callback:function(){
                    if(CommonUtil.SELECT_RESERVATION_ID){
                        let removeAPI:string = "api/reservation/cancel/"+CommonUtil.SELECT_RESERVATION_ID;
                        console.log(removeAPI);
                        NetworkManager.sendJsonRequest(removeAPI,"","","POST",true,onCancelReservation,onLoginError);
                    }
                }}, {label:"No"}]);
        }
    }

    let onCancelReservation = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                $("#btnReservationCancel").hide();
                reservationStatus = restData.reservationStatus;
                setReservationData(restData);
                let strCancel = CommonUtil.getMessageText(Constants.RESERVATION_CANCEL_SUCCESS, 'Reservation Cancelled Successfully');
                ToastManager.showToast(strCancel, 'Info');
            }
        }
    }

    let onClickEquipImage = (mTile:string,images:any,imgPath:string,idx:number,catagory?:string)=>{
        // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
        // setGallary(images);
        if(images.length == 0){
            setEquipImagePath(imgPath);
        }else{
            setImageIdx(idx);
        }
        let equipImages:any = [];

        images.forEach((item:any,eIdx:number)=>{
            if(item && item.Type == catagory){
                equipImages.push(item)
            }
        });
        for(let i=0;i<equipImages.length;i++){
            if(equipImages[i].Path == imgPath){
                idx =  i;
                break;
            }
        }
        setModalTile(mTile);
        setGallary(equipImages);

        setImageMoalShow(true);
    }

    let onCloseImagePopup = ()=>{
        setImageMoalShow(false);
    }

    let onClickAddEquipment = ()=>{
        CommonUtil.IS_GENERIC_EQUIP = false;
        CommonUtil.SELECT_EQUIP_ID = "";
       // localStorage.setItem("IsGeneric","false");
        CommonUtil.SCREEN_TYPE = CommonUtil.RESERVATION_SCREEN_TYPE;
        navigate('equipDetails');

    }
    let onSaveEquipment = () => {
        //alert("Save Equipment");
        onClickShowVIN();
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }
    return(
        <>
            <Modal  show={showVINPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "850px","height":"500px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.ASSIGN_VIN,'Assign VIN')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseVINPopup} style={{marginLeft:"0px"}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            <div className="ag-theme-alpine gridHeight"
                                 style={{ height: 'calc(350px)', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                                <AGGrid id="OrgEquipAvailAssignVINGrid" cols={vinColumnDefs}
                                        rows={equipmentList} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                        ref={gridRef} addNew={true} tooltip={CommonUtil.getMessageText(Constants.ADD_EQUIPMENT,'Add Equipment')} addNewRecord={onClickAddEquipment}  />
                            </div>
                            <div className="col-md-12" style={{display:"none"}}>
                                <label>Equipment</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">
                                    <SingleSelectComp  dataSource={equipmentList} selectedValue={equipObj}
                                                       handleChange={onEquipChange} value={"key"} label={"value"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-warning waves-effect" data-bs-dismiss="modal"
                                onClick={onCloseVINPopup}>{CommonUtil.getMessageText(Constants.CLOSE,'Close')}</button> &nbsp;&nbsp;
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onClickSaveVIN}>{CommonUtil.getMessageText(Constants.ASSIGN,'Assign')}</button>
                    </div>
                </div>
            </Modal>

            <Modal  show={showDriverPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "550px","height":"600px"}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{CommonUtil.getMessageText(Constants.REPLACE_DRIVER,'Replace Driver')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDriverPopup} style={{marginLeft:"0px"}} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{overflow:"auto"}}>
                    {!isRenter && <div className="col-12 mb-2 d-flex justify-content-center">A driver for a reservation that was created/assign on behalf of a renter.</div>}
                        <div className="row fs-7">
                            <div className="col-md-12">
                                <label>{CommonUtil.getMessageText(Constants.DRIVER_NAME,'Driver Name')}</label>
                                <span className="clsFormMandatory"></span>
                                <div className="input-group mb-3">
                                    <SingleSelectComp  dataSource={driverList} selectedValue={driverObj}
                                                       handleChange={onDriverChange} value={"driverId"} label={"name"} />
                                </div>
                            </div>
                            <div className="col-md-12" style={{paddingTop:"10px"}}>
                                <div className="flex">
                                    <input ref={chkAddNewDriver} type="checkbox" className="checkBox" id="chkAddNewDriver" onChange={onChangeAddDriver}/>&nbsp;&nbsp;
                                    <label>{CommonUtil.getMessageText(Constants.ADD_NEW_DRIVER,"Add New Driver")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row" id={"divDriverPanel"} style={{display:"none"}}>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.DRIVER_NAME,'Driver Name')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text"  ref={nameRef} name="parkingLotName"
                                               className="form-control clsFormInputControl" value={driverName}
                                               placeholder="" onChange={handleInputName}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.CONTACT_NO,'Mobile Number')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{zIndex: 9}}>
                                        <PhoneInput
                                            country={'us'}
                                            countryCodeEditable={false}
                                            containerClass={phoneClass}
                                            value={phoneNumber}
                                            placeholder=""
                                            inputClass={`input-phone ${phoneClass}`}
                                            onChange={(e) => {
                                                handleInputPhone(e)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            disableCountryGuess={true}
                                            disableDropdown={true}
                                            disableSearchIcon={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL,'Email (Optional)')}
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control" ref={emailRef}
                                               placeholder=""
                                               type={'email'} name="email"
                                               value={email}
                                               onChange={handleInputEmail}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.TRUCK_NUMBER, 'Truck Number')}
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control"
                                          placeholder="Enter Truck Number"
                                          type='text'
                                          value={truckNumber} maxLength={25}
                                          onChange={handleInputNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {"Carrier Name"}
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control"
                                               placeholder="Carrier Name"
                                               type='text' maxLength={20}
                                               value={carrierName}
                                               onChange={handleCarrierName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {"USDOT Number"}
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control"
                                               placeholder="USDOT Number"
                                               type='text' maxLength={20}
                                               value={usdotNumber}
                                               onChange={handleUSDOTNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                                onClick={onCloseDriverPopup}>{CommonUtil.getMessageText(Constants.CLOSE,"Cancel")}</button> &nbsp;&nbsp;
                        <button type="button" id={"btnSaveAssign"} className="btn btn-success" data-bs-dismiss="modal"
                                onClick={onCreateDriver} style={{display:"none"}}>{CommonUtil.getMessageText(Constants.SAVE_ASSIGN,"Save & Assign")}</button> &nbsp;&nbsp;
                        <button type="button" id={"btnSave"} className="btn btn-success" onClick={onClickSaveDriver}>{CommonUtil.getMessageText(Constants.ASSIGN,"Assign")}</button>
                    </div>
                </div>
            </Modal>

            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                               imagePath={imageEquipPath}/>

            <div className="page-content-wrapper">
                <div className={`container-fluid euipviewpage-header col-12`}>
                    <div className="profile-bar">
                        <div className="shade">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                                        <div className="row">
                                            <div className="col" style={{marginTop:"20px"}}>
                                                <img className="img-fluid" src={imagePath} alt="" />
                                            </div>
                                            <div id="margin-top" className="col-6 mt-3">
                                                <div className="profile-name">
                                                    <h6>{equipType}<small style={{"display": "block"}}>{unitNumber}</small></h6>
                                                </div>
                                                <p>{CommonUtil.getMessageText(Constants.VIN_NO,"VIN Number")} : {rvinNumber} &nbsp;&nbsp;
                                                    { (isDisplayAssignVin) && (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)?<a className="border pl-2 pr-2 pt-1 pb-1 text-white" onClick={onClickShowVIN}>
                                                        <strong id={"spnVIN"}>{rvinNumber?CommonUtil.getMessageText(Constants.REPLACE_VIN,"Replace VIN"):CommonUtil.getMessageText(Constants.ASSIGN_VIN,"Assign VIN")}</strong></a>:""}
                                                </p>
                                                <div className="profile-name">
                                                    <p>{CommonUtil.getMessageText(Constants.PROCESS_URL,"Process URL")}<a href={processUrl} target="_blank" style={{color: "#fff",textDecoration: "none"}}><strong style={{"display": "block",fontWeight:" 600",fontSize: "17px", }}>{processUrl}</strong></a></p>
                                                </div>
                                                <div className="rating row" >
                                                    <StarRating value={Math.round(Number(overAllRate))} />{overAllRate} | {ratingCount} {CommonUtil.getMessageText(Constants.RATINGS,"Ratings")}
                                                </div>
                                                <div className="favourities" style={{display:"none"}}>
                                                    <i className="fa-regular fa-heart"></i> Add To Favourites
                                                </div>
                                                <div className="rating" style={{display:"none"}}>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa-solid fa-star-half-stroke"></i> 4.5 | 39 Ratings
                                                </div>
                                            </div>
                                            <div id="margin-top" className="col text-center mt-3 pt-3">
                                                <div className="border-right border-left">
                                                    <p className="m-1"><small style={{display: "block"}}>{CommonUtil.getMessageText(Constants.BRAND,"Brand")}</small>{brand}</p>
                                                    <p className="m-0"><small style={{display: "block"}}>{CommonUtil.getMessageText(Constants.YEAR,"Year")}</small>{year}</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-sm-12 col-md-12  col-lg-4 col-xl-4  mt-4">
                                        <span className="details-head">{CommonUtil.getMessageText(Constants.RESERVATION,"Reservation")}</span><h6>{reservationCode}</h6>
                                        <div className="row">
                                            <div className="col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.DRIVER,"Driver")}</span><h6>{driver}</h6></div>
                                            <div className="col-6" style={{marginTop:"-40px"}}>
                                                <span className="details-head">{CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type")}</span><h6>{listingType}</h6>
                                                <span className="details-head"></span><h6>{equipGeneric}</h6>
                                                <button type="button" className="btn-sm btn-warning mb-2 " id={"btnDriver"} disabled={CommonUtil.getSelectedOrgId()?false:true} style={{opacity:CommonUtil.getSelectedOrgId()?1:0.5}}
                                                        data-dismiss="modal" onClick={onClickAssignDriver}> {driver?CommonUtil.getMessageText(Constants.REPLACE_DRIVER,"Replace Driver"):CommonUtil.getMessageText(Constants.ASSI_DRIVER,"Assign Driver")}
                                                </button>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <button className="cbtn btn-warning" onClick={printPDF} disabled={CommonUtil.getSelectedOrgId()?false:true} style={{opacity:CommonUtil.getSelectedOrgId()?1:0.5}}> {CommonUtil.getMessageText(Constants.PRINT_PDF,"Print PDF")}</button>
                                            {activationDate && deActivationDate?<button className="cbtn btn-warning" onClick={onClickBill} style={{marginTop:"5px",display:(CommonUtil.isRootUser()?"":"none")}}> {CommonUtil.getMessageText(Constants.BILL,"Bill")}</button>:""}
                                            <button id={"btnReservationCancel"} disabled={CommonUtil.getSelectedOrgId()?false:true} className="cbtn btn-warning" style={{opacity:CommonUtil.getSelectedOrgId()?1:0.5,marginLeft:"5px",width:"152px",display:"none"}}  onClick={onClickCancelReservation}> {CommonUtil.getMessageText(Constants.RESERVATION_CANCEL,"Cancel Reservation")}</button>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className={`profile-tabs row`} style={{ justifyContent:'center' }}>

                              <div className="scroller scroller-left float-left" onClick={leftClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                                    <div className="wrapper-nav m-0" id='wrapper-nav'>
                                        <nav className="nav nav-tabs list col-12 px-3" id="myTab" role="tablist">
                                        <a id={"lnkDetails"} className={`nav-item nav-link ${genInfo ? 'active': ''} ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={()=>onClickTabs('lnkDetails')}>{CommonUtil.getMessageText(Constants.DETAILS,"Details")}</a>
                                          <a id={"lnkEquipment"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={()=>onClickTabs('lnkEquipment')}>{CommonUtil.getMessageText(Constants.EQUIPMENT,"Equipment")}</a>
                                          <a id={"lnkActivation"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"   onClick={()=>onClickTabs('lnkActivation')}>{CommonUtil.getMessageText(Constants.ACTIVATION,"Activation")}</a>
                                          <a id={"lnkDeactivation"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={()=>onClickTabs('lnkDeactivation')}>{CommonUtil.getMessageText(Constants.DEACTIVATION,"Deactivation")}</a>
                                          <a id={"lnkCarrierDetails"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={()=>onClickTabs('lnkCarrierDetails')}>{CommonUtil.getMessageText(Constants.CARRIER_DETAILS,"Carrier Details")}</a>
                                          <a id={"lnkDocuments"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" onClick={()=>onClickTabs('lnkDocuments')}>{CommonUtil.getMessageText(Constants.DOCUMENTS,"Documents")}</a>
                                          <a id={"lnkClaims"} className={`nav-item nav-link ${isMobile ? '' : 'col'} ${claims ? 'active': ''} ${reservationStatus ===CommonUtil.RESERVATION_DELETED || activationDate ? '' : 'd-none'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={()=>onClickTabs('lnkClaims')}>{CommonUtil.getMessageText(Constants.CLAIMS,"Claims")}</a>
                                          <a id={"lnkHistory"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} data-bs-toggle="tab"  role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"  onClick={()=>onClickTabs('lnkHistory')}>{CommonUtil.getMessageText(Constants.HISTORY,"History")}</a>
                                        </nav>
                                    </div>
                              <div className="scroller scroller-right float-right" onClick={rightClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div className="tab-content p-3" id="myTabContent">
                                <div className="tab-pane show active  mt-2" id="pnlDetails" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    <ReservationDetails reservationData={reservationData} isLogin={props.isLogin} driverName={driver}/>
                                </div>
                                <div role="tabpanel" className="tab-pane fade mt-2" id="pnlEquipment"
                                     aria-labelledby="public-tab" aria-expanded="true">
                                    {equipments &&  <div className="container">
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.GENERAL_DETAILS,"General Details")}</h5>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type")}</span><label>{assetType}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number")}</span><label>{unitNumber}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.VIN_NO,"VIN Number")}</span><label>{vinNumber}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.BRAND,"Brand")}</span><label>{brand}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.MODEL,"Model")}</span><label>{model}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.YEAR,"Year")}</span><label>{year}</label></div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.AXLE_NO,"Number of Axles")}</span><label>{numOfAxes}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER,"Length of Trailer")}</span><label>{trailerLength}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO,"License Plate Number")}</span><label>{license}</label></div>
                                                            <div className="col-sm-3 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ANN_MECH_EXPIRE_DATE,"Annual Mechanical Expiration Date")}</span><label>{expDate}</label></div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.SPECIFICATIONS,"Specifications")}</h5>
                                                        <hr />
                                                        <h6>{CommonUtil.getMessageText(Constants.SPECIFICATIONS,"Specifications")}</h6>
                                                        <div className="row">
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.AXLE_SPREAD,"Axle Spread")}</span><label>{axelSpread}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.TIRE_INF_SYS,"Tire Inflation System")}</span><label>{tireInflation}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.SKIRTS,"Skirts")}</span><label>{skirts}</label></div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.PINTLE_HOOK,"Pintle Hook")}</span><label>{pintleHook}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.HAZMAT_PLACARD,"Hazmat Placard")}</span><label>{hazmat}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6">
                                                                <span className="details-head">{CommonUtil.getMessageText(Constants.SUSPENSION,"Suspension")}</span><label>{suspension}</label></div>
                                                        </div>
                                                        <h6>{CommonUtil.getMessageText(Constants.TRAILERS,"Trailers")}</h6>
                                                        <div className="row">
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.REAR_DOOR,"Rear Door")}</span><label>{rearDoor}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.SIDE_DOOR,"Side Door")}</span><label>{sideDoor}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.ROOF,"Roof")}</span><label>{roof}</label></div>
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.FLOOR,"Floor")}</span><label>{floor}</label></div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.FLOOR_TYPE,"Floor Type")}</span><label>{floorType}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.INTERIOR_FINISH,"Interior Finish")}</span><label>{interior}</label>
                                                            </div>

                                                            <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LOGIS_POST_DIST,"Logistics Posts Distance")}</span><label>{logistics}</label>
                                                            </div>
                                                            <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.DOUBLE_DECK_BEAMS,"Double Deck Beams")}</span><label>{deckBeams}</label>
                                                            </div>
                                                        </div>
                                                        <h6>{CommonUtil.getMessageText(Constants.LIFT,"Lift")}</h6>
                                                        <div className="row">
                                                            <div className="col-sm-2 col-6"><span
                                                                className="details-head">{CommonUtil.getMessageText(Constants.LIFT,"Lift")}</span><label>{lift}</label></div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LIFT_TYPE,"Lift Type")}</span><label>{liftType}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_CAPACITY,"Unit Capacity")}</span><label>{liftCap}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6">
                                                                <span className="details-head">{CommonUtil.getMessageText(Constants.FLOOR,"Floor")}</span><label>{floor}</label></div>
                                                        </div>
                                                        <h6>{CommonUtil.getMessageText(Constants.TEMP_CONTROL,"Temperature Control")}</h6>
                                                        <div className="row">
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MAKE,"Unit Make")}</span><label>{unitMake}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MODEL,"Unit Model")}</span><label>{unitModal}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_YEAR,"Unit Year")}</span><label>{unitYear}</label>
                                                            </div>
                                                            <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.MULTI_TEMP,"Multi Temperature")}</span><label>{multiTemp}</label>
                                                            </div>
                                                        </div>

                                                    </section>
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type")}</h5>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-12 col-12">
                                                                <div className="popup-gallery general-details-photos">
                                                                    <a className="d-inline-block">
                                                                        <div className="img-fluid d-block mx-auto">
                                                                            <img src={imagePath} alt="" />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.EQUIP_PHOTO,"Equipment Photos")}</h5>
                                                        <hr />
                                                        <div style={{display: 'flex',overflow:"auto"}}>
                                                            {equipImagesPath.map((item: any, idx: number) => {
                                                                if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                                                                    var list = (
                                                                        <div className={'upload-image-item'} key={idx}>
                                                                            <img src={item.Path} style={{margin:"0px",cursor:"pointer"}} className="upload-thumbnail m-3"
                                                                                 onClick={()=>{
                                                                                     //console.log(equipImagesPath,item.Path,idx);
                                                                                     onClickEquipImage(CommonUtil.getMessageText(Constants.EQUIP_PHOTO,"Equipment Photos"),equipImagesPath,item.Path,idx,CommonUtil.EQUIP_IMAGES);
                                                                                 }}/>
                                                                        </div>
                                                                    )
                                                                    return list
                                                                }
                                                            })}

                                                        </div>

                                                    </section>
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.INSTRUCTIONS,"Instructions")}</h5>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-12 col-12">
                                                                <p>{CommonUtil.getMessageText(Constants.DRIVER_INSTRUCTIONS,"These instructions will be requested of the drivers to apply\n" +
                                                                    "                                                                    and confirm at the beginning and at the end of the rental.\n" +
                                                                    "                                                                    These instructions may also assist the driver if needed.")}</p>
                                                                <h6>{CommonUtil.getMessageText(Constants.ACTIVATION_INSTRUCTIONS,"Activation Instructions")}</h6>
                                                                <p>{activeInstruction}</p>
                                                                <h6>Deactivation Instructions</h6>
                                                                <p>{deActiveInstruction}</p>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <h5>{CommonUtil.getMessageText(Constants.GENERAL_SETTINGS,"General Settings")}</h5>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-12 col-12">
                                                                <h6>{CommonUtil.getMessageText(Constants.ADDITIONAL_EMAILS,"Additional emails for renting notifications")}</h6>
                                                                <p><a>{emails}</a>
                                                                </p>
                                                                <h6>{CommonUtil.getMessageText(Constants.SECURE_DEPOSIT,"Security Deposit")}</h6>
                                                                <p>{deposit}</p>
                                                            </div>

                                                        </div>
                                                    </section>

                                                    </div>}

                                </div>
                                <div className="tab-pane fade  mt-2" id="pnlActivation" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {activation && <ReserveActivation equipData={equipmentData} vin={vinNumber} reservationData={reservationData} />}

                                </div>
                                <div className="tab-pane fade   mt-2" id="pnlDeactivation" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {deactivation &&  <ReserveDeActivation equipData={equipmentData} reservationData={reservationData} />}
                                </div>
                                <div className="tab-pane fade mt-2" id="pnlDocuments" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {docs &&  <EquipmentDocuments equipData={equipmentData} getEquipData={getEquipmentDetails} addNewDocument={true} specificForReservation={true}/>}

                                </div>
                                <div className="tab-pane fade mt-2" id="pnlClaims" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {claims && <ReserveClaims equipData={equipmentData} reservationData={reservationData} />}
                                </div>
                                <div className="tab-pane fade mt-2" id="pnlHistory" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {ehistory && <ReservationHistory equipData={equipmentData} />}
                                </div>
                                <div className="tab-pane fade mt-2" id="pnlCarrierDetails" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {carrierDetails && <CarrierDetails equipData={equipmentData} />}
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
            </div>


        </>
    )
}

export default ReservationView;
