import React, { useEffect, useRef, useState } from 'react'
import { CommonUtil } from '../../utils/CommonUtil'
import { Constants } from '../Languages/Constants'
import PhoneInput from 'react-phone-input-2';
import CreateParkingHours from './CreateParkingHours';
import { NetworkManager } from '../../Components/NetworkManager';
import ToastManager from '../../Components/ToastManager';
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router';
import $ from "jquery";
import { Events } from '../Languages/Events';

const CreateParkingLot = ({isOpen}:any) => {
    const navigate = useNavigate();
    const parkingLotCodeNameRef = useRef<HTMLInputElement>(null);
    const lotNameRef = useRef<HTMLInputElement>(null);
    const addressRef = useRef<HTMLInputElement>(null);
    const hourRef = useRef<HTMLInputElement>(null);
    const dailyRefLot = useRef<HTMLInputElement>(null);
    const weekRef = useRef<HTMLInputElement>(null);
    const monthRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const poicaptureRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactRef = useRef<HTMLInputElement>(null);

    const [parkingLotName, setParkingLotName] = useState<any>('');
    const [parkingLotCodeName, setParkingLotCodeName] = useState<any>('');
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [contactName, setContactName] = useState<any>('');
    const [currency, setCurrency] = useState<any>("");
    const [status, setStatus] = useState<any>('Active');
    const [weeklyRate, setWeeklyRate] = useState<any>('');
    const [address, setAddress] = useState<any>('');
    const [city, setCity] = useState<any>('');
    const [country, setCountry] = useState<any>('');
    const [latitude, setLatitude] = useState<any>('');
    const [longitude, setLongitude] = useState<any>('');
    const [postalCode, setPostalCode] = useState<any>('');
    const [rawAddress, setRawAddress] = useState<any>('');
    const [state, setState] = useState<any>('');
    const [timeZone, setTimeZone] = useState<any>('');
    const [utcOffsetMinutes, setUtcOffsetMinutes] = useState<any>('');
    const [mode, setMode] = useState<any>('new');
    const [parkingList, setParkingList] = useState<any>([]);
    const [address1, setAddress1] = useState('');
    const [poiCapture, setPoiCapture] = useState<any>('');
    const [parkingLotId, setParkingLotId] = useState<any>('')
    const [twicRequire, seTwicRequire] = useState<any>('N');
    const [phoneClass, setPhoneClass] = useState('mandatory');
    const [email, setEmail] = useState<any>('');
    const [instructions, setInstructions] = useState<any>('');
    const [payToPark, setPayToPark] = useState<any>('N');
    const [dailyRate, setDailyRate] = useState<any>('');
    const [hourlyRate, setHourlyRate] = useState<any>('');
    const [monthlyRate, setMonthlyRate] = useState<any>('');
    const [is24hoursAccessAvailable, setIs24hoursAccessAvailable] = useState<any>('Y');
    const [parkingLots, setParkingLots] = useState<any>([]);
    const [parkingHoursRequest, setParkingHours] = useState([]);
    var isCreateAvailability:boolean = false;

    
    const parkingLotTimings = [
    { day: "Monday", openTime: "09:00", closeTime: "17:00" },
    { day: "Tuesday", openTime: "09:00", closeTime: "17:00" },
    { day: "Wednesday", openTime: "09:00", closeTime: "17:00" },
    { day: "Thursday", openTime: "09:00", closeTime: "17:00" },
    { day: "Friday", openTime: "09:00", closeTime: "17:00" },
    { day: "Saturday", openTime: "09:00", closeTime: "17:00" },
    { day: "Sunday", openTime: "09:00", closeTime: "17:00" },
    ];

    const handleTimeChange = (updatedHours:any) => {
      setParkingHours(updatedHours);
    };
    
    useEffect(()=>{
        if(window.location.pathname.includes("createParkingLot")){
            Events.dispatchHideAvailabilityButton();
        }

        isCreateAvailability = true;
        setTimeout(() => {
        getGooglePlaces();
        }, 1000)
    },[])
    
    let onLoginError = (err: any) => {
        console.log(err)
    }
    let handleParkingLotName = (e: any) => {
        setParkingLotName(e.target.value)
        CommonUtil.isMandatory(lotNameRef);
    }
    let handleParkingLotCodeName = (e: any) => {
        setParkingLotCodeName(e.target.value)
        // CommonUtil.isMandatory(parkingLotCodeNameRef);
    }
    let handleInputStatus = (e: any) => {
        setStatus(e.target.value)
    }
    let onChangeAddress = (e: any) => {
        setLatitude('');
        setLongitude('');
        setAddress1(e.currentTarget.value);
        setRawAddress(e.currentTarget.value);
        //setPoiCapture(address1);
        CommonUtil.isMandatory(addressRef);
        // CommonUtil.isMandatory(poiCaptureRef);
    }
    const handleContactName = (e: any) => {
        setContactName(e.target.value)
    }
    const handleInputPhone = (e: any) => {
        setPhoneNumber(e)
        // setPhoneClass('mandatory');
    }
    let handleInputEmail = (e: any) => {
        setEmail(e.target.value)
        let flag: boolean = CommonUtil.isValidEmail(e.target.value);
        if (flag) {
            emailRef.current?.classList.add("is-valid");
            emailRef.current?.classList.remove("is-invalid");
        } else {
            emailRef.current?.classList.add("is-invalid");
            emailRef.current?.classList.remove("is-valid");
        }
    }
    let handleInputInstruction = (e: any) => {
        setInstructions(e.target.value)
    }
    let handleTwicRequire = (e: any) => {
        seTwicRequire(twicRequire === 'N' ? 'Y' : "N");
        //console.log(twicRequire);
    }
    const handlepayToPark = (e: any) => {
        setPayToPark(payToPark === 'N' ? 'Y' : "N");
        if (payToPark === 'Y') {
            setHourlyRate('')
            setDailyRate('')
            setWeeklyRate('')
            setMonthlyRate('')
        }
    }
    const handleInputChange24 = (event: any) => {
        setIs24hoursAccessAvailable(is24hoursAccessAvailable === 'N' ? 'Y' : 'N')
    }
    let handleInputHourRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: hourRef, setState: setHourlyRate })
        CommonUtil.isMandatory(hourRef);
    }
    let handleInputDailyRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: dailyRefLot, setState: setDailyRate })
        CommonUtil.isMandatory(dailyRefLot);
    }
    let handleInputWeekRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: weekRef, setState: setWeeklyRate })
        CommonUtil.isMandatory(weekRef);
    }
    let handleInputMonthRate = (e: any) => {
        CommonUtil.handleNumbersDecimalInput({ event: e, ref: monthRef, setState: setMonthlyRate })
        CommonUtil.isMandatory(monthRef);
    }
    let handleCloseParkingLot = () => {
        // resetStates();
        
        navigateToParentScreen();
    }

    let navigateToParentScreen = ()=>{
            // if(isCreateAvailability){
                Events.dispatchSaveParkingLot();
            // }else{
            //     Events.dispatchShowAvailabilityButton();
            // }
    
            navigate(-1);
        }

    let handleParkingLotSubmit = () => {
        let isLotName: boolean = CommonUtil.isMandatory(lotNameRef)
        let isparkLotCodeName: boolean = CommonUtil.isMandatory(parkingLotCodeNameRef)
        let isEmail: boolean = true;
        let isHour: boolean = true;
        let isDaily: boolean = true;
        let isWeek: boolean = true;
        let isMonth: boolean = true;
        let isAddress: boolean = CommonUtil.isMandatory(addressRef);
        //let isPOICapture: boolean = CommonUtil.isMandatory(poicaptureRef);
        let isPhone: boolean = true;
        if (payToPark === 'Y') {
            isHour = CommonUtil.isMandatory(hourRef)
            isDaily = CommonUtil.isMandatory(dailyRefLot)
            isWeek = CommonUtil.isMandatory(weekRef)
            isMonth = CommonUtil.isMandatory(monthRef)
        }
        if (!longitude?.toString()?.trim() || !latitude?.toString()?.trim()) {
            ToastManager.showToast('Invalid location', 'Error');
            return;
        }
        // if (!phoneNumber.trim() || phoneNumber.length < 11) {
        //     isPhone = false
        //     setPhoneClass('is-invalid')
        // }
        if (email.length > 0) {
            isEmail = CommonUtil.isValidEmail(email);
        }
        var twicAccessRequired = twicRequire;
        if (isLotName && isEmail && isHour && isDaily && isWeek && isMonth && isAddress && isPhone) {
            let orgUrl = "api/parkinglot/";
            let newData = {
                dailyRate,
                email,
                hourlyRate,
                instructions,
                poiCapture,
                payToPark,
                is24hoursAccessAvailable,
                currency,
                monthlyRate,
                twicAccessRequired,
                contactName,
                parkingLotName: parkingLotName.trimStart().trimEnd(),
                parkingLotCodeName,
                phoneNumber,
                status,
                weeklyRate,
                address,
                city,
                country,
                latitude,
                longitude,
                postalCode,
                rawAddress,
                state,
                timeZone,
                utcOffsetMinutes,
                parkingHoursRequest
            };

            orgUrl = "api/parkinglot/?saasOrgId=" + CommonUtil.getSelectedOrgId();
            NetworkManager.sendJsonRequest(orgUrl, newData, "", "POST", true, onParkingLotSuccess, onLoginError);
        }
    }
    let onParkingLotSuccess = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Parking lot created successfully', 'Success');
                // resetStates();
                handleCloseParkingLot();
                getPartkingLots();
            }
        }
    }
    let getPartkingLots = () => {
        let parkingAPI: string = "api/parkinglot/all?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetParkingLots, onLoginError);
    }
    let onGetParkingLots = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let parkingLots: any = [];
                if (Array.isArray(restData.parkingLots)) {
                    parkingLots = restData.parkingLots;
                } else {
                    parkingLots.push(restData.parkingLots);
                }
                setParkingLots(parkingLots);
            }
        }
    }
    
    let getGooglePlaces = () => {
        // resetStates();
        const input = document.getElementById('address') as HTMLInputElement
        const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: ['us', 'ca', 'in'] }
        })
        autocomplete.addListener('place_changed', () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete);
            if (addr) {
                const typedPOI = $('#address').val();
                setPoiCapture(typedPOI);
                let str: any = typedPOI?.toString();
                if (str.includes("USA")) {
                    setCurrency(CommonUtil.US_CURRENCY);
                }
                if (str.includes("Canada")) {
                    setCurrency(CommonUtil.CANADA_CURRENCY);
                }

                setTimeout(() => {
                    setAddress(addr['rawAddress'])
                    setAddress1(addr['rawAddress'])
                    setCity(addr['city'])
                    setCountry(addr['country'])
                    setLatitude(addr['lat'])
                    setLongitude(addr['lng'])
                    setPostalCode(addr['postalcode'])
                    setRawAddress(addr['rawAddress'])
                    setState(addr['state'])
                    setTimeZone('');
                    setUtcOffsetMinutes('330')
                    CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    })
                })
            }
        })
    }

  return (
    
    <>
        <div className="pt-4 container" style={{ overflow: "auto" }}>
            <div className="row create-equip-card" style={{ margin: "0 10px 15px" }}>
                <div className="col-12 fs-4 pb-3 border-bottom">
                    <div className="col-md-12">
                        <h5 className="mb-1"><span className="count-span">1 </span>General Details<span style={{fontWeight: 300, fontSize: "15px", paddingLeft: "4px"}}>{`(Required)`}</span></h5>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-2">
                    <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text" autoFocus={true} name="parkingLotName"
                                    className="form-control clsFormInputControl" ref={lotNameRef}
                                    placeholder={CommonUtil.getMessageText(Constants.PARKING_LOT_NAME, 'Parking Lot Name')} value={parkingLotName}
                                    onChange={handleParkingLotName}
                                />
                            </div>
                        </div>
                    </div>
                <div className="col-md-4 col-12 mt-2">
                    <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.PARKING_LOT_CODE_NAME, 'Parking Lot Code Name')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text" ref={parkingLotCodeNameRef} name="parkingLotCodeName"
                                    className="form-control" placeholder="Parking Lot Code Name"
                                    maxLength={12}
                                    aria-label="parkingLotCodeName" value={parkingLotCodeName} onChange={(e) => handleParkingLotCodeName(e)}
                                />
                            </div>
                        </div>
                    </div>
                <div className="col-md-4 col-12 mt-2">
                    <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.STATUS, 'Status')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <select name="status" className={'form-control'} onChange={handleInputStatus} value={status}>
                                    <option value="Active">Active</option>
                                    <option value="InActive">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                <div className="col-md-6 col-12">
                    <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.LOCATION, 'Location')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text" id={'address'} ref={addressRef}
                                    className="form-control googleAddress clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.LOCATION, 'Location')}
                                    aria-label="Address1"
                                    value={address1}
                                    onChange={(e) => onChangeAddress(e)}
                                />
                            </div>
                        </div>
                    </div>
                <div className="col-md-6 col-12">
                    <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.POINT_OF_INTEREST, 'Point of Interest')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text" ref={poicaptureRef}
                                    className="form-control googleAddress" placeholder='POI'
                                    aria-label="POICapture" value={poiCapture} readOnly
                                />
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
            <div className="row create-equip-card" style={{ margin: "0 10px 15px" }}>
                <div className="col-12 fs-4 pb-3 border-bottom">
                    <div className="col-md-12">
                        <h5 className="mb-1"><span className="count-span">2 </span>Contact</h5>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-2">
                    <label className="col-sm-12 col-form-label">
                        {CommonUtil.getMessageText(Constants.CONTACT_NAME, "Contact Name")}
                    </label>
                    <input className="form-control" placeholder="Contact Name" type="text"
                        name="contactName" ref={contactRef}
                        value={contactName}
                        onChange={handleContactName}
                    />
                </div>
                <div className="col-md-4 col-12 mt-2">
                    <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                            {CommonUtil.getMessageText(Constants.CONTACT_NO, "Contact Number")} <span className="text-danger"></span>
                        </label>
                        <div className="col-sm-12 phoneNo" style={{ zIndex: 9 }}>
                            {/*<input className={'form-control'} placeholder="Contact Number" type="number"*/}
                            {/*       name="phoneNumber"*/}
                            {/*       value={phoneNumber} onChange={handleInputChange}/>*/}
                            <PhoneInput
                                country={'us'}
                                countryCodeEditable={false}
                                value={phoneNumber}
                                // containerClass={phoneClass}
                                placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, 'Enter Mobile Number')}
                                // inputClass={`input-phone`}
                                onChange={(e) => {
                                    handleInputPhone(e)
                                }}
                                containerStyle={{
                                    width: '100%',
                                }}
                                disableCountryGuess={true}
                                disableDropdown={true}
                                disableSearchIcon={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 mt-2">
                    <div className="form-group row">
                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_OPTIONAL, 'Email (Optional)')}</label>
                        <div className="col-sm-12">
                            <input className="form-control" ref={emailRef}
                                placeholder="Email (Optional)" aria-label="Email"
                                type="email" name="email" value={email}
                                onChange={handleInputEmail}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row create-equip-card" style={{ margin: "0 10px 15px" }}>
                <div className="col-12 fs-4 pb-3 mb-3 border-bottom">
                    <div className="col-md-12">
                        <h5 className="mb-1"><span className="count-span">3 </span>Business Hours</h5>
                    </div>
                </div>
                <div className="col-md-12 col-12">
                    <CreateParkingHours onTimeChange={handleTimeChange} parkingHours={parkingLotTimings} />
                </div>
            </div>
            <div className="row create-equip-card" style={{ margin: "0 10px 15px" }}>
            <div className="row  ">
                <div className="col-md-12 fs-4 pb-3 border-bottom">
                    <h5 className="mb-1"><span className="count-span">4 </span>Instructions</h5>
                </div>
                <div className="col-md-12 col-12" style={{ paddingLeft: "0px" }}>
                    <div className="form-group row ms-2">
                        <label className="col-sm-12 col-form-label">
                            {CommonUtil.getMessageText(Constants.ACCESS_INSTRUCTION_OPTIONAL, 'Access Instruction (Optional)')}
                        </label>
                        <div className="col-sm-6">
                            <textarea className="form-control" style={{ height: "100px", }}
                                placeholder="Access Instruction (Optional)"
                                name="instructions" value={instructions}
                                onChange={handleInputInstruction}
                            />
                        </div>
                    </div>
                </div>
                </div>
                <div className="row pr-0 ms-1">
                    <div className="col-md-2 pt-4">
                        <Form.Check
                            type="switch"
                            name={'twicRequire'}
                            label={CommonUtil.getMessageText(Constants.TWIC_REQUIRE, 'TWIC Required')}
                            checked={twicRequire === 'Y' ? true : false}
                            // defaultChecked={false}
                            value={twicRequire === "Y" ? 'Y' : "N"}
                            onChange={handleTwicRequire}
                        />
                    </div>
                    <div className="col-md-2 col-sm-12 pt-2" style={{ display: (twicRequire == 'Y' ? "" : "none") }}>
                        <img src="../../images/twic.png" className="mx-2" style={{ width: "50px" }} />
                    </div>
                </div>
                <div className="row pr-0 ms-1">
                    <div className="col-md-4 sm-col-12 pt-2">
                        <Form.Check
                            type="switch"
                            name={'is24hoursAccessAvailable'}
                            label={CommonUtil.getMessageText(Constants.HOURS_24, '24 hours')}
                            checked={is24hoursAccessAvailable === 'N' ? false : true}
                            // defaultChecked={false}
                            value={is24hoursAccessAvailable === "N" ? 'N' : "Y"}
                            onChange={handleInputChange24}
                        />
                    </div>
                </div>
                <div className="row pr-0 ms-1">
                    <div className="col-md-4 sm-col-12 pt-2">
                        <Form.Check
                            type="switch"
                            name={'payToPark'}
                            label={CommonUtil.getMessageText(Constants.PAY_TO_PARK, 'Pay to Park')}
                            checked={payToPark === 'N' ? false : true}
                            defaultChecked={false}
                            value={payToPark === "Y" ? 'Y' : "N"}
                            onChange={handlepayToPark}
                        />
                    </div>
                </div>
                <div className="row pr-0 ms-1">
                <div className="col-md-2 col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                            <div className="col-sm-12">
                                <input type="text"
                                    className="form-control"
                                    disabled value={currency} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.HOURLY_RATE, "Hourly Rate")} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} maxLength={10}
                                    placeholder="Enter Rate" ref={hourRef}
                                    required
                                    type="text" name="hourlyRate" value={hourlyRate}
                                    onChange={handleInputHourRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                    disabled={payToPark === 'N'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12" style={{ zIndex: 9 }}>
                                <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="Enter Rate" type="text"
                                    name="dailyRate" ref={dailyRefLot} maxLength={10}
                                    required
                                    value={dailyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                    onChange={handleInputDailyRate}
                                    disabled={payToPark === 'N'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12" style={{ zIndex: 9 }}>
                                <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="Enter Rate" type="text"
                                    name="weeklyRate" ref={weekRef} maxLength={10}
                                    required
                                    value={weeklyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                    onChange={handleInputWeekRate}
                                    disabled={payToPark === 'N'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12" style={{ zIndex: 9 }}>
                                <input className={`form-control ${payToPark === 'Y' ? 'clsFormInputControl' : ''}`} placeholder="Enter Rate" type="text"
                                    name="monthlyRate" ref={monthRef} maxLength={10}
                                    required
                                    value={monthlyRate} onKeyPress={CommonUtil.allowNumbersDecimal}
                                    onChange={handleInputMonthRate}
                                    disabled={payToPark === 'N'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="create-equip-card " style={{ margin: "0 10px 15px" }}>
                    <div className='modal-footer me-4'>
                        <button type="button" className="btn btn-outline-dark me-2"
                            data-dismiss="modal" onClick={handleCloseParkingLot}>
                            {CommonUtil.getMessageText(Constants.CLOSE, 'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success"
                            onClick={handleParkingLotSubmit}>
                            {CommonUtil.getMessageText(Constants.SAVE, 'Save')}
                        </button>
                    </div>
                </div>
            </div>
</>
  )
}

export default CreateParkingLot